import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  SubPaperData: [],
  notification:{}
};

const SubPaerSlice = createSlice({
  name: 'Sub-Paper',
  initialState,
  reducers: {
    setSupPaperData(state, action) {
      state.SubPaperData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setSupPaperData, setToast } = SubPaerSlice.actions;


export const ETEGetSubPaperListURL = (ExamUuid, token, ) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/entrance/subpaper?exam_uuid=${ExamUuid}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setSupPaperData(response.data));
};



const SubPaperReducer = SubPaerSlice.reducer;

export default SubPaperReducer;
