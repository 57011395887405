import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import '../../PlayHistory/History.css'
import account from '../../../Assets/account.png'
import replay from '../../../Assets/replay.png'
import next from '../../../Assets/next.png'
import download from '../../../Assets/download.png';
import facebook from '../../../Assets/facebook.png';
import { ScoreCardListURL, ScoreCardDownloadURL } from "../../../Redux/ScoreCard/ScoreCardRedux";
import axios from "axios";
import { secureStorage } from "../../../Utils";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { GameComplitedUrl, GameReplyApi } from "../../../Redux/Game/GameListRedux";
// import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import scor from "../../../Assets/award_winning_svg.svg"

function ScoreCard() {
  const history = useHistory()

  const location = useLocation('');
  console.log(location?.state, "locationff")

  const dispatch = useDispatch()
  const { GameListData, GameCompletedDetails, GameReplayDetails } = useSelector((state) => state.GameList)
  console.log(GameReplayDetails, "GameReplayDetails")

  const { ScoreCardData } = useSelector((state) => state.ScoreCardList)
  console.log(ScoreCardData, "ScoreCardData");
  const details = secureStorage.getItem('currentUser')
  console.log(details, "detailsdetails");

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // dispatch(ScoreCardListURL(GameListData?.data?.uuid, GameListData?.data?.type_uuid, GameListData?.data?.method_uuid, details?.data?.token))
    dispatch(ScoreCardListURL(location?.state?.data?.uuid, location?.state?.data?.type_uuid, location?.state?.data?.method_uuid, details?.data?.token))
    console.log(ScoreCardData, "ScoreCardData")
  }, [location])



  // const gameUUID = ScoreCardData?.data[0]?.game_uuid
  const participantUUID = details?.data?.data?.uuid


  const handleDownloadPDF = (e) => {
    e.preventDefault()

    axios.get(`${process.env.REACT_APP_WEB_APP}/participant/download/pdf?game_uuid=${location?.state?.data?.uuid}&participant_uuid=${participantUUID}`, {
      headers: {

        "x-auth-token": `${details.data.token}`,
      }
    })
      .then((res) => {

        if (res.data) {
          const dt = res.data;
          const linkSource = `data:application/pdf;base64,${res.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "Score_Card.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }


      })
      .catch((err) => {
        console.log(err, "ssdfsdfdsfd")

      })
  }

  useEffect(() => {
    localStorage.removeItem("ScoreCardUpdate");
  }, [])


  // const Replay = () => {
  //   history.push({
  //     pathname: '/game-instruction',
  //     state: 'replay'
  //   });
  // };

  const Replay = () => {
    if (GameCompletedDetails?.data?.name === "uservsuser" || GameCompletedDetails?.data?.name === "uservs2user" || GameCompletedDetails?.data?.name === "uservs4user") {
      // const payload = {
      //   "game_uuid": location?.state?.data?.uuid,
      //   "participant_uuid": participantUUID
      // }
      // dispatch(GameReplyApi(payload, details?.data?.token))
      setTimeout(() => {
        setSuccess(true)
      }, 1000)
    }
    else {
      history.push({
        pathname: '/game-instruction',
        state: 'replay'
      });
    }
  }

  useEffect(() => {
    if (success === true) {
      if (GameReplayDetails !== undefined) {
        history.push({
          pathname: '/game-instruction-uservsalluser',
          // state: GameReplayDetails?.data || location?.state?.data
          state: location?.state?.data,
          stat: "Replay"
        });
      }
    }
  }, [success])


  useEffect(() => {
    const payload = {
      "game_completed": true
    }
    dispatch(GameComplitedUrl(location?.state?.data?.uuid, payload, details.data.token))

  }, [])

  return (
    <>
      <Grid item container style={{ background: "linear-gradient(87deg, #FF5722 0, #9C27B0 100%)", width: "100%", height: "100vh", padding: "52px 20px" }}>
        <Grid xs={12} md={12} lg={12} >
          <div style={{ background: "white", padding: "10px", borderRadius: "10px", margin: "10px" }}>
            <Grid align="center">
              <img src={scor} style={{ width: "37.5%" }} />
              <h1 className='scorecardtext'>Score Card
              </h1>
            </Grid>
            <Grid>
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "6px 20px", fontWeight: "500", width: "5%", backgroundColor: "#1877f2", color: "#fff" }} className='share'>
                <div>
                  <img src={facebook} alt="facebook" width="20px" height="auto" />
                </div>
                <div>
                  Share
                </div>
                <div>
                  18
                </div>
              </div>
            </Grid>

            {/* <div>
<div style={ {backgroundColor: '#fff',border:'1px solid #ddd'}} className="row">
        <div className="cell" >
          <p  className="cellText">RANK</p>
        </div>
        <div className="cell">
          <p className="cellText">NAME</p>
        </div>
        <div className="cell">
          <p className="cellText">CORRECT COUNT</p>
        </div>
        <div className="cell">
          <p className="cellText">SKIPPED COUNT</p>
        </div>
        <div className="cell">
          <p className="cellText">INCORRECT COUNT</p>
        </div>
        <div className="cell">
          <p className="cellText">TOTAL SCORE</p>
        </div>
      </div>


      {ScoreCardData && ScoreCardData.data && ScoreCardData.data.map((item) => {
                console.log(item, "sdfdfdsfsdfdsfsdf")
                return <div key="">
      <div style={{backgroundColor: '#e6e6e6'}} className="row">
        <div className="cell">
          <p className="cellText">{item.game_uuid}</p>
        </div>
        <div className="cell">
          <p className="cellText">{item.participant_name[0].name}</p>
        </div>
        <div className="cell">
          <p className="cellText">{item.correct_count}</p>
        </div>
        <div className="cell">
          <p className="cellText">{item.skipped_count}</p>
        </div>
        <div className="cell">
          <p className="cellText">{item.incorrect_count}</p>
        </div>
        <div className="cell">
          <p className="cellText">{item.total_score}</p>
        </div>
      </div>
      </div>
  })}

</div> */}
            <div>
              {ScoreCardData && ScoreCardData.data && (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th className="header">RANK</th>
                      <th className="header">NAME</th>
                      <th className="header">CORRECT COUNT</th>
                      <th className="header">SKIPPED COUNT</th>
                      <th className="header">INCORRECT COUNT</th>
                      <th className="header">TOTAL SCORE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ScoreCardData.data.map((item, index) => (
                      <tr key={index}>
                        <td className="align-center">{index + 1}</td>
                        <td className="align-center">{item.participant_name[0].name}</td>
                        <td className="align-center">{item.correct_count}</td>
                        <td className="align-center">{item.skipped_count}</td>
                        <td className="align-center">{item.incorrect_count}</td>
                        <td className="align-center">{item.total_score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "20px", fontWeight: "500" }}>
              <Link to="/Stats" style={{ color: 'black', textDecoration: 'none' }}>
                <div style={{ display: "block", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={account} alt="account" width="55px" height="auto" className='scorefooterimg' />
                  </div>
                  <div className='scorefootertext'>
                    Account
                  </div>
                </div>
              </Link>
              <div style={{ display: "block", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <div style={{ display: "flex", justifyContent: "center" }} onClick={Replay}>
                  <img src={replay} alt="replay" width="55px" height="auto" className='scorefooterimg' />
                </div>
                <div className='scorefootertext'>
                  Replay
                </div>
              </div>


              <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                <div style={{ display: "block", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={next} alt="next" width="55px" height="auto" className='scorefooterimg' />
                  </div>
                  <div className='scorefootertext'>
                    Another Play
                  </div>
                </div>
              </Link>

              <div
                onClick={handleDownloadPDF}
                style={{ display: "block", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={download} alt="download" width="55px" height="auto" className='scorefooterimg' />
                </div>
                <div className='scorefootertext'>
                  Download
                </div>
              </div>

            </div>

            {/* <table style={{ width: "100%",}}>
  <tr>
    <th>First Name</th>
    <th>Last Name</th>
    <th>Points</th>
  </tr>
  <tr>
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
  </tr>


</table> */}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ScoreCard;