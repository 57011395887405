import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { secureStorage } from "../../../Utils";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import "./Question.css";
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import rupees from "../../../Assets/rupee.png";
import medal from "../../../Assets/medal.png";
import axios from "axios";
import { GKGetQuestionURL, EntranceGetQuestionURL, CompatitiveGetQuestionURL, AcadmicGetQuestionURL, AnswerSubmitURL, ParticipentScorePostURL } from "../../../Redux/Game/QuestionsRedux/QuestionRedux";
import { GameInstructionListURL } from "../../../Redux/GameInstruction/GameInstructionRedux";
import { ScoreCardListURL } from "../../../Redux/ScoreCard/ScoreCardRedux";


function Questions() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();
  console.log(location.state, "fdgdyuguyduyduys")
  const { GameInstructionData } = useSelector((state) => state.GameInstructionList)
  const { ScoreCardData } = useSelector((state) => state.ScoreCardList)
  console.log(ScoreCardData, " ScoreCardData");

  const { GameListData } = useSelector((state) => state.GameList)

  console.log(GameListData, "GameListData")
  useEffect(() => {
    if (GameListData) {
      dispatch(GameInstructionListURL(GameListData?.data?.uuid))
    }

  }, [GameListData])


  // time for per Question?
  const [timePerQuestion, setTimePerQuestion] = useState();
  // time for total Question?
  const [time, setTime] = useState();
  const [skipBtndisabled, setSkipBtndisabled] = useState(false)


  const [submitbtn, setSubmitBtn] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [gameType, setGameType] = useState("")
  console.log(gameType, "gameType");
  const [answer, setAnswer] = useState("")
  console.log(answer, "answer");
  const [selectedTab, setSelectedTab] = useState(0);

  let userData = secureStorage.getItem("currentUser")
  console.log(userData, "userData")



  const { QuestionData, ScoreForThisExam, notification } = useSelector((state) => state.QuestionList)

  const [backgroundColor, setBackgroundColor] = useState('#8898aa'); // Initial background color

  const handleClick = () => {
    // Change the background color when clicked
    setBackgroundColor('green');
  };

  const ParticipentScorePostFunction = async (payload1, token1) => {
    const funct = async (payload, token) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_WEB_APP}/participant/scorecard`, payload, {
          headers: {
            "x-auth-token": token
          }
        })
        dispatch(ParticipentScorePostURL(res));
        return res?.data;
      }
      catch (res) {
        dispatch(ParticipentScorePostURL({}));
        return;
      }
    }
    const result = await funct(payload1, token1);
    return result;
  }

  const ParticipentScore = () => {
    const payload = {

      "type_uuid": GameListData?.data?.type_uuid,
      "method_uuid": GameListData?.data?.method_uuid,
      "game_uuid": GameListData?.data?.uuid,
      "participant_uuid": userData.data.data.uuid
    }
    ParticipentScorePostFunction(payload, userData.data.token)
      .then((res) => {
        console.log(res, "gfjdshjgdshds")
        if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-A76284AA") {

          dispatch(GKGetQuestionURL(
            // GameInstructionData?.data[0]?.exam_type_uuid,
            // GameInstructionData?.data[0]?.topic_uuid,
            // GameInstructionData?.data[0]?.sub_topic_uuid,
            // GameInstructionData?.data[0]?.type_uuid,
            // GameInstructionData?.data[0]?.method_uuid,

            GameListData?.data?.exam_type_uuid,
            GameListData?.data?.topic_uuid.length === 1 ? GameListData?.data?.topic_uuid[0] : GameListData?.data?.topic_uuid.join(','),
            GameListData?.data?.sub_topic_uuid.length === 1 ? GameListData?.data?.sub_topic_uuid[0] : GameListData?.data?.sub_topic_uuid.join(','),
            GameListData?.data?.type_uuid,
            GameListData?.data?.method_uuid,


          ))

        } else if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-E9018E83") {
          dispatch(EntranceGetQuestionURL(
            GameInstructionData?.data[0]?.exam_type_uuid,
            GameInstructionData?.data[0]?.level_uuid,
            GameInstructionData?.data[0]?.entrance_exam_type_uuid,
            GameInstructionData?.data[0]?.entrance_exam_uuid,
            GameInstructionData?.data[0]?.entrance_sub_paper_uuid,
            GameInstructionData?.data[0]?.board_type,
            GameInstructionData?.data[0]?.state,
            GameInstructionData?.data[0]?.type_uuid,
            GameInstructionData?.data[0]?.method_uuid,

          ))
        } else if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-E2819C81") {
          dispatch(CompatitiveGetQuestionURL(
            GameInstructionData?.data[0]?.exam_type_uuid,
            GameInstructionData?.data[0]?.level_uuid,
            GameInstructionData?.data[0]?.competitive_exam_type_uuid,
            GameInstructionData?.data[0]?.competitive_exam_uuid,
            GameInstructionData?.data[0]?.competitive_sub_paper_uuid,
            GameInstructionData?.data[0]?.board_type,
            GameInstructionData?.data[0]?.state,
            GameInstructionData?.data[0]?.type_uuid,
            GameInstructionData?.data[0]?.method_uuid,

          ))
        }
        else if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-811A8359") {
          dispatch(AcadmicGetQuestionURL(
            GameInstructionData?.data[0]?.exam_type_uuid,
            GameInstructionData?.data[0]?.level_uuid,
            GameInstructionData?.data[0]?.academic_board_uuid,
            GameInstructionData?.data[0]?.academic_stream_uuid,
            GameInstructionData?.data[0]?.academic_group_uuid,
            GameInstructionData?.data[0]?.academic_subject_uuid,
            GameInstructionData?.data[0]?.academic_sub_subject_uuid,
            GameInstructionData?.data[0]?.academic_year,
            GameInstructionData?.data[0]?.academic_semister,
            GameInstructionData?.data[0]?.board_type,
            GameInstructionData?.data[0]?.state,
            GameInstructionData?.data[0]?.type_uuid,
            GameInstructionData?.data[0]?.method_uuid,

          ))
        }
        else {
          console.log("wrong");
        }
        if (GameInstructionData && GameInstructionData?.data[0]?.competitive_types[0].name) {
          setGameType(GameInstructionData?.data[0]?.competitive_types[0]?.name)

        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    ParticipentScore()
  }, [])


  const scorecardUpdate = () => {
    dispatch(ScoreCardListURL(GameListData?.data?.uuid, GameListData?.data?.type_uuid, GameListData?.data?.method_uuid, userData?.data?.token))
  }
  useEffect(() => {
    scorecardUpdate()
  }, [notification])




  const [questions, setQuestions] = useState()
  console.log(questions, "questionssfdssdfsdfdsf");

  // console.log(JSON.parse(questions ? questions :"no data","questionsquestions"))

  const EncryptedData = QuestionData ? QuestionData : [];

  useEffect(() => {

    if (QuestionData.data) {
      decrypt(QuestionData.data)
    }
  }, [QuestionData])

  const CryptoJS = require("crypto-js");

  const SECRET_KEY = ')H@McQfTjWnZr4u7x!A%C*F-JaNdRgUkUkUXNh*F-JaNPLAYER';


  const decrypt = (data) => {

    // data = CryptoJS.AES.decrypt(data, SECRET_KEY);

    // data = data.toString(CryptoJS.enc.Utf8);
    // console.log(data, "datadatadata")
    // const data1 = JSON.parse(data)
    // setQuestions(data1)

    try {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      const data1 = JSON.parse(data)
      setQuestions(data1)
    } catch (error) {
      console.error("JSON parsing error:", error);
    }
    return data;

  }





  // useEffect(() => {
  //   if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-A76284AA") {

  //     dispatch(GKGetQuestionURL(
  //       // GameInstructionData?.data[0]?.exam_type_uuid,
  //       // GameInstructionData?.data[0]?.topic_uuid,
  //       // GameInstructionData?.data[0]?.sub_topic_uuid,
  //       // GameInstructionData?.data[0]?.type_uuid,
  //       // GameInstructionData?.data[0]?.method_uuid,

  //       GameListData?.data?.exam_type_uuid,
  //       GameListData?.data?.topic_uuid.length === 1 ? GameListData?.data?.topic_uuid[0] : GameListData?.data?.topic_uuid.join(','),
  //       GameListData?.data?.sub_topic_uuid.length === 1 ? GameListData?.data?.sub_topic_uuid[0] : GameListData?.data?.sub_topic_uuid.join(','),
  //       GameListData?.data?.type_uuid,
  //       GameListData?.data?.method_uuid,


  //     ))

  //   } else if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-E9018E83") {
  //     dispatch(EntranceGetQuestionURL(
  //       GameInstructionData?.data[0]?.exam_type_uuid,
  //       GameInstructionData?.data[0]?.level_uuid,
  //       GameInstructionData?.data[0]?.entrance_exam_type_uuid,
  //       GameInstructionData?.data[0]?.entrance_exam_uuid,
  //       GameInstructionData?.data[0]?.entrance_sub_paper_uuid,
  //       GameInstructionData?.data[0]?.board_type,
  //       GameInstructionData?.data[0]?.state,
  //       GameInstructionData?.data[0]?.type_uuid,
  //       GameInstructionData?.data[0]?.method_uuid,

  //     ))
  //   } else if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-E2819C81") {
  //     dispatch(CompatitiveGetQuestionURL(
  //       GameInstructionData?.data[0]?.exam_type_uuid,
  //       GameInstructionData?.data[0]?.level_uuid,
  //       GameInstructionData?.data[0]?.competitive_exam_type_uuid,
  //       GameInstructionData?.data[0]?.competitive_exam_uuid,
  //       GameInstructionData?.data[0]?.competitive_sub_paper_uuid,
  //       GameInstructionData?.data[0]?.board_type,
  //       GameInstructionData?.data[0]?.state,
  //       GameInstructionData?.data[0]?.type_uuid,
  //       GameInstructionData?.data[0]?.method_uuid,

  //     ))
  //   }
  //   else if (GameInstructionData && GameInstructionData?.data[0]?.exam_type_uuid === "ET-811A8359") {
  //     dispatch(AcadmicGetQuestionURL(
  //       GameInstructionData?.data[0]?.exam_type_uuid,
  //       GameInstructionData?.data[0]?.level_uuid,
  //       GameInstructionData?.data[0]?.academic_board_uuid,
  //       GameInstructionData?.data[0]?.academic_stream_uuid,
  //       GameInstructionData?.data[0]?.academic_group_uuid,
  //       GameInstructionData?.data[0]?.academic_subject_uuid,
  //       GameInstructionData?.data[0]?.academic_sub_subject_uuid,
  //       GameInstructionData?.data[0]?.academic_year,
  //       GameInstructionData?.data[0]?.academic_semister,
  //       GameInstructionData?.data[0]?.board_type,
  //       GameInstructionData?.data[0]?.state,
  //       GameInstructionData?.data[0]?.type_uuid,
  //       GameInstructionData?.data[0]?.method_uuid,

  //     ))
  //   }
  //   else {
  //     console.log("wrong");
  //   }
  //   if (GameInstructionData && GameInstructionData?.data[0]?.competitive_types[0].name) {
  //     setGameType(GameInstructionData?.data[0]?.competitive_types[0]?.name)

  //   }

  // }, [])




  const HandleSubmit = (response) => {
    // console.log(response, "responsezsfdsfdsfs")

    // const isCorrect = selectedOptionId === correctAnswerId;
    // setIsSubmitted(true);
    setIsSubmitted(true);
    const payload = {
      "type_uuid": GameListData.data.type_uuid,
      "method_uuid": GameListData.data.method_uuid,
      "game_uuid": GameListData.data.uuid,
      "question_uuid": response.questionUuid,
      "participant_uuid": userData.data.data.uuid,
      "user_answer": response.answer
    }

    dispatch(AnswerSubmitURL(payload, userData.data.token))
    // scorecardUpdate()
    setSubmitBtn(true)
    setTimeout(() => {
      handleNextClick()
    }, 1000);
  }

  const HandleSubmitBtn = () => {
    setIsSubmitted(true);

    const payload = {
      "type_uuid": GameListData.data.type_uuid,
      "method_uuid": GameListData.data.method_uuid,
      "game_uuid": GameListData.data.uuid,
      "question_uuid": answer.questionUuid,
      "participant_uuid": userData.data.data.uuid,
      "user_answer": answer.answer
    }

    dispatch(AnswerSubmitURL(payload, userData.data.token))
    // scorecardUpdate()

    setSkipBtndisabled(false)
    const updatedArray = skipdQuesIndex.filter(item => item !== currentQuestionIndex);
    setSkipdQuesIndex(updatedArray);
    //  setSelectedOptionId(null);


    setSubmitBtn(true)
    setTimeout(() => {
      handleNextClick()
    }, 1000);
  }



  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  console.log(currentQuestionIndex, "currentQuestionIndex");
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [userResponses, setUserResponses] = useState([]);



  let trueCount = 0;
  let falseCount = 0;

  for (const response of userResponses) {
    if (response.isCurrectAns) {
      trueCount++;
    } else {
      falseCount++;
    }
  }



  const [skipdQuesIndex, setSkipdQuesIndex] = useState([]);
  console.log(skipdQuesIndex, "skipdQuesIndex");

  const currentQuestion = questions?.data[currentQuestionIndex];
  console.log(currentQuestion, "currentQuestion");
  const SkipQuestion = () => {
    setIsSubmitted(true);
    if (currentQuestion?.uuid) {
      const payload = {
        "type_uuid": GameListData.data.type_uuid,
        "method_uuid": GameListData.data.method_uuid,
        "game_uuid": GameListData.data.uuid,
        "question_uuid": currentQuestion.uuid,
        "participant_uuid": userData.data.data.uuid,
        "user_answer": "skip"
      }
      // sdfsdfsdfsdfdsfsdf

      dispatch(AnswerSubmitURL(payload, userData.data.token))
      // scorecardUpdate()
      setSubmitBtn(true)
      handleNextClick()
      // setSkipdQuesIndex([...skipdQuesIndex, currentQuestionIndex]);

      // if (!skipdQuesIndex.includes(currentQuestionIndex)) {
      //   // If it's not in the array, add it
      //   setSkipdQuesIndex([...skipdQuesIndex, currentQuestionIndex]);
      // }
      if (currentQuestionIndex + 1 === questions?.data?.length) {
        setTimeout(() => {
          history.push({
            pathname: "/ScoreCard",
            state: GameListData
          })
        }, 1000);

      }
    }

  }



  const handleOptionChange = (optionId, answer, isCurrectAns) => {
    console.log(optionId, answer, isCurrectAns, "optionId");
    setSelectedOptionId(optionId);
    setSubmitBtn(false)

    const response = {
      questionUuid: currentQuestion.uuid,
      optionId,
      answer,
      isCurrectAns,
    };
    setAnswer(response)
    // ==========================================================================
    // HandleSubmit(response)
    // If the response for the current question already exists, update it
    if (gameType === "Rapid fire" || gameType === "Buzzer") {
      HandleSubmit(response)
    }



    const updatedResponses = [...userResponses];
    const existingResponseIndex = updatedResponses.findIndex((item) => item.questionUuid === response.questionUuid);

    if (existingResponseIndex !== -1) {
      updatedResponses[existingResponseIndex] = response;
    } else {
      updatedResponses.push(response);
    }

    setUserResponses(updatedResponses);

  };







  const handleNextClick = () => {
    // setTimePerQuestion(10)
    setIsSubmitted(false);
    if (questions?.data3[0]?.time_per_question) {
      setTimePerQuestion(questions?.data3[0]?.time_per_question)
    }

    if (currentQuestionIndex < questions?.data.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOptionId(null);
      const previousQuestionUuid = questions?.data[currentQuestionIndex + 1].uuid;
      const previousResponse = userResponses.find((response) => response.questionUuid === previousQuestionUuid);
      setSelectedOptionId(previousResponse ? previousResponse.optionId : null);
    }
  };



  const handlePreviousClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      // Retrieve the previously selected option for the previous question
      const previousQuestionUuid = questions?.data[currentQuestionIndex - 1].uuid;
      const previousResponse = userResponses.find((response) => response.questionUuid === previousQuestionUuid);
      setSelectedOptionId(previousResponse ? previousResponse.optionId : null);
    }
  };







  useEffect(() => {
    if (questions?.data3[0]?.time_per_question) {
      setTimePerQuestion(questions?.data3[0]?.time_per_question)
      setTime(questions?.data3[0]?.total_duration)
      // setTime(10)

    }
  }, [questions])



  useEffect(() => {

    const interval = setInterval(() => {
      if (timePerQuestion > 0) {
        setTimePerQuestion((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        if (currentQuestionIndex + 1 === questions?.data?.length) {

          history.push({
            pathname: "/ScoreCard",
            state: GameListData
          })
        }
        else {
          SkipQuestion()
        }

      }
    }, 1000);

    return () => {
      clearInterval(interval);
      // SkipQuestion()
    };
  }, [timePerQuestion]);

  useEffect(() => {
    if (currentQuestionIndex + 1 === questions?.data?.length && isSubmitted) {
      setTimeout(() => {
        history.push({
          pathname: "/ScoreCard",
          state: GameListData
        })
      }, 1000);

    }
  }, [currentQuestionIndex, isSubmitted])



  const CancelGame = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "to exit the game ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'exit!',
          'Your are successfully exit',
          'success'
        )
        // setTimeout(() => {

        history.push(({
          pathname: "/",

        }));

        // }, 1000)
      }
    })
  }


  // Initial time in timePerQuestion

  useEffect(() => {
    if (time) {
      let timerInterval = setInterval(() => {
        if (time > 0) {
          setTime((prevTime) => prevTime - 1);
        }

        else {
          clearInterval(timerInterval);

        }


      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    }


  }, [time]);

  if (time === 0) {

    history.push({
      pathname: "/ScoreCard",
      state: GameListData
    })
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const timePerQuestion = time % 60;
    return `${minutes} : ${timePerQuestion} `;
  };



  const TrySkipedQuestion = (event) => {
    console.log(event, "TrySkipedQsdfsfsdfsdfuestion");
    setCurrentQuestionIndex(event)
    setSkipBtndisabled(true)

    if (questions?.data3[0]?.time_per_question) {
      setTimePerQuestion(questions?.data3[0]?.time_per_question)
    }

    // const updatedArray = skipdQuesIndex.filter(item => item !== event);
    // // Update the state with the filtered array
    // setSkipdQuesIndex(updatedArray);

  }
  const HandleClikSkipedForExam = () => {


    if (!skipdQuesIndex.includes(currentQuestionIndex)) {
      // If it's not in the array, add it
      setSkipdQuesIndex([...skipdQuesIndex, currentQuestionIndex]);
    }
    if (questions?.data3[0]?.time_per_question) {
      setTimePerQuestion(questions?.data3[0]?.time_per_question)
    }
  }



  return (
    <>
      <Grid style={{ backgroundImage: "linear-gradient(#2dce89, #2dcecc)" }}>
        <Grid item container className="QuestionContainer" >
          {/* <h1>Countdown Timer: {timePerQuestion} timePerQuestion</h1> */}
          <Grid md={4} xs={12} lg={4}>
            {GameInstructionData && GameInstructionData?.data && GameInstructionData?.data?.map((item) => {

              return <div key="">
                <div
                  style={{
                    background: "white",
                    padding: "10px 10px 40px 10px",
                    borderRadius: "10px",
                    margin: "10px",
                  }}
                >

                  <Grid item container >

                    <Grid md={6} xs={6}>
                      <p style={{ fontWeight: "500", color: "#707070" }}>
                        Quiz | {item?.competitive_types[0]?.name}
                      </p>
                      <p style={{ fontWeight: "500", color: "#707070" }}>
                        Subject : {item?.topic[0]?.name}
                      </p>
                      <p style={{ fontWeight: "500", color: "#000" }}>
                        Game Id :{" "}
                        <span style={{ color: "#2a8e46", fontWeight: "bold" }}>
                          {item?.uuid}
                        </span>{" "}
                      </p>
                    </Grid>
                    <Grid md={6} xs={6}>
                      <p>
                        {/* <UseTimer expiryTimestamp={expiryTimestamp} /> */}

                        <div style={{ textAlign: "center", display: "flex", justifyContent: "flex-start" }}>
                          <div style={{ fontSize: "25px" }}>

                            <span
                              style={{
                                backgroundColor: "black",
                                padding: "3px 4px",
                                borderRadius: "10px",
                                color: "#fff",
                                fontWeight: "bold",
                                margin: "5px"
                              }}
                            >
                              {/* {minutes} */}
                              {formatTime(time)}

                            </span>


                          </div>

                        </div>


                        {/* <h1>Timer: {formatTime(time)}</h1> */}
                      </p>
                      <p style={{ fontWeight: "500", color: "#707070" }}>
                        Total Questions : {item?.competitive_methods[0]?.questions_count}
                      </p>
                      <p
                        style={{ display: "grid", justifyContent: "space-between", cursor: "pointer" }}
                        onClick={CancelGame}
                      >
                        <span style={{ color: "#5e72e4", fontWeight: "bolder", cursor: "pointer" }}>
                          <CloseIcon />
                        </span>
                        <span style={{ color: "red" }}>Exit game</span>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </div>
            })}
          </Grid>

          <Grid md={3} xs={12} lg={3}>

            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              <Grid align="center">

                <div
                  style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "-webkit-box",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    // paddingLeft:"30px"
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontSize: "22px",
                      }}
                    >
                      {/* {item.player[0].name} */}
                    </span>

                    {ScoreCardData?.data?.length > 0 && ScoreCardData?.data?.length !== 0 ?
                      <p style={{ fontWeight: "500", color: "#000" }}>
                        Total Score : {ScoreCardData?.data[0]?.total_score}
                      </p>
                      :
                      <p style={{ fontWeight: "500", color: "#000" }}>
                        Total Score : 0
                      </p>
                    }

                    <div style={{ paddingBottom: "1%" }}>
                      {ScoreCardData?.data?.length > 0 && ScoreCardData?.data?.length !== 0 ?
                        <span style={{ fontWeight: "500", color: "#000", fontSize: "30px" }}>
                          {ScoreCardData?.data[0]?.coins}
                        </span>
                        :
                        <span style={{ fontWeight: "500", color: "#000", fontSize: "30px" }}>
                          0
                        </span>
                      }


                      <img src={rupees} alt="" width="7%" height="auto" />
                    </div>
                  </div>

                </div>



                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div>

                    {ScoreCardData?.data?.length > 0 && ScoreCardData?.data?.length !== 0 ?
                      <span
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#2dce89",
                          display: "block",
                          justifyContent: "center",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {ScoreCardData?.data?.length > 0 && ScoreCardData?.data[0]?.correct_count}
                      </span>
                      :
                      <span
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#2dce89",
                          display: "block",
                          justifyContent: "center",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {ScoreCardData?.data?.length > 0 && ScoreCardData?.data[0]?.correct_count}
                      </span>
                    }

                    <span style={{ fontWeight: "500", color: "#707070" }}>
                      Correct
                    </span>
                  </div>
                  <div>

                    {ScoreCardData?.data?.length > 0 && ScoreCardData?.data?.length !== 0 ?
                      <span
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#fb6340",
                          display: "block",
                          justifyContent: "center",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {ScoreCardData?.data?.length > 0 && ScoreCardData?.data[0]?.skipped_count}
                      </span>
                      :
                      <span
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#fb6340",
                          display: "block",
                          justifyContent: "center",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        0
                      </span>

                    }
                    <span style={{ fontWeight: "500", color: "#707070" }}>
                      {" "}
                      Skipped
                    </span>
                  </div>
                  <div>
                    {ScoreCardData?.data?.length > 0 && ScoreCardData?.data?.length !== 0 ?
                      <span
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#f5365c",
                          display: "block",
                          justifyContent: "center",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >

                        {ScoreCardData?.data?.length > 0 && ScoreCardData?.data[0]?.incorrect_count}
                      </span>
                      :
                      <span
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#f5365c",
                          display: "block",
                          justifyContent: "center",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >

                        0
                      </span>
                    }

                    <span style={{ fontWeight: "500", color: "#707070" }}>
                      {" "}
                      Incorrect
                    </span>
                  </div>
                </div>
              </Grid>
            </div>

          </Grid>
          <Grid md={5} xs={12} lg={5}>
            <div
              style={{
                background: "white",
                padding: "10px 10px 40px 10px",
                borderRadius: "10px",
                margin: "10px",

              }}
            >
              <table className="custom-table">
                <thead>
                  <tr>
                    <th className="header">Player</th>
                    <th className="header">Score</th>
                    <th className="header">Completion</th>
                  </tr>
                </thead>
                <tbody>


                </tbody>
              </table><br />
              <h3 className="align-center "><b>Playing Against System</b></h3><br />

            </div>
          </Grid>

          <Grid md={gameType === "Exam" ? 8 : 12} >
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              <Grid
                item
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >


                <Grid md={4}>
                  <p style={{ fontWeight: "500" }}>Question No :  {currentQuestionIndex + 1}/{questions?.data?.length}

                  </p>
                </Grid>
                <Grid md={4} style={{ textAlign: "center" }}>
                  <p><span style={{ fontWeight: "500" }}>Next Question In :</span> <span style={{ fontWeight: "bold", color: "#000" }}>{timePerQuestion} Seconds</span></p>
                </Grid>
                <Grid md={4} style={{ display: "flex", justifyContent: "end" }}>
                  <p>

                    {/* <Button
                      variant="contained"
                      color="success"
                      onClick={handlePreviousClick}
                      disabled={currentQuestionIndex === 0}>
                      Previous
                    </Button> */}
                    &nbsp;&nbsp;&nbsp;
                    {/* <Button
                      onClick={handleNextClick}
                      disabled={currentQuestionIndex === questions?.data.length - 1}
                      variant="contained" color="success"
                    >
                      Next
                    </Button> */}



                    &nbsp;&nbsp;&nbsp;

                    <Button variant="contained" color="error"
                      disabled={skipBtndisabled}
                      onClick={() => { SkipQuestion(); HandleClikSkipedForExam() }}
                    >
                      Skip
                    </Button>{" "}
                    &nbsp;&nbsp;&nbsp;
                    {/* <Link to="/ScoreCard"> */}



                    {/* ======================================================================================== */}


                    {gameType === "Rapid fire" || gameType === "Buzzer" ?
                      null
                      :
                      <Button variant="contained" color="success"
                        onClick={HandleSubmitBtn}
                        disabled={submitbtn}
                      >
                        Submit
                      </Button>

                    }

                    {/* </Link> */}

                    {" "}
                  </p>
                </Grid>
              </Grid>
              <hr />
              {/* <h3>Question and answer section start */}

              <div className="App">
                {currentQuestionIndex < questions?.data?.length ? (
                  <div>
                    <h2>Question {currentQuestionIndex + 1}: {currentQuestion?.question}</h2>
                    <Grid item container>
                      {gameType === "Rapid fire" || gameType === "Buzzer" ?

                        <>
                          {currentQuestion.options.map((option) => (
                            <Grid md={6} xs={12} >

                              <div onClick={() => handleOptionChange(option?._id, option?.answer, option?.is_correct_answer)} style={{ cursor: "pointer" }}>

                                <div
                                  style={{
                                    background: selectedOptionId === option?._id
                                      ? option?.is_correct_answer
                                        ? "green"
                                        : "red"
                                      : "#8898aa",
                                    padding: "18px",
                                    margin: "10px",
                                    display: "flex",
                                    color: "white",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <label>
                                    {option?.answer}
                                  </label>
                                  <input
                                    style={{ width: "25px", height: "25px" }}
                                    type="radio"
                                    name="options"
                                    value={option?._id}
                                    checked={selectedOptionId === option?._id}
                                    onChange={() => handleOptionChange(option?._id, option?.answer, option?.is_correct_answer)}
                                    onClick={(e) => e.stopPropagation()}
                                  />



                                </div>
                              </div>
                            </Grid>
                          ))}

                        </>

                        :
                        <>
                          {currentQuestion?.options.map((option) => (
                            <Grid md={6} xs={12} key={option?._id}>
                              <div onClick={() => handleOptionChange(option?._id, option?.answer, option?.is_correct_answer)} style={{ cursor: "pointer" }}>
                                <div
                                  style={{
                                    background:
                                      isSubmitted && selectedOptionId === option?._id && option?.is_correct_answer
                                        ? 'green'
                                        : isSubmitted && selectedOptionId === option?._id
                                          ? 'red'
                                          : selectedOptionId === option?._id
                                            ? 'blue'
                                            : '#8898aa',
                                    padding: '18px',
                                    margin: '10px',
                                    display: 'flex',
                                    color: 'white',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <label>{option?.answer}</label>
                                  <input
                                    style={{ width: '25px', height: '25px' }}
                                    type="radio"
                                    name="options"
                                    value={option?._id}
                                    checked={selectedOptionId === option?._id}
                                    // onChange={() => handleOptionChange(option._id)}
                                    onChange={() => handleOptionChange(option?._id, option?.answer, option?.is_correct_answer)}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </>
                      }

                      {/* <button onClick={handleSubmit}>Submit</button> */}

                    </Grid>

                  </div>
                ) : (
                  <h2>Quiz Finished!</h2>
                )}
              </div>

            </div>
          </Grid>

          {gameType === "Exam" ?
            <Grid md={4} xs={4} lg={4} >
              <div
                style={{
                  background: "black",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "10px",
                }}
              >
                <p style={{
                  color: "white",
                  padding: "10px",
                }}>Skiped Questions Palette test</p>


                <Grid item container>

                  {skipdQuesIndex.map((item, index) => (
                    <Grid md={1} key={index}>
                      <div
                        style={{
                          background: "red",
                          color: "white",
                          padding: "5px",
                          // width: "20px",
                          margin: "5px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          alignItems: "center",// Add cursor style to indicate interactivity
                          justifyContent: "center"

                        }}
                        onClick={() => TrySkipedQuestion(item)} // Use 'onClick' instead of 'onChange' for click events
                      >
                        {item + 1}
                      </div>
                    </Grid>
                  ))}

                </Grid>
              </div>

            </Grid>

            :
            null
          }



        </Grid>


      </Grid>






    </>
  );
}

export default Questions;
