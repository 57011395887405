import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  GKSubTopicData: [],
  notification: {}
};

const GKSubTopicSlice = createSlice({
  name: 'G K Sub-Topic',
  initialState,
  reducers: {
    setGKSubTopicData(state, action) {
      state.GKSubTopicData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    },
  },
});

export const { setGKSubTopicData, setToast } = GKSubTopicSlice.actions;


export const GetGKSubTopicListURL = (TopiId,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/subtopics?${TopiId}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setGKSubTopicData(response.data.data));
};



const GKSubTopicListReducer = GKSubTopicSlice.reducer;

export default GKSubTopicListReducer;
