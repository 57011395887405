import React,{useEffect,useState} from "react";
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import '../PlayHistory/History.css'



import axios from "axios";


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import FindFriends from "./FriendsTab/FindFriends";
import FriendsList from "./FriendsTab/FriendsList";
import Chat from "./FriendsTab/Chat";
import PendingRequest from "./FriendsTab/PendingRequest";
import GameSchedule from "./FriendsTab/GameSchedule";
import Header from '../../Components/Header';

const Friends = () => {

    const [selectedTab ,setSelectedTab] = useState(0);


  
 


  return (
    <>
         <Grid item container   style={{background: "linear-gradient(87deg, #172b4d 0, #1a174d 100%)",width:"100%",height:"100vh",padding:"100px 20px"}}>
         <Header />
                <Grid xs={12} md={12} lg={12} >
                    <div style={{ background: "#f8f9fe", padding: "20px", borderRadius: "10px", margin: "10px",height:"80vh", }}>
                       
 

                    <div  className="bottomTabs">
        <div className="tab" style={{ backgroundColor : selectedTab == 0 ? ' #7560e9' : '#7560e9',display:"flex",justifyContent:"center",borderWidth:"2px",borderColor:"#fff",cursor:"pointer"}} onClick={() =>{setSelectedTab(0);}}>
          <div  className="textInfo">
    Find Friends
    {selectedTab === 0 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
        <div className="tab" style={{ backgroundColor : selectedTab == 1 ? "#33d298" : '#33d298',display:"flex",justifyContent:"center",borderWidth:"2px",borderColor:"#fff",cursor:"pointer"}} onClick={() =>{setSelectedTab(1);}}>
        <div  className="textInfo">
        Friends List
        {selectedTab === 1 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
        <div className="tab" style={{ backgroundColor : selectedTab == 2 ? "#272e38"  : '#272e38' ,display:"flex",justifyContent:"center",cursor:"pointer"}} onClick={() =>{setSelectedTab(2);}}>
        <div  className="textInfo">
     Chat
     {selectedTab === 2 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
        <div className="tab" style={{ backgroundColor : selectedTab == 3 ? "#e74c3c" : "#e74c3c",display:"flex",justifyContent:"center",cursor:"pointer"}} onClick={() =>{setSelectedTab(3);}}>
        <div  className="textInfo">
Pending Request
{selectedTab === 3 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
        <div className="tab" style={{ backgroundColor : selectedTab == 4 ? "#29b4f5" : "#29b4f5",display:"flex",justifyContent:"center",cursor:"pointer"}} onClick={() =>{setSelectedTab(4);}}>
        <div  className="textInfo">
Game Sechedule
{selectedTab === 4 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
      </div>
      <Grid xs={12} md={12}  lg={12} style={{marginTop:"2.35%"}}>
        {selectedTab == 0 ? (<FindFriends/>) : selectedTab == 1  ? (<FriendsList/>) : selectedTab == 2  ? (<Chat/>) : selectedTab == 3  ? (<PendingRequest/>) : selectedTab == 4  ? (<GameSchedule/>): null}
        </Grid>



    
                        </div>
                        </Grid>
                        </Grid>
    </>
  )
}

export default Friends;