import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    PlayHistoryData: [],
    PlayHistoryDataCount: [],

    notification:{}
};

const PlayHistorySlice = createSlice({
  name: 'PlayHistoryData',
  initialState,
  reducers: {
    setPlayHistoryData(state, action) {
      state.PlayHistoryData = action.payload;
    },
    setPlayHistoryDataCount(state, action) {
      state.PlayHistoryDataCount = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setPlayHistoryData, setPlayHistoryDataCount, setToast } = PlayHistorySlice.actions;

export const PlayHistoryURL = (pageNum, limit,search, player_uuid,type_uuid,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/play/history?page=${pageNum}&limit=${limit}&search=${search}&player_uuid=${player_uuid}&type_uuid=${type_uuid}`,{headers:{
    "x-auth-token" : token
   
}})
.then((res) => {
    console.log(res, "sdfasdadadad")
  dispatch(setPlayHistoryData(res.data.data));
  dispatch(setPlayHistoryDataCount(res.data.count));
  

})
.catch((err) => {
  console.log(err, "err")
  // dispatch(setPlayHistoryData([]));
})
}


const PlayHistoryReducer = PlayHistorySlice.reducer;

export default PlayHistoryReducer;
