import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import clogin from "../Assets/signup_modal.svg"
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2'


export default function SignUp() {
  const [open, setOpen] = React.useState(false);
  const [openerr, setOpenerr] = React.useState(false);
  const [name, setName]=React.useState("")
  const [userName, setUserName]=React.useState("")
  const [email, setEmail]=React.useState("")
  const [mobile, setMobile]=React.useState("")
  const [password, setPassWord]=React.useState("")
  const[CnfPassWord,setCnfPassWord]=useState("")
  const [ErrMsg, setErrMsg]=useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleSubmit = (e) => {
    e.preventDefault()
    if(password===CnfPassWord){
      const payload = {
        "name" : name,
        // "user_name" : userName,l
        "email" : email,
        "mobile" : mobile,
        "password" : password
      }
      axios.post(`${process.env.REACT_APP_WEB_APP}/user/register`, payload, {
        // headers: {
  
        //   "x-access-token": `${token}`,
        // }
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res, "res")
            handleClose()
            // showNotification("success", "success !", "" + res.data.message)
  
            // handleClose()
            // getUserData()
            setOpenerr(false);
            setErrMsg("")
            Swal.fire({
              icon: 'success',
              title: (res.data.message),
              // text: 'Something went wrong!',
              // footer: '<a href="">Why do I have this issue?</a>'
            })
          
          }
  
  
        })
        .catch((err) => {
          // showNotification("error", "Failed !", "" + err.response.data.message)
          console.log(err)
          setOpenerr(true);
          setErrMsg(err.response.data.message)
        })

    }
    else{
      setOpenerr(true);
          setErrMsg("Password and Confirm Password Should be same")
    }
  }


  return (
    <div>
      <div onClick={handleClickOpen} > Sign Up</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          {"Sign up"}
        </DialogTitle>

        <Grid item container style={{ padding: "20px" }}>
          <Grid md={12} tyle={{ padding: "20px" }} align="center">
            <img src={clogin} style={{ width: "50%" }} />
          </Grid>
          <Grid md={12} style={{padding:"20px"}}>
        <Collapse in={openerr}>
        <Alert
         severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenerr(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         {ErrMsg}
        </Alert>
      </Collapse>
</Grid>
          <Grid md={12} style={{ padding: "20px" }}>
         

            <TextField 
            id="outlined-basic"
             label="Name" 
             variant="outlined" 
             type='text' 
             fullWidth 
             onChange={(e)=>{setName(e.target.value)}}

             />
            <br /><br />
            <TextField 
        id="outlined-basic"
         label="Email" 
         variant="outlined" 
          type='email' 
          fullWidth
          onChange={(e)=>{setEmail(e.target.value)}}

          />
            <br /><br />
            <TextField 
            id="outlined-basic" 
            label="Phone Number"
             variant="outlined" 
             type='number'
              fullWidth 
              onChange={(e)=>{setMobile(e.target.value)}}

              />
         
            {/* <TextField 
            id="outlined-basic"
             label="User Name" 
             variant="outlined" 
             type='text'
              fullWidth 
              onChange={(e)=>{setUserName(e.target.value)}}

              /> */}
            <br /><br />
            <TextField 
        id="outlined-basic"
         label="password" 
         variant="outlined" 
          type='password'
           fullWidth
           onChange={(e)=>{setPassWord(e.target.value)}}

           />
            <br /><br />
            <TextField 
        id="outlined-basic"
         label="Confirm Password" 
         variant="outlined" 
          type='text'
           fullWidth
           onChange={(e)=>{setCnfPassWord(e.target.value)}}

           />

          </Grid>
        </Grid>

        <DialogActions>
          <Button  variant="contained" color="success" type="submmit">Signup</Button>
          <Button onClick={handleClose} autoFocus variant="contained" color="error">
            close
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}