import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  GameListData: [],
  UservsalluserData: {},
  GameCompletedDetails: {},
  GameReplayDetails: {},
  TopplayerList: {},
  notification: {}
};

const GameSlice = createSlice({
  name: 'Game List',
  initialState,
  reducers: {
    setGameListData(state, action) {
      state.GameListData = action.payload;
    },
    setUserVsAllUserData(state, action) {
      state.UservsalluserData = action.payload;
    },
    setGameCompletedDetails(state, action) {
      state.GameCompletedDetails = action.payload;
    },
    setGameReplayDetails(state, action) {
      state.GameReplayDetails = action.payload;
    },
    setTopplayerList(state, action) {
      state.TopplayerList = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setGameListData, setUserVsAllUserData, setGameCompletedDetails, setGameReplayDetails, setTopplayerList, setToast } = GameSlice.actions;


export const GameListGetURL = (token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/game/view/GID-0C3F5A8F`,
    {
      headers: {
        "x-auth-token": token

      }
    }).then((res) => {
      dispatch(setGameListData(res.data));

    })
    .catch((err) => {
      dispatch(setGameListData([]));

    })
}

export const GameAddURL = (payload, token) => async (dispatch) => {
  const response = await axios.post(`${process.env.REACT_APP_WEB_APP}/game/create`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "sdfsdfsdff")
      dispatch(setToast({ status: true, message: res.data.message }))
      dispatch(setGameListData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};

export const UserVsAllUserCreateGame = (payload, token) => async (dispatch) => {
  dispatch(setUserVsAllUserData({}));
  const response = await axios.post(`${process.env.REACT_APP_WEB_APP}/watingroom/create`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "sdfsdfsdff")
      dispatch(setToast({ status: true, message: res.data.message }))
      dispatch(setUserVsAllUserData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};

export const UserVsAllUserCreateGameData = (data) => async (dispatch) => {
  dispatch(setUserVsAllUserData(data));
};

export const GameUpdateURL = (payload, token) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/game/update`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "game update")
      dispatch(setToast({ status: true, message: res.data.message }))
      dispatch(setGameListData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};


export const ReplayGamePostURL = (payload, token) => async (dispatch) => {
  const response = await axios.post(`${process.env.REACT_APP_WEB_APP}/game/replay`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "sdfsdfssdfsdfsdfdsfdff")
      dispatch(setToast({ status: true, message: res.data.message }))
      dispatch(setGameListData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};

export const IsGameStartedUrl = (GameUuid, payload, token) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/game/started/status/update/${GameUuid}`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "sdfsdfssdfsdfsdfdsfdff")
      dispatch(setToast({ status: true, message: res.data.message }))
      // dispatch(setGameListData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};

export const GameComplitedUrl = (GameUuid, payload, token) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/game/completed/status/update/${GameUuid}`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "sdfsdfssdfsdfsdfdsfdff")
      dispatch(setGameCompletedDetails(res?.data))
      dispatch(setToast({ status: true, message: res.data.message }))
      // dispatch(setGameListData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};

export const GameReplyApi = (payload, token) => async (dispatch) => {
  const response = await axios.post(`${process.env.REACT_APP_WEB_APP}/watingroom/replay`, payload, {
    headers: {
      "x-auth-token": token
    }
  })
    .then((res) => {
      console.log(res, "sdfsdfssdfsdfsdfdsfdff")
      dispatch(setUserVsAllUserData(res?.data))
      dispatch(setToast({ status: true, message: res.data.message }))
      // dispatch(setGameListData(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};

export const TopPlayerApi = () => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/user/top/players`)
    .then((res) => {
      console.log(res, "sdfsdfsdff")
      dispatch(setToast({ status: true, message: res.data.message }))
      dispatch(setTopplayerList(res.data));
    })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))

    })
};


const GameReducer = GameSlice.reducer;

export default GameReducer;
