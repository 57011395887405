import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  GameTypeData: [],
  notification:{}
};

const GameTypeSlice = createSlice({
  name: 'Game List',
  initialState,
  reducers: {
    setGameTypeData(state, action) {
      state.GameTypeData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setGameTypeData, setToast } = GameTypeSlice.actions;


export const GameTypeGetURL = (token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/competitive/type/user/list?page=&limit=&search=`,
  {headers:{
    "x-auth-token" : token

}}).then((res) => {
  dispatch(setGameTypeData(res.data));

})
.catch((err) => {
  dispatch(setGameTypeData([]));

})
}


  

const GameTypeReducer = GameTypeSlice.reducer;

export default GameTypeReducer;
