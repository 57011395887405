import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
// import {
//   Row,
//   Col,
//   Dropdown,
//   Form,
//   Card,
//   Badge,
//   Tooltip,
//   OverlayTrigger,
//   Modal,
// } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import infoImage from "../../../Assets/infoImage.avif";
import { secureStorage } from "../../../Utils";
import { UpdateProfileURL } from "../../../Redux/Game/Stats/UpdateProfileRedux";
import { StatsListURL } from "../../../Redux/Game/Stats/StatsRedux";
import Swal from 'sweetalert2';
import '../Stats.css';
import axios from "axios";

const PersonalInfo = ({ onClick }) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { UpdateProfileData ,notification } = useSelector((state) => state.UpdateProfileList);
  console.log(UpdateProfileData, "notification");

  const { StatsListData } = useSelector((state) => state.StatsList)
  console.log(StatsListData,"gdsdfdfssdsfd");

  const [suc, setSuc] = useState(false);
  const [name, setName] = useState(StatsListData?.data?.name);
  const [email, setEmail] = useState(StatsListData?.data?.email);
  const [mobile, setMobile] = useState(StatsListData?.data?.mobile);

  console.log(name,"dsjhfdjkfhdsk")

  useEffect(() => {
    setName(StatsListData?.data?.name);
    setEmail(StatsListData?.data?.email);
    setMobile(StatsListData?.data?.mobile);
  }, []);
  // const { currentUser } = useSelector((state) => state.auth)

  // if (currentUser) {
  //   const { token, uuid,payload } = currentUser;
  //   // Now you can safely use username and email
  // } else {
  //   // Handle the case where currentUser is undefined
  // }
  const details = secureStorage.getItem("currentUser");
  console.log(details, "dfdgfddfdg");

  const [upload, setUpload] = useState();

  const imageupload = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0])
    console.log(event.target.files[0], "jkltyuiop")
    axios.post(`${process.env.REACT_APP_WEB_APP}/upload/image`, formData)
      .then((res) => {
        console.log(res,"bvcsavb")
        setUpload(res.data.image.filename)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const StatsUpdate = (e) => {
    e.preventDefault();
    const payload = {
      name: name,
      email: email,
      mobile: mobile,
      profile_pic: upload === undefined ? StatsListData?.data?.profile_pic.replace('https://api.quizapp.scienstechnologies.com/images/',"") : upload ,
    };
    dispatch(UpdateProfileURL(details?.data?.token, details?.data?.data?.uuid, payload));
    setSuc(true)
    setTimeout(()=>{
      dispatch(StatsListURL(details?.data?.token , details?.data?.data?.uuid))
    })
    console.log(UpdateProfileData, "ajdvjhdgjkadkajs");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(true);
  };


  const Back = () => {
    history.goBack()
}



useEffect(() => {
  if (suc === true) {
      if (notification.status === true) {
          // toast.success(notification.message, {
          //   position: "top-right",
          // })
          setSuc(false)
          Swal.fire({
            icon: 'success',
            title: (notification.message),
            // text: 'Something went wrong!',
            // footer: '<a href="">Why do I have this issue?</a>'
            
          }).then((result) => {
            if (result.isConfirmed) {
              // Call your function here
              setTimeout(() => {
               onClick()
              }, 1000)
            }
          });
        






           

         
      }
      else if (notification.status === false) {
          // toast.error(notification.message)
          setSuc(false)
      
          Swal.fire({
            icon: 'error',
            title: (notification.message.message),
            // text: 'Something went wrong!',
            // footer: '<a href="">Why do I have this issue?</a>'
          })
          // .then((result) => {
          //   if (result.isConfirmed) {
          //     // Call your function here
          //     setTimeout(() => {
          //       props.onClick()
          //     }, 1000)
          //   }
          // });
      

      }
  }

}, [notification])
console.log(notification, "ProductDataProductData")


  return (
    <>
      <Grid item container style={{ padding: "20px" }}>
      
          {/* <Grid
            xs={12}
            md={12}
            style={{ paddingBottom: "20px" }}
            align="center"
          >
            <img
              src={UpdateProfileData?.data?.profile_pic}
              style={{ width: "40%" }}
              alt=""
              crossOrigin="anonymous"
            />
          </Grid> */}
          <Grid xs={12} md={12} lg={12}>
          <form onSubmit={StatsUpdate}>
            <Grid
            xs={12}
            md={12}
            style={{ paddingBottom: "20px" }}
            align="center"
          >
            <img
              src={StatsListData?.data?.profile_pic || 'fallback_image_url.jpg'}
              style={{ width: "30%" }}
              alt=""
              crossOrigin="anonymous"
            />
          </Grid>
            <TextField
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
              type="text"
              fullWidth
              // maxWidth="xxl"
              value={name}
              onChange={(e) => setName(e.target.value)}
              defaultValue={StatsListData?.data?.name}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <br />
            {/* <TextField
              id="outlined-basic"
              // label="Email"
              variant="outlined"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={StatsListData?.data?.email}
              readonly
            /> */}
            <TextField
  disabled
  id="outlined-disabled"
  label="Email"
  fullWidth
  defaultValue={StatsListData?.data?.email}

/>
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              type="number"
              fullWidth
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              defaultValue={StatsListData?.data?.mobile}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <br />

            <TextField
              id="outlined-size-normal"
              type="file"
              label="Profile Picture"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              // value={upload}
              // onChange={(e) => setProfilePic(UpdateProfileData?.profile_pic?.replace("https://api.quizapp.scienstechnologies.com/images/1694519414769-Recop (1).png", ''))}
              onChange={imageupload}
            />

            <Grid style={{ float: "right", marginTop: "4%" }}>
              <DialogActions>
                <Button  variant="contained" color="error" onClick={onClick}>Close</Button>
                <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  color="success"
                >
                  Update
                </Button>
              </DialogActions>
            </Grid>
            </form>
          </Grid>
       
      </Grid>
    </>
  );
};
export default PersonalInfo;
