import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  ScoreCardData: [],
  notification: {}
};

const ScoreCardSlice = createSlice({
  name: 'ScoreCard',
  initialState,
  reducers: {
    setScoreCardData(state, action) {
      state.ScoreCardData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setScoreCardData, setToast } = ScoreCardSlice.actions;


export const ScoreCardListURL = (gameId, typeId, methodId, token) => async (dispatch) => {
  // dispatch(setScoreCardData([]));
  dispatch(clearScoreCardData());
  try {
    const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/scorecard?game_uuid=${gameId}&type_uuid=${typeId}&method_uuid=${methodId}`,
      {
        headers: {
          "x-auth-token": token
        }
      })
      .then((res) => {
        dispatch(setScoreCardData(res.data));
        console.log(res, "wereewrwer")
      })
  }
  catch (err) {
    dispatch(setScoreCardData([]));
  }
}

export const clearScoreCardData = () => async (dispatch) => {
  dispatch(setScoreCardData([]));
};

export const ScoreCardDownloadURL = (token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/download/pdf?game_uuid=GID-25C0F7F0&participant_uuid=PR-25218776`, {
    headers: {
      "x-auth-token": token

    }
  }).then((res) => {
    dispatch(setScoreCardData(res.data));
    console.log(res.data, "dfghj")
  })
    .catch((err) => {
      dispatch(setScoreCardData([]));
    })
}





const ScoreCardReducer = ScoreCardSlice.reducer;

export default ScoreCardReducer;
