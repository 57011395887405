import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from 'react-redux';
import "./gameInstruction.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Llogo from "../../../Assets/no1exam_logo.png";
import Button from "@mui/material/Button";
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import Header from '../../../Components/Header';
import { GameInstructionListURL } from "../../../Redux/GameInstruction/GameInstructionRedux";
import { CategoryListURL } from "../../../Redux/ExamCategoryRedux/ExamCategoryRedux";
import { GKGetQuestionURL, ParticipentScorePostURL } from "../../../Redux/Game/QuestionsRedux/QuestionRedux";
import { secureStorage } from "../../../Utils";
import { CoinsDuductionURL } from "../../../Redux/Game/Stats/UpdateProfileRedux";
import { ScoreCardListURL } from "../../../Redux/ScoreCard/ScoreCardRedux";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { GameListGetURL, GameAddURL, ReplayGamePostURL } from '../../../Redux/Game/GameListRedux';
import { IsGameStartedUrl } from "../../../Redux/Game/GameListRedux";



function GameInstruction(props) {
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();
  console.log(location.state, "location11111111111");
  const { GameListData } = useSelector((state) => state.GameList)
  const { UpdateProfileData, notification } = useSelector((state) => state.UpdateProfileList)
  // const { GameListData, notification } = useSelector((state) => state.GameList)


  let userData = secureStorage.getItem("currentUser")
  console.log(userData.data.data.uuid, "userData")

  console.log(GameListData, "GameListData");
  const { GameInstructionData } = useSelector((state) => state.GameInstructionList)
  console.log(GameInstructionData, "GameInstructionData");


  const ParticipentScore = () => {
    const payload = {
      "type_uuid": GameListData?.data?.type_uuid,
      "method_uuid": GameListData?.data?.method_uuid,
      "game_uuid": GameListData?.data?.uuid,
      "participant_uuid": userData?.data?.data?.uuid
    }
    dispatch(ParticipentScorePostURL(payload, userData?.data?.token))
  }


  useEffect(() => {
    if (GameListData) {
      ParticipentScore()
      dispatch(GameInstructionListURL(GameListData?.data?.uuid))
      dispatch(ScoreCardListURL(GameListData?.data?.uuid, GameListData?.data?.type_uuid, GameListData?.data?.method_uuid, userData?.data?.token))
      console.log(GameInstructionData, "GameInstructionData")
    }
  }, [GameListData])


  // const scorecardUpdate =()=>{
  //   dispatch(ScoreCardListURL(GameListData.data.uuid, GameListData.data.type_uuid, GameListData.data.method_uuid, userData?.data?.token))
  //   }




  // exam type list api

  const { categoryData } = useSelector((state) => state.categoryList)
  console.log(categoryData, "categoryData");

  const [suc, setSuc] = useState(false);


  const ReplayGame = () => {
    const payload = {
      "game_uuid": GameListData?.data?.uuid,
      "participant_uuid": userData?.data?.data?.uuid
    }
    dispatch(ReplayGamePostURL(payload, userData?.data?.token))
  }


  const CoinDeduction = () => {
    // setSuc(true)
    if (location.state === "uservssystem") {
      const payload = {
        "is_game_started": "true"
      }
      dispatch(IsGameStartedUrl(GameListData?.data?.uuid, payload, userData?.data?.token))

      setTimeout(() => {

        history.push(({
          pathname: "/questions",

        }));
      }, 1000)
    }
    else {
      const payload = {
        "user_uuid": userData?.data?.data?.uuid,
        "type_uuid": GameListData?.data?.type_uuid,

      }
      dispatch(CoinsDuductionURL(payload, userData.data.token))
      setSuc(true)
    }
  }





  useEffect(() => {
    dispatch(CategoryListURL())
  }, [])







  useEffect(() => {
    if (suc === true) {
      if (notification.status === true) {
        Swal.fire({
          icon: 'success',
          title: (notification.message),
        })
        if (location?.state === "replay") {
          ReplayGame()
        }
        // ParticipentScore()
        const payload = {
          "is_game_started": "true"
        }
        dispatch(IsGameStartedUrl(GameListData?.data?.uuid, payload, userData?.data?.token))

        setTimeout(() => {

          history.push(({
            pathname: "/questions",

          }));
        }, 1000)
        setSuc(false)

      }
      else if (notification.status === false) {
        // toast.error(notification.message)

        Swal.fire({
          icon: 'error',
          title: (notification.message.message),
        })
        setSuc(false)
      }
    }

  }, [notification])
  console.log(notification, "ProductDataProductData")

  return (
    <>
      <Grid item container className="gameInstContainer">
        <Header />
        <Grid md={9}>
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              //   height: "40vh",
              //   overflowY: "scroll",
            }}
          >
            <Grid align="center" >
              <h1 className="Gameheader">Game Instructions</h1>
            </Grid>
            <div
              style={{ padding: "20px", height: "60vh", overflowY: "scroll" }}
            >
              <div>
                <h3>Before beginning the exam</h3>
                <p>1. Make sure you have a good internet connection</p>
                <p>
                  2. Highly recommended to user googlechrome browser for game
                  play for better smooth experience.
                </p>
                <p>
                  3. Keep browser window in maximize.Minimizing the game window
                  could lead to time wastage.
                </p>
              </div>

              <div>
                <h3>During the exam</h3>
                <p>1. Make sure you have a good internet connection</p>
                <p>
                  2. Highly recommended to user googlechrome browser for game
                  play for better smooth experience.
                </p>
                <p>
                  3. Keep browser window in maximize.Minimizing the game window
                  will lead to time wastage.
                </p>
                <p>
                  4. Do not refresh game window at any cost,this will lead to
                  end of game and make you lose all coins you gained during the
                  game.
                </p>
              </div>
              <div>
                <h3>Score Calculations</h3>
                {GameInstructionData && GameInstructionData.data && GameInstructionData.data.map((item) => {
                  console.log(item, "sdfdfdsfsdfdsfsdf")
                  return <div key="">
                    <h3>Game Id : {item.uuid}</h3>
                    <p>1. Competition Type : {item.competitive_types[0].name}</p>
                    <p>2. Competition Method : {item.competitive_methods[0].name}</p>
                    <p>3. Total Allowed Players : {item.competitive_methods[0].total_players}</p>
                    <p>4. Total Questions : {item.competitive_methods[0].questions_count}</p>

                    <p>5. Total Duration : {item.competitive_methods[0].total_duration / 60}  Minutes</p>
                    <p>6. Time per question : {item.competitive_methods[0].time_per_question} Seconds</p>
                    <p>
                      7. Quick Answer Time : {item.competitive_methods[0].quick_answer_time} {Number(item.competitive_methods[0].quick_answer_time) > 2 ? "Points" : "Point"} (If player answers a question within
                      quick answer time player gets bonus 2 points for correct
                      answer)(Only applicable for BUZZER game)
                    </p>
                    <p>8. Score for correct answer : {item.competitive_methods[0].correct_answer_value} {Number(item.competitive_methods[0].correct_answer_value) > 1 ? "Points" : "Point"}</p>

                    <p>9. Score for in-correct answer : {item.competitive_methods[0].negative_answer_value} {Number(item.competitive_methods[0].negative_answer_value) > 1 ? "Points" : "Point"}</p>
                    <p>10. If question skipped : {String(item.competitive_methods[0].skip)}</p>
                  </div>
                })}
              </div>
              <div>
                <h3>U can Share</h3>
                <FacebookIcon />
                <TwitterIcon />
                <LinkedInIcon />

                <p>
                  If you encounter problems while joining the game or during the
                  game play,you must contact our support team by email or phone.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid md={3}>
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
            }}
          >
            <img src={Llogo} alt="" width="100%" height="100%" />
            <br />
            <br />
            {/* <Link to="/questions"> */}
            <Button variant="contained" fullWidth color="success" onClick={CoinDeduction}>
              Start Game
            </Button>
            {/* </Link> */}
            <br />
            <br />
            <Link to="/">
              <Button variant="contained" fullWidth color="error">
                Back to Home
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default GameInstruction;
