import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  ExamTypeData: [],
  notification:{}
};

const ExamTypeSlice = createSlice({
  name: 'ExamType',
  initialState,
  reducers: {
    setExamTypeData(state, action) {
      state.ExamTypeData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setExamTypeData, setToast } = ExamTypeSlice.actions;


export const ExamTypeListURL = (educationLevelUuid,board, state,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/competitive/examtype?level_uuid=${educationLevelUuid}&board_type=${board}&state=${state}`,{headers:{
    "x-auth-token" : token
  }}).then((res) => {
  
    dispatch(setExamTypeData(res.data));
  })
  .catch((err) => {
    dispatch(setExamTypeData([]));

  })
}


// export const ExamTypeAddURL = (payload,token) => async (dispatch) => {
//   const response = await axios.post(`${process.env.REACT_APP_URL}/competitive/exam/type/add`,payload,{headers:{
//     "x-auth-token" : token
//   }})
//   .then((res) => {
//     console.log(res, "sdfsdfsdff")
//     dispatch(setToast({ status: true, message: res.data.message }))
//   })
//   .catch((err) => {
//     dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))

//   })
// };


  


//   export const ExamTypeUpdateURL = (payload, uuid ,token) => async (dispatch) => {
//     const response = await axios.put(`${process.env.REACT_APP_URL}/competitive/exam/type/update/${uuid}`, payload, {
//       headers: {
//         "x-auth-token": token
//       }
//     }).then((res) => {
//       console.log(res, "sdfsddffsdff")
//       dispatch(setToast({ status: true, message: res.data.message }))
//     })
//       .catch((err) => {
//         console.log(err && err.response,"hjgjghgjhghj")
//         dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
//       })
//     // console.log(response, "sdfsfsdfs")
  
//   };

//   export const  ExamTypeStatusUpdateURL = ( id, payload, token) => async (dispatch) => {
//     const response = await axios.put(`${process.env.REACT_APP_URL}/board/update/status/${id}`, payload, {
//       headers: {
//         "x-auth-token": token
//       }
//     }).then((res) => {
//       console.log(res, "sdfsddffsdff")
//       dispatch(setToast({ status: true, message: res.data.message }))
//     })
//       .catch((err) => {
//         console.log(err && err.response,"hjgjghgjhghj")
//         dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
//       })
 
//   };




const ExamTypeReducer = ExamTypeSlice.reducer;

export default ExamTypeReducer;
