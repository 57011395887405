import React,{useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from 'react-redux';
import { secureStorage } from "../../Utils";
import './History.css'
import { PlayHistoryURL } from "../../Redux/Game/Stats/PlayHistoryRedux";

const RapidFireHistory = (props) =>{

  console.log(props?.uuid, "ghdfghfgs")

  const dispatch = useDispatch()

  const [pageNum, setPageNum] = useState(0);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState('')
  console.log(search, "search");

  const handleDropdownChange = (e) => {
    setLimit(e.target.value);
  };
  const { PlayHistoryData } = useSelector((state) => state.PlayHistoryList)
  const { PlayHistoryDataCount } = useSelector((state) => state.PlayHistoryList)


  const details = secureStorage.getItem('currentUser')
  console.log(details, "details")
  const playerid = details?.data?.data?.uuid

  useEffect(() => {
    dispatch(PlayHistoryURL(pageNum, limit, search, playerid, props.uuid, details?.data?.token));
  }, [pageNum, limit, search])


  const paginationNext = (e) => {
    setPageNum(pageNum + 1)
  }
  const paginationPrev = (e) => {
    setPageNum(pageNum - 1)
  }

    return(
        <>
        <div>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"1%"}}>
               
                <div>
            <div>
              <label htmlFor="dropdown" className="headertext">Show </label>
              <select id="dropdown" value={limit} onChange={handleDropdownChange} style={{ padding: "3px 20px" }} className="headerinput">

                <option value="1">1</option>
                <option value="5" >5</option>
                <option value="10" >10</option>
                <option value="15" >15</option>
                <option value="20" >20</option>
              </select>
              <label htmlFor="dropdown" className="headertext"> entries </label>
            </div>
          </div>
          <div className="headertext" style={{ display: "flex", justifyContent: "end" }}>
            Search:&nbsp; <input style={{ padding: "3px 5px" }} onChange={(e) => { setSearch(e.target.value) }} className="headerinput" />
          </div>
            </div>
        {/* <div style={ {backgroundColor: '#fff',border:'1px solid #ddd'}} className="row">
        <div className="cell">
          <p  className="cellText">NO</p>
        </div>
        <div className="cell">
          <p className="cellText">METHODE</p>
        </div>
        <div className="cell">
          <p className="cellText">SUBJECT</p>
        </div>
        <div className="cell">
          <p className="cellText">TOTAL SCORE</p>
        </div>
        <div className="cell">
          <p className="cellText">COINS</p>
        </div>
        <div className="cell">
          <p className="cellText">RANK</p>
        </div>
      </div>
      {PlayHistoryData && PlayHistoryData?.map((item,index) =>{
        console.log(item,"wewrwer")
        return <div key={''}>
      <div style={{backgroundColor: '#e6e6e6',marginBottom:"10px"}} className="row">
        <div className="cell">
          <p className="cellText">{index+1}</p>
        </div>
        <div className="cell">
          <p className="cellText">{item?.competitive_method[0]?.name}</p>
        </div>
        <div className="cell">
          <p className="cellText">
            {item?.topic[0]?.name}
          </p>
        </div>
        <div className="cell">
          <p className="cellText"> {item?.score_card[0]?.total_score}</p>
        </div>
        <div className="cell">
          <p className="cellText"> {item?.score_card[0]?.coins}</p>
        </div>
        <div className="cell">
          <p className="cellText">1</p>
        </div>
      </div>
      </div>
      })} */}

<div>
      <table className="custom-table">
        <thead>
          <tr>
            <th className="header">NO</th>
            <th className="header">METHODE</th>
            <th className="header">SUBJECT</th>
            <th className="header">TOTAL SCORE</th>
            <th className="header">COINS</th>
            <th className="header">RANK</th>
          </tr>
        </thead>
        <tbody>
          {PlayHistoryData && PlayHistoryData.map((item, index) => (
            <tr key={index}>
              <td className="align-center">{index + 1}</td>
              <td className="align-center">{item?.competitive_method[0]?.name}</td>
              <td className="align-center">{item?.topic[0]?.name}</td>
              <td className="align-center">{item?.score_card[0]?.total_score}</td>
              <td className="align-center">{item.incorrect_count}</td>
              <td className="align-center">{item.total_score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1%" }}>
          <div className="headertext">
          Showing {PlayHistoryData.length} of {PlayHistoryDataCount} entries
          </div>
          <div>
            <button style={{ border: "1px solid #ddd", padding: "8px 30px", cursor: "pointer" }}
              //  className="footerbutton"
              disabled={pageNum === 0}
              onClick={paginationPrev}
            >Previous</button>
            <button style={{ border: "1px solid #ddd", padding: "8px 30px", cursor: "pointer" }}

              //  className="footerbutton" 
              onClick={paginationNext}
              disabled={pageNum + 1 > PlayHistoryDataCount / limit}
            >Next</button>
          </div>
        </div>
        </div>
        </>
    )
};

export default RapidFireHistory;