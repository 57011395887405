import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    BoardData: [],
  notification:{}
};

const BoardSlice = createSlice({
  name: 'Board',
  initialState,
  reducers: {
    setBoardData(state, action) {
      state.BoardData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setBoardData, setToast } = BoardSlice.actions;


export const BoardListURL = (board,state,search,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/academic/board?board_category=university&board_type=${board}&state=${state}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setBoardData(response.data));
};







const BoardReducer = BoardSlice.reducer;

export default BoardReducer;
