import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  EntranceExamTypeData: [],
  notification:{}
};

const EntranceExamTypeSlice = createSlice({
  name: 'ExamType',
  initialState,
  reducers: {
    setEntranceExamTypeData(state, action) {
      state.EntranceExamTypeData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setEntranceExamTypeData, setToast } = EntranceExamTypeSlice.actions;


export const EntanceExamTypeGetURL = (educationLevelUuid, BoardType,stateName, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/entrance/examtype?level_uuid=${educationLevelUuid}&board_type=${BoardType}&state_name=${stateName}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setEntranceExamTypeData(response.data));
};





const EntranceExamTypeReducer = EntranceExamTypeSlice.reducer;

export default EntranceExamTypeReducer;
