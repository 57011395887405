// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';
// import categoryReducer from './Redux/TestRedux';
import categoryReducer from './Redux/ExamCategoryRedux/ExamCategoryRedux';
import GKTopicListReducer from './Redux/GKExamRedux/GKExamRedux';
import GKSubTopicListReducer from './Redux/GKExamRedux/GKExamSubTopicRedux';
import ExamTypeReducer from './Redux/Competitve Exams/ExamtypeRedux';
import CompatativeExamReducer from './Redux/Competitve Exams/CompatativeExamRedux';
import CompatativeSubPpaperReducer from './Redux/Competitve Exams/ComtativeSubPpaerRedux';
import EducationReducer from './Redux/EducationRedux/EducationRedux';
import LocationReducer from './Redux/Location/LocationRedux';
import EntranceExamTypeReducer from './Redux/EntranceExam/EntranceEamTypeRedux';
import EntranceExamReducer from './Redux/EntranceExam/EntranceExam';
import SubPaperReducer from './Redux/EntranceExam/SubPaperRedux';
import BoardReducer from './Redux/Acadamic Exams/Board/BoardRedux';
import GroupReducer from './Redux/Acadamic Exams/Group/GroupRedux';
import StreamsReducer from './Redux/Acadamic Exams/Streams/StreamsRedux';
import SubSubjectReducer from './Redux/Acadamic Exams/Sub Subject/SubSubjectRedux';
import SubjectReducer from './Redux/Acadamic Exams/Subject/SubjectRedux';
import GameReducer from './Redux/Game/GameListRedux';
import GameTypeReducer from './Redux/Game/selectGameRedux/GameTypeRedux';
import QuizMethodReducer from './Redux/Game/selectGameRedux/QuizMethodRedux';
import QuestionReducer from './Redux/Game/QuestionsRedux/QuestionRedux';
import GameInstructionReducer from './Redux/GameInstruction/GameInstructionRedux';
import GameInstructionReducerUserVsUser from './Redux/GameInstruction/GameInstructionUserVsUserRedux.js';
import ScoreCardReducer from './Redux/ScoreCard/ScoreCardRedux';
import StatsListReducer from './Redux/Game/Stats/StatsRedux';
import PlayHistoryReducer from './Redux/Game/Stats/PlayHistoryRedux';
import PlayHistoryHeaderReducer from './Redux/Game/Stats/PlayHistoryHeader';
import { REDUX_PERSIST_KEY } from './config.js';
import UpdateProfileReducer from './Redux/Game/Stats/UpdateProfileRedux';




const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  // whitelist: ['menu', 'settings', 'lang'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    
    EducationList:EducationReducer,

    ExamTypeList:ExamTypeReducer,
    CompatativeSubPpaperList:CompatativeSubPpaperReducer,
    CompatativeExamList:CompatativeExamReducer,

    categoryList:categoryReducer,
    GKTopicList:GKTopicListReducer,
    GKSubTopicList:GKSubTopicListReducer,
    LocationList:LocationReducer,
    EntranceExamTypeList:EntranceExamTypeReducer,
    EntranceExamList:EntranceExamReducer,
    SubPaperList:SubPaperReducer,

    BoardList:BoardReducer,
    StreamsList:StreamsReducer,
    GroupList:GroupReducer,
    SubjectList:SubjectReducer,
    SubSubjectList:SubSubjectReducer,
    GameList:GameReducer,
    GameTypeList:GameTypeReducer,
    QuizMethodList:QuizMethodReducer,
    QuestionList:QuestionReducer,
    GameInstructionList:GameInstructionReducer,
    GameInstructionListUserVsUser:GameInstructionReducerUserVsUser,
    ScoreCardList:ScoreCardReducer,
    StatsList:StatsListReducer,
    PlayHistoryList:PlayHistoryReducer,
    PlayHistoryHeaderList:PlayHistoryHeaderReducer,
    UpdateProfileList:UpdateProfileReducer
    




    

  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
