import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    GroupData: [],
  notification:{}
};

const GroupSlice = createSlice({
  name: 'Group',
  initialState,
  reducers: {
    setGroupData(state, action) {
      state.GroupData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setGroupData, setToast } = GroupSlice.actions;


export const GroupListURL = (educationLevelUuid,academicStreamUuid,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/academic/group?level_uuid=${educationLevelUuid}&stream_uuid=${academicStreamUuid}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setGroupData(response.data));
};





const GroupReducer = GroupSlice.reducer;

export default GroupReducer;
