import React ,{useEffect, useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Link ,useHistory, useLocation} from "react-router-dom";

import Grid from '@mui/material/Grid';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./auth.css";
import Swal from 'sweetalert2';

function Otp() {

  const history = useHistory();
  const location = useLocation();

  console.log(location.state,"location121212");


    const [email, setEmail]=useState("")
    const [otp, setOtp]=useState("")
    const [Password,setPassword]=useState("")
    const [ConfirmPassword,setConfirmPassword]=useState("")

useEffect(()=>{
  setEmail(location?.state)
})

    const handleSubmit = (e) => {
      e.preventDefault()
if(Password===ConfirmPassword){

  const payload = {
    "email" : email,
    "otp" : otp,
    "newPassword" : Password

  }
  axios.post(`${process.env.REACT_APP_WEB_APP}/user/verify/otp`, payload, {
  })
    .then((res) => {
      if (res.status === 200) {
        console.log(res, "ressadasdasd")
        Swal.fire({
          icon: 'success',
          title: (res.data.message),
        })
      }
      setTimeout(() => {
            
        history.push(({
            pathname: "/",
        }));
    }, 1000)


    })
    .catch((err) => {
      console.log(err.response.data.message,"dasdasdasdad")
      Swal.fire({
        icon: 'error',
        title: (err?.response?.data?.message  ? err.response.data.message : "Somthing Went Wrong" ),
      
      })
    })

}
else{
  Swal.fire({
    icon: 'error',
    title: ("Password and Confirm Password Should be same" ),
  
  })
}

       
      }
  return (
    <>
     <Grid item container style={{padding:"200px"}}>
        <Grid md={12} align="center">
        <div className="forgatpasswod" style={{padding:"10px"}}>
        <form onSubmit={handleSubmit}>
        <TextField 
        id="outlined-basic"
         label="Email" 
         variant="outlined" 
          type='text' 
          fullWidth
          onChange={(e)=>{setEmail(e.target.value)}}
          disabled
          value={email}

          />
          <br/>
          <br/>
        <TextField 
        id="outlined-basic"
         label="OTP" 
         variant="outlined" 
          type='number' 
          fullWidth
          onChange={(e)=>{setOtp(e.target.value)}}

          />
          <br/>
          <br/>
          <TextField 
        id="outlined-basic"
         label="Password" 
         variant="outlined" 
          type='password' 
          fullWidth
          onChange={(e)=>{setPassword(e.target.value)}}

          />
          <br/>
          <br/>
          <TextField 
        id="outlined-basic"
         label="Confirm Password" 
         variant="outlined" 
          type='text' 
          fullWidth
          onChange={(e)=>{setConfirmPassword(e.target.value)}}

          />
          <br/>
          <br/>

<Button  variant="contained" color="success" type='submit'>Submit</Button>
          </form>
        </div>
        
       
    </Grid>
    </Grid>
    </>
  )
}

export default Otp