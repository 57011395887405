import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import passwordImage from '../../../Assets/ChangePassword.avif';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { secureStorage } from "../../../Utils";


const ChangesPassword = ({onClick}) => {

    const [open, setOpen] = React.useState(false);
    let userData = secureStorage.getItem("currentUser")
    console.log(userData.data.data.uuid, "userData");
    const UserId = userData?.data?.data?.uuid

    const dispatch = useDispatch()

    const history = useHistory();

    const [validated, setValidated] = useState(false);
    const [suc, setSuc] = useState(false);

    const setpassword = (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        const details = event.target.elements;

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();

            if (details.newpassword.value === details.confirmpassword.value) {
                const payload = {
                    "oldPassword": details.oldpassword.value,
                    "newPassword": details.newpassword.value
                }
                axios.put(`${process.env.REACT_APP_WEB_APP}/user/change/password/${UserId}`, payload)
                    .then((res) => {
                        console.log(res, "hdfsjafd")
                        // toast.success(res.data.message);
                        Swal.fire({
                            icon: 'success',
                            title: (res.data.message),
                        })
                    })
                    .catch((err) => {
                        console.log(err.response, "hdkfjds")
                        toast.error(err.response.data.message);
                        Swal.fire({
                            icon: 'error',
                            title: (err?.response?.data?.message ? err.response.data.message : "Somthing Went Wrong"),

                        })
                    })
            }
        }

        setValidated(true);
    }
    const Back = () => {
        history.goBack()
    }



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Grid item container style={{ padding: "20px" }}>
                <Grid xs={12} md={12} >
                    <form onSubmit={setpassword}>
                        <TextField
                            id="outlined-basic"
                            label="Current Password"
                            variant="outlined"
                            type='text'
                            fullWidth
                            //  value={oldPassword}
                            //  onChange={(e)=>{setOldPassword(e.target.value)}}
                            name="oldpassword"
                        />
                        <br /><br />
                        <TextField
                            id="outlined-basic"
                            label="New Password"
                            variant="outlined"
                            type='text'
                            fullWidth
                            // value={newPassword}
                            name="newpassword"
                        // onChange={(e)=>{setNewPassword(e.target.value)}}

                        />
                        <br /><br />
                        <TextField
                            id="outlined-basic"
                            label="Confirm New Password"
                            variant="outlined"
                            type='text'
                            fullWidth
                            // value={newPassword}
                            name="confirmpassword"
                        // onChange={(e)=>{setNewPassword(e.target.value)}}

                        />


                        <Grid style={{ float: "right", marginTop: "4%" }}>
                            <DialogActions>
                                <Button variant="contained" color="error" onClick={onClick}>Close</Button>
                                <Button autoFocus variant="contained" color="success" type='submit'>
                                    Update
                                </Button>
                            </DialogActions>
                        </Grid>
                    </form>
                </Grid>

            </Grid>

        </>
    )
};
export default ChangesPassword;