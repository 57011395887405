import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  QuestionData: [],
  UserVsUserQuestionsData: [],
  ScoreForThisExam: {},
  notification: {},
  scoreCardCreate: false
};

const QuestionSlice = createSlice({
  name: 'Questions List',
  initialState,
  reducers: {
    setQuestionData(state, action) {
      state.QuestionData = action.payload;
    },
    setUserVsUserQuestionsData(state, action) {
      state.UserVsUserQuestionsData = action.payload;
    },
    setScoreForThisExam(state, action) {
      state.ScoreForThisExam = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    },
    setScoreCardCreate(state, action) {
      state.scoreCardCreate = action.payload
    }
  },
});

export const { setQuestionData, setUserVsUserQuestionsData, setScoreForThisExam, setToast, setScoreCardCreate } = QuestionSlice.actions;


export const GKGetQuestionURL = (examTypeUuid, topicUuid, subTopicUuid, TypeUuid, MethodUuid, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/gk/questions?exam_type_uuid=${examTypeUuid}&topic_uuid=${topicUuid}&sub_topic_uuid=${subTopicUuid}&type_uuid=${TypeUuid}&method_uuid=${MethodUuid}`,
    {
      headers: {
        "x-auth-token": token

      }
    }).then((res) => {
      dispatch(setQuestionData(res.data));

    })
    .catch((err) => {
      dispatch(setQuestionData([]));

    })
}

export const EntranceGetQuestionURL = (examTypeUuid, level_uuid, entrance_exam_type_uuid, entrance_exam_uuid, entrance_sub_paper_uuid, board_type, state, type_uuid, method_uuid, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/entrance/questions?exam_type_uuid=${examTypeUuid}&level_uuid=${level_uuid}&entrance_exam_type_uuid=${entrance_exam_type_uuid}&entrance_exam_uuid=${entrance_exam_uuid}&entrance_sub_paper_uuid=${entrance_sub_paper_uuid}&board_type=${board_type}&state=${state}&type_uuid=${type_uuid}&method_uuid=${method_uuid}`,
    {
      headers: {
        "x-auth-token": token

      }
    }).then((res) => {
      dispatch(setQuestionData(res.data));

    })
    .catch((err) => {
      dispatch(setQuestionData([]));

    })
}
export const CompatitiveGetQuestionURL = (exam_type_uuid, level_uuid, competitive_exam_type_uuid, competitive_exam_uuid, competitive_sub_paper_uuid, board_type, state, type_uuid, method_uuid, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/competitive/questions?exam_type_uuid=${exam_type_uuid}&level_uuid=${level_uuid}&competitive_exam_type_uuid=${competitive_exam_type_uuid}&competitive_exam_uuid=${competitive_exam_uuid}&competitive_sub_paper_uuid=${competitive_sub_paper_uuid}&board_type=${board_type}&state=${state}&type_uuid=${type_uuid}&method_uuid=${method_uuid}`,
    {
      headers: {
        "x-auth-token": token

      }
    }).then((res) => {
      dispatch(setQuestionData(res.data));

    })
    .catch((err) => {
      dispatch(setQuestionData([]));

    })
}

export const AcadmicGetQuestionURL = (exam_type_uuid, level_uuid, academic_board_uuid, academic_stream_uuid, academic_group_uuid, academic_subject_uuid, academic_sub_subject_uuid, academic_year, academic_semister, board_type, state, type_uuid, method_uuid, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/academic/questions?exam_type_uuid=${exam_type_uuid}&level_uuid=${level_uuid}&academic_board_uuid=${academic_board_uuid}&academic_stream_uuid=${academic_stream_uuid}&academic_group_uuid=${academic_group_uuid}&academic_subject_uuid=${academic_subject_uuid}&academic_sub_subject_uuid=${academic_sub_subject_uuid}&academic_year=${academic_year}&academic_semister=${academic_semister}&board_type=${board_type}&state=${state}&type_uuid=${type_uuid}&method_uuid=${method_uuid}`,
    {
      headers: {
        "x-auth-token": token

      }
    }).then((res) => {
      dispatch(setQuestionData(res.data));

    })
    .catch((err) => {
      dispatch(setQuestionData([]));

    })
}


export const UservsAllUsersQuestions = (gameId, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/game/questions/${gameId}`,
    {
      headers: {
        "x-auth-token": token

      }
    }).then((res) => {
      console.log(res, "dghfvsgs")
      dispatch(setUserVsUserQuestionsData(res.data));

    })
    .catch((err) => {
      dispatch(setUserVsUserQuestionsData([]));

    })
}


// export const GKGetQuestionURL = (examTypeUuid,topicUuid,subTopicUuid,TypeUuid,MethodUuid, token) => async (dispatch) => {
//   const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/gk/questions?exam_type_uuid=ET-A76284AA&topic_uuid=TP-EF76531C%2CTP-A1DB6606&sub_topic_uuid=STP-9A4CD949%2CSTP-61F1BC33&type_uuid=CT-C93B62D9&method_uuid=CM-00BE9AEA`,
//   {headers:{
//     "x-auth-token" : token

// }}).then((res) => {
//   dispatch(setQuestionData(res.data));

// })
// .catch((err) => {
//   dispatch(setQuestionData([]));

// })
// }


// export const ParticipentScorePostURL = (payload, token) => async (dispatch) => {
//   dispatch(setScoreCardCreate(false))
//   const response = await axios.post(`${process.env.REACT_APP_WEB_APP}/participant/scorecard`, payload, {
//     headers: {
//       "x-auth-token": token
//     }
//   }).then((res) => {
//     console.log(res, "sdfsdfsdff")
//     dispatch(setScoreCardCreate(true))
//     dispatch(setToast({ status: true, message: res.data.message }))
//   })
//     .catch((err) => {
//       dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))
//       dispatch(setScoreCardCreate(false))
//       // setScoreForThisExam(err)
//     })
// };

export const ParticipentScorePostURL = () => async (dispatch) => {
  dispatch(setScoreCardCreate(true))
};



export const AnswerSubmitURL = (payload, token) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/participant/scorecard/update`, payload, {
    headers: {
      "x-auth-token": token
    }
  }).then((res) => {
    console.log(res, "sdfsdfsdff")
    dispatch(setToast({ status: true, message: res.data.message }))
    dispatch(setScoreForThisExam(res.data));
  })
    .catch((err) => {
      dispatch(setToast({ status: false, message: err && err.response ? err && err.response.data : "Something went wrong" }))
      dispatch(setScoreForThisExam({}));
    })
};




const QuestionReducer = QuestionSlice.reducer;

export default QuestionReducer;
