import { Button, Grid } from "@mui/material";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from '../../Components/Header';
import stats from '../../Assets/stats.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimerIcon from '@mui/icons-material/Timer';
import TextField from "@mui/material/TextField";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Pagination from '@mui/material/Pagination';
import './mypost.css'
import Stack from '@mui/material/Stack';

const TopicsDetail = () =>{
    return(
        <>
   <Grid style={{background: "#eeeeee",width:"100%",height:"100%",padding:" 120px 300px 31px"}} className="mypostcontainer">
    <Header/>
    <Grid item container  >
           {/* <div style={{display:"flex",justifyContent:"space-between"}}> */}
           <Grid xs={12} md={3} lg={3} style={{display:"flex",justifyContent:"start",color:"#000",fontSize:"20px",padding: "0px 20px",}}>
              
                    <TextField
              id="outlined-basic"
              label="Search Topics"
              variant="outlined"
              type="text"
              fullWidth 
              // maxWidth="xxl"
            
            />
                </Grid>
                <Grid xs={12} md={9} lg={9} style={{display:"flex",justifyContent:"end",alignItems:"center"}} className="buttonmypost">
                <Link to="/NewTopics"> <Button style={{backgroundColor:"orangered",color:"white"}} >New Topic</Button> </Link>&nbsp;&nbsp;
                    <Button style={{backgroundColor:"orangered",color:"white"}} >My Post</Button>
                </Grid>
           {/* </div> */}
           </Grid>
           <Grid xs={12} md={12} lg={12} style={{paddingTop:"5px"}}>
           <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}}>
                {/* <div  > */}
                    <div>
                  
                    <img src={stats} alt="" width="40px" height="auto" />&nbsp;&nbsp;
                   
                        <span style={{fontSize:"25px"}}>
                            <div className="postDetails">test post by lakshmi on 05/07/2021....</div> </span>
              <p>lakshmi</p>
                    </div>
                    <div>
                        <ThumbUpOffAltIcon/>4&nbsp;&nbsp;
                        <ThumbDownOffAltIcon/>2&nbsp;&nbsp;
                        <TimerIcon/>&nbsp;&nbsp;Posted on : 2 years ago <br/>
                        <img src={stats} alt="" width="20px" height="auto" />&nbsp;&nbsp;
                   <span style={{fontSize:"15px"}}>Your testing is not upto the mark</span>
                    </div><br/>
                    <br/>
                    <div>
                        <ThumbUpOffAltIcon/>4&nbsp;&nbsp;
                        <ThumbDownOffAltIcon/>2&nbsp;&nbsp;
                        <TimerIcon/>&nbsp;&nbsp;Posted on : 2 years ago <br/>
                        <img src={stats} alt="" width="20px" height="auto" />&nbsp;&nbsp;
                   <span style={{fontSize:"15px"}}>Please respond to the post</span>
                    </div>
                    <br/>
                    <br/>
                    <div>
                        <ThumbUpOffAltIcon/>4&nbsp;&nbsp;
                        <ThumbDownOffAltIcon/>2&nbsp;&nbsp;
                        <TimerIcon/>&nbsp;&nbsp;Posted on : 2 years ago <br/>
                        <img src={stats} alt="" width="20px" height="auto" />&nbsp;&nbsp;
                   <span style={{fontSize:"15px"}}>lakshmi@123</span><br/><br/>
                   
                    </div>
                   
                 {/* </div> */}
                 <Grid item container style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Grid xs={12} md={4} lg={4}>
                    <span style={{fontSize:"15px"}}>Post a Reply</span><br/>
                   <textarea rows={4} style={{width:"100%",padding:"7px",fontSize:"16px"}} className="textarea"/>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} style={{display:"flex",justifyContent:"end",alignItems:"center"}}>
                    <Button style={{backgroundColor:"#007bff",color:"white",border:"1px solid #007bff"}} >Post Reply</Button>
                    </Grid>
                 </Grid>
            </div>
           </Grid>

           
          
        </Grid>
        </>
    )
};
export default TopicsDetail;