import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  QuizMethodData: [],
  notification:{}
};

const QuizMethodSlice = createSlice({
  name: 'Quiz Method List',
  initialState,
  reducers: {
    setQuizMethodData(state, action) {
      state.QuizMethodData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setQuizMethodData, setToast } = QuizMethodSlice.actions;


export const QuizMethodListGetURL = (GametypeUuid, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/quiz/methods?type_uuid=${GametypeUuid}`,
  {headers:{
    "x-auth-token" : token

}}).then((res) => {
  dispatch(setQuizMethodData(res.data));

})
.catch((err) => {
  dispatch(setQuizMethodData([]));

})
}


  

const QuizMethodReducer = QuizMethodSlice.reducer;

export default QuizMethodReducer;
