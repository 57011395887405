import React ,{useState} from 'react';
import { secureStorage } from "../Utils";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import clogin from "../Assets/clogin.svg" 
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Swal from 'sweetalert2'
// import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';


export default function AlertDialog({ isOpen, onClose }) {

  let userData = secureStorage.getItem("currentUser")
  console.log(userData,"userData")
  const [open, setOpen] = React.useState(false);
  const [ErrMsg, setErrMsg]=useState("")
  const [email, setEmail]=React.useState("")
  const [password, setPassWord]=React.useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      "email" : email,
      "password" : password
    }
    axios.post(`${process.env.REACT_APP_WEB_APP}/user/login`, payload, {
      // headers: {

      //   "x-access-token": `${token}`,
      // }
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message, "res")
          secureStorage.setItem('currentUser', res)
      onClose()
      setErrMsg("")
          Swal.fire({
            icon: 'success',
            title: (res.data.message),
            // text: 'Something went wrong!',
            // footer: '<a href="">Why do I have this issue?</a>'
          })
        }


      })
      .catch((err) => {
        // showNotification("error", "Failed !", "" + err.response.data.message)
        // onClose()
        setOpen(true);
       
        console.log(err.response.data.message
          ,"ssdfsdfdsfd")
        setErrMsg(err.response.data.message)
      })
  }

  return (
    <div>
      {/* <div onClick={handleClickOpen} > Login</div> */}
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Login"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
        <Grid item container style={{padding:"20px"}}>
        <Grid md={12} tyle={{padding:"20px"}} align="center">
        <img src={clogin} style={{ width: "50%" }} />
        </Grid>
        <Grid md={12} style={{padding:"20px"}}>
        <Collapse in={open}>
        <Alert
         severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         {ErrMsg}
        </Alert>
      </Collapse>


        <TextField 
        id="outlined-basic"
         label="Email" 
         variant="outlined" 
          type='email' 
          fullWidth
          onChange={(e)=>{setEmail(e.target.value)}}

          />
        
       


        </Grid>
        <Grid md={12} style={{padding:"20px"}}>
        <TextField 
        id="outlined-basic"
         label="Password" 
         variant="outlined" 
          type='password'
           fullWidth
           onChange={(e)=>{setPassWord(e.target.value)}}

           />
        </Grid>
        <Grid md={12} align="center">
        {/* <p>Forgot Password ?</p> */}
        <Link to="/forgot-password">
        <Button onClick={onClose}>Forgot Password ?</Button>
        </Link>
        <hr/>
        </Grid>

    

        </Grid>
        
        <DialogActions>
          <Button  variant="contained" color="success" type='submit'>Login</Button>
          <Button onClick={onClose} autoFocus  variant="contained" color="error">
         close
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}