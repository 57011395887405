import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    SubjectData: [],
  notification:{}
};

const SubjectSlice = createSlice({
  name: 'Subject',
  initialState,
  reducers: {
    setSubjectData(state, action) {
      state.SubjectData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setSubjectData, setToast } = SubjectSlice.actions;


export const SubjectListURL = (educationLevelUuid,academicStream,academicBoard,academicGroup,year,sem,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/academic/subject?level_uuid=${educationLevelUuid}&stream_uuid=${academicStream}&board_uuid=${academicBoard}&group_uuid=${academicGroup}&year=${year}&semister=${sem}`,{headers:{
    "x-auth-token" : token
  }})
  .then((res) => {
  console.log(res.data, "dfsubghj")
  dispatch(setSubjectData(res.data));
})
  // .catch(() => {
  // dispatch(setSubjectData([]));
  // })
};

const SubjectReducer = SubjectSlice.reducer;

export default SubjectReducer;
