import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    PlayHistoryHeaderData: [],
    notification:{}
};

const PlayHistoryHeaderSlice = createSlice({
  name: 'PlayHistoryHeader',
  initialState,
  reducers: {
    setPlayHistoryHeaderData(state, action) {
      state.PlayHistoryHeaderData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setPlayHistoryHeaderData, setToast } = PlayHistoryHeaderSlice.actions;

export const PlayHistoryHeaderURL = (pageNum, search, token, limit) => async (dispatch) => {
    const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/competitive/type/user/list?page=&limit=&search=`,{headers:{
      // "x-auth-token" : token
     
  }})
  .then((res) => {
      console.log(res, "ressASasSas")
    dispatch(setPlayHistoryHeaderData(res.data.data));
  })
  .catch((err) => {
    console.log(err, "err")
    // dispatch(setStatsData([]));
  })
  }


const PlayHistoryHeaderReducer = PlayHistoryHeaderSlice.reducer;

export default PlayHistoryHeaderReducer;
