import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    StatsListData: {},
    notification:{}
};

const StatsSlice = createSlice({
  name: 'Stats',
  initialState,
  reducers: {
    setStatsData(state, action) {
      state.StatsListData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setStatsData, setToast } = StatsSlice.actions;

export const StatsListURL = (token , uuid) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/profile/${uuid}`,{headers:{
    "x-auth-token" : token
   
}})
.then((res) => {
    console.log(res, "res")
  dispatch(setStatsData(res.data));
})
.catch((err) => {
  console.log(err, "err")
  // dispatch(setStatsData([]));
})
}

export const StatsUpdateURL = (token,uuid,payload) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_URL}/participant/update/${uuid}`,payload,{headers:{
    "x-auth-token" : token
  }})
  .then((res) => {
    console.log(res, "res")
  dispatch(setStatsData(res.data));
})
.catch((err) => {
  console.log(err, "err")
  // dispatch(setStatsData([]));
})
 
};

// export const StatsPlayhistoryURL = (page,limit,search,token,uuid,) => async (dispatch) => {
//   const response = await axios.get(`${process.env.REACT_APP_URL}/participant/play/history?page=${page}&limit=${limit}&search=${search}&player_uuid=PR-3E94FA7B&type_uuid=CT-8F0C79B6`,{headers:{
//     "x-auth-token" : token
//   }})
//   .then((res) => {
//     console.log(res, "res")
//   dispatch(setStatsData(res.data));
// })
// .catch((err) => {
//   console.log(err, "err")
//   // dispatch(setStatsData([]));
// })
 
// };

const StatsListReducer = StatsSlice.reducer;

export default StatsListReducer;
