import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  GameInstructionDataUserVsUser: [],
  notification: {}
};

const GameInstructionSliceUserVsUser = createSlice({
  name: 'GameInstructionUserVsUser',
  initialState,
  reducers: {
    setGameInstructionDataUserVsUser(state, action) {
      state.GameInstructionDataUserVsUser = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setGameInstructionDataUserVsUser, setToast } = GameInstructionSliceUserVsUser.actions;


export const UserVsUserGameInstructionListURL = (methodId, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/game/instructions?method_uuid=${methodId}`, {
    headers: {
      "x-auth-token": token
    }
  }).then((res) => {
    console.log(res.data)
    dispatch(setGameInstructionDataUserVsUser(res.data));
    dispatch(setToast(res.Message))

  })
    .catch((err) => {
      dispatch(setGameInstructionDataUserVsUser({}));
      dispatch(setToast(err))
    })
}





const GameInstructionReducerUserVsUser = GameInstructionSliceUserVsUser.reducer;

export default GameInstructionReducerUserVsUser;
