import { Button, Grid } from "@mui/material";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from '../../Components/Header';
import stats from '../../Assets/stats.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimerIcon from '@mui/icons-material/Timer';
import TextField from "@mui/material/TextField";
import Pagination from '@mui/material/Pagination';
import './mypost.css'
import Stack from '@mui/material/Stack';

const NewTopics = () =>{
    return(
        <>
   <Grid style={{background: "#eeeeee",width:"100%",height:"100vh",padding:" 120px 300px 31px"}} className="mypostcontainer">
    <Header/>
           <Grid item container  >
     
           <Grid xs={12} md={3} lg={3} style={{display:"flex",justifyContent:"start",color:"#000",fontSize:"20px",padding: "0px 20px",}}>
              
                    <TextField
              id="outlined-basic"
              label="Search Topics"
              variant="outlined"
              type="text"
              fullWidth 
              // maxWidth="xxl"
            
            />
                </Grid>
                <Grid xs={12} md={9} lg={9} style={{display:"flex",justifyContent:"end",alignItems:"center"}} className="buttonmypost">
                <Button style={{backgroundColor:"orangered",color:"white"}} >New Topic</Button>&nbsp;&nbsp;
                    <Button style={{backgroundColor:"orangered",color:"white"}} >My Post</Button>
                </Grid>
         
           </Grid>


           <Grid xs={12} md={12} lg={12} style={{paddingTop:"5px"}}>
           <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}}>
                {/* <div  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}> */}
                    <div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                  
                    <img src={stats} alt="" width="40px" height="auto" />&nbsp;&nbsp;
                    <div style={{fontSize:"23px",textDecoration:"none",color:"#004289",cursor:"pointer"}} >
                    <div className="postDetails">lakshmi@gmail.com</div>
                    </div>
                        
                    </div>
               
                       <Grid item container>
                       <Grid xs={12} md={3} lg={3} style={{display:"flex",justifyContent:"start",color:"#000",fontSize:"20px",marginTop:"20px"}}>
              
              <TextField
        id="outlined-basic"
        label="Enter Topic Title"
        variant="outlined"
        type="text"
        fullWidth 
        // maxWidth="xxl"
      
      />
          </Grid>
                       </Grid>
                       <Grid item container style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"20px"}}>
                    <Grid xs={12} md={4} lg={4}>
                    <span style={{fontSize:"15px"}}>Write Description</span><br/>
                   <textarea rows={4} style={{width:"100%",padding:"7px",fontSize:"16px"}} className="textarea" placeholder="Enter here...."/>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} style={{display:"flex",justifyContent:"end",alignItems:"center"}}>
                    <Button style={{backgroundColor:"#007bff",color:"white",border:"1px solid #007bff"}} >Post</Button>
                    </Grid>
                 </Grid>
                    
                   
                 {/* </div> */}
            </div>
           </Grid>

          
        </Grid>
        </>
    )
};
export default NewTopics;