import React from "react";
import Grid from "@mui/material/Grid";
import "./footer.css";

function Footer() {
  return (
    <>
      <Grid
        item
        container
        style={{ background: "#004a99", padding: "100px" }}
        className="footer"
      >
        <Grid xs={12} md={3}>
          <h1 className="footer-heading">NO1 EXAM</h1>
          <p className="footer-heading">
            Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada
            terra videa magna derita valies darta donna mare fermentum iaculis
            eu non diam phasellus. Scelerisque felis imperdiet proin fermentum
            leo. Amet volutpat consequat mauris nunc congue.
          </p>
        </Grid>
        <Grid xs={12} md={3}>
          <h1 className="footer-para"> USEFUL LINKS</h1>
          <p className="footer-para">Home</p>
          <p className="footer-para">Events</p>
          <p className="footer-para">Categories</p>
          <p className="footer-para">Privacy policy</p>
        </Grid>
        <Grid xs={12} md={3}>
          <h1 className="footer-heading">CONTACT US</h1>
          <p className="footer-para">
            A108 Adam Street New York, NY 535022 United States Phone: +1 5589
            55488 55 Email: info@example.com
          </p>
        </Grid>
        <Grid xs={12} md={3}>
          <h1 className="footer-heading">OUR NEWSLETTER</h1>
          <p className="footer-para">
            Tamen quem nulla quae legam multos aute sint culpa legam noster
            magna veniam enim veniam illum dolore legam minim quorum culpa amet
            magna export quem marada parida nodela caramase seza.
          </p>
        </Grid>
      </Grid>
      <Grid item container style={{ background: "#00428a" }}>
        <Grid xs={12} md={12} align="center">
          <p className="footer-para">
            © Copyright No1 Exam. Developed By Sciens Software Technologies LLP
          </p>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
