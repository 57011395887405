import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { secureStorage } from "../../../Utils";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import "./Question.css";
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import axios from 'axios';
import rupees from "../../../Assets/rupee.png";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import medal from "../../../Assets/medal.png";
import io from 'socket.io-client';
import { UservsAllUsersQuestions, GKGetQuestionURL, EntranceGetQuestionURL, CompatitiveGetQuestionURL, AcadmicGetQuestionURL, AnswerSubmitURL, ParticipentScorePostURL } from "../../../Redux/Game/QuestionsRedux/QuestionRedux";
import { IsGameStartedUrl } from "../../../Redux/Game/GameListRedux";
import { GameInstructionListURL } from "../../../Redux/GameInstruction/GameInstructionRedux";
import { ScoreCardListURL } from "../../../Redux/ScoreCard/ScoreCardRedux";
// import ClockLoader from "react-spinners/ClockLoader";
import { CirclesWithBar } from 'react-loader-spinner'

const host = "https://api.quizapp.scienstechnologies.com";
function UserQuestions() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { GameInstructionData } = useSelector((state) => state.GameInstructionList)
    const { ScoreCardData } = useSelector((state) => state.ScoreCardList)
    const { scoreCardCreate } = useSelector((state) => state.QuestionList)
    const { UserVsUserQuestionsData, QuestionData, ScoreForThisExam, notification } = useSelector((state) => state.QuestionList)
    console.log(UserVsUserQuestionsData, "UserVsUserQuestionsData")

    let userData = secureStorage.getItem("currentUser")
    const location = useLocation();

    const playeruuid = userData?.data?.data?.uuid

    const { GameListData } = useSelector((state) => state.GameList)

    const [TotalScoreBoard, setTotalScoreBoard] = useState('');
    const [skipdQuesIndex, setSkipdQuesIndex] = useState([]);
    console.log(skipdQuesIndex, "skipdQuesIndexskipdQuesIndex")

    // time for per Question?
    const [timePerQuestion, setTimePerQuestion] = useState();
    console.log(timePerQuestion, "gdgfdhsfhgds")
    // time for total Question?
    const [time, setTime] = useState();
    const [skipBtndisabled, setSkipBtndisabled] = useState(false)

    const [totalscoreCount, setTotalScoreCount] = useState([]);
    console.log(totalscoreCount, "totalscoreCount")

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [Examopen, setExamOpen] = useState(false);

    const [typeofdisabled, setTypeOfDisabled] = useState(false);
    const [submitbtn, setSubmitBtn] = useState(true);
    const [submitoptionsDisabled, setSubmitOptionsDisabled] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [gameType, setGameType] = useState("")
    console.log(gameType, "gameType");
    const [answer, setAnswer] = useState("")
    const [selectedTab, setSelectedTab] = useState(0);

    const [questions, setQuestions] = useState()
    const [updateScore, setUpdateScore] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const currentQuestion = questions?.data[currentQuestionIndex];
    const currentQuestionUuidRef = useRef();
    console.log(currentQuestion?.uuid, "currentQuestionIndex");

    const [handleanswerdetails, sethandleAnswerDetails] = useState('');
    console.log(handleanswerdetails, "handleanswerdetails")

    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const [userResponses, setUserResponses] = useState([]);

    console.log(totalscoreCount, "dshjcfkdshkfjhds")

    useEffect(() => {
        if (currentQuestion) {
            currentQuestionUuidRef.current = currentQuestion?.uuid;
            console.log(currentQuestionUuidRef?.current, "currentQuestionUuidRef");
        }
    }, [currentQuestion]);

    useEffect(() => {
        const matchedObject = ScoreCardData?.data?.length > 0 && ScoreCardData?.data?.find(obj => obj.participant_uuid === userData?.data?.data?.uuid)
        if (matchedObject) {
            ScoreCardData?.data?.length > 0 && ScoreCardData?.data.map((items) => {
                if (items.participant_uuid === userData?.data?.data?.uuid) {
                    return setTotalScoreBoard(items)
                }
            })
        }
    }, [ScoreCardData])

    useEffect(() => {
        SocketUpdateApi()
        dispatch(GameInstructionListURL(location?.state?.data?.uuid))
    }, [])

    useEffect(() => {
        let timerInterval = setInterval(() => {
            SocketUpdateApi()
        }, 10000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [location])

    useEffect(() => {
        ParticipentScore()
        dispatch(ScoreCardListURL(location?.state?.data?.uuid, location?.state?.data?.type_uuid, location?.state?.data?.method_uuid, userData?.data?.token))
    }, [])


    useEffect(() => {
        if (location && location?.state?.name) {
            setGameType(location?.state?.name)
        }
    }, [])

    const handleClose = () => {
        setOpen(false);
    }

    const ExamhandleClose = () => {
        setExamOpen(false);
    }

    const [backgroundColor, setBackgroundColor] = useState('#8898aa');

    const handleClick = () => {
        setBackgroundColor('green');
    };

    const ParticipentScorePostFunction = async (payload1, token1) => {
        const funct = async (payload, token) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_WEB_APP}/participant/scorecard`, payload, {
                    headers: {
                        "x-auth-token": token
                    }
                })
                dispatch(ParticipentScorePostURL(res));
                return res?.data;
            }
            catch (res) {
                dispatch(ParticipentScorePostURL({}));
                return;
            }
        }
        const result = await funct(payload1, token1);
        return result;
    }

    const ParticipentScore = () => {
        const payload = {
            "type_uuid": location?.state?.data?.type_uuid,
            "method_uuid": location?.state?.data?.method_uuid,
            "game_uuid": location?.state?.data?.uuid,
            "participant_uuid": userData.data.data.uuid
        }
        ParticipentScorePostFunction(payload, userData.data.token)
            .then((res) => {
                console.log(res, "gfjdshjgdshds")
                if (res?.status === true) {
                    dispatch(UservsAllUsersQuestions(location?.state?.data?.uuid, userData?.data?.token))
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const TotalScoreDataApi = () => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/participant/atempt/percentage?game_uuid=${location?.state?.data?.uuid}&question_uuid=${currentQuestionUuidRef?.current}`, {
            headers: {
                "x-auth-token": userData?.data?.token
            }
        })
            .then((res) => {
                console.log(res, "resData12345")
            })
            .catch((err) => {
                console.log(err, "errData")
            })
    }

    const SocketUpdateApi = () => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/game_socket_update?game_uuid=${location?.state?.data?.uuid}`)
            .then((res) => {
                console.log(res, "hgvhdbcjdbkjds")
                setTotalScoreCount(res?.data?.score_details)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const IsGameStarted = () => {
        const payload = {
            "is_game_started": "true"
        }
        setTimeout(() => {
            dispatch(IsGameStartedUrl(location?.state?.data?.uuid, payload, userData.data.token))
        }, 1000)
    }

    const SkippedQuestionFinishApi = () => {
        const payload = {
            "is_finished": true
        }
        axios.put(`${process.env.REACT_APP_WEB_APP}/participant/scorecard/finished/status/${handleanswerdetails?.data?.uuid}`, payload, {
            headers: {
                "x-auth-token": userData?.data?.token
            }
        })
            .then((res) => {
                console.log(res)
                setExamOpen(false);
                if (totalscoreCount?.length > 0) {
                    console.log(totalscoreCount, "123444444444444444");
                    if (totalscoreCount?.filter(items => items?.participant === playeruuid && items?.percentage === 100 && items?.is_finished === false)) {
                        setOpen(true);
                        setLoading(true);
                    }
                    else if (totalscoreCount?.every(items => items?.percentage === 100 && items?.is_finished === true)) {
                        history.push({
                            pathname: "/ScoreCard",
                            state: location?.state
                        })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const EncryptedData = UserVsUserQuestionsData ? UserVsUserQuestionsData : [];

    useEffect(() => {
        if (UserVsUserQuestionsData?.data) {
            decrypt(UserVsUserQuestionsData?.data)
        }
    }, [UserVsUserQuestionsData])

    const CryptoJS = require("crypto-js");

    const SECRET_KEY = ')H@McQfTjWnZr4u7x!A%C*F-JaNdRgUkUkUXNh*F-JaNPLAYER';


    const decrypt = (data) => {

        try {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);

            data = data.toString(CryptoJS.enc.Utf8);

            const data1 = JSON.parse(data)
            setQuestions(data1)
        } catch (error) {
            console.error("JSON parsing error:", error);
        }
        return data;

    }

    const HandleSubmit = (response) => {
        setTypeOfDisabled(true);
        setIsSubmitted(true);
        const payload = {

            "type_uuid": location?.state?.data.type_uuid,
            "method_uuid": location?.state?.data.method_uuid,
            "game_uuid": location?.state?.data?.uuid,
            "question_uuid": response.questionUuid,
            "participant_uuid": userData.data.data.uuid,
            "user_answer": response.answer,
            "answer_time": String(timePerQuestion)
        }
        axios.put(`${process.env.REACT_APP_WEB_APP}/participant/scorecard/update`, payload, {
            headers: {
                "x-auth-token": userData?.data?.token
            }
        })
            .then((res) => {
                console.log(res, "sdfsdfsdff")
                sethandleAnswerDetails(res?.data)


                console.log(location?.state?.player_uuid, "bdsvghsadhj")

                setSubmitBtn(true)
                setTimeout(() => {
                    dispatch(ScoreCardListURL(location?.state?.data?.uuid, location?.state?.data?.type_uuid, location?.state?.data?.method_uuid, userData?.data?.token))
                    handleNextClick()
                }, 500);

                SocketUpdateApi()
            })
            .catch((err) => {
                console.log(err)
            })
        // scorecardUpdate()

    }

    const HandleSubmitBtn = () => {
        const updatedArray = skipdQuesIndex.filter(item => item !== currentQuestionIndex);
        setSkipdQuesIndex(updatedArray);
        setIsSubmitted(true);
        setSubmitBtn(true);
        setSubmitOptionsDisabled(true);
        const payload = {
            "type_uuid": location?.state?.data.type_uuid,
            "method_uuid": location?.state?.data.method_uuid,
            "game_uuid": location?.state?.data?.uuid,
            "question_uuid": answer?.questionUuid,
            "participant_uuid": userData?.data?.data?.uuid,
            "user_answer": answer?.answer,
            // "skipped_questions": skipdQuesIndex?.length
            "skipped_questions": updatedArray?.length
        }
        axios.put(`${process.env.REACT_APP_WEB_APP}/participant/scorecard/update`, payload, {
            headers: {
                "x-auth-token": userData.data.token
            }
        })
            .then((res) => {
                console.log(res, "sdfsdfsdff123")
                sethandleAnswerDetails(res.data)
                setSubmitOptionsDisabled(false);
                setTimeout(() => {
                    dispatch(ScoreCardListURL(location?.state?.data?.uuid, location?.state?.data?.type_uuid, location?.state?.data?.method_uuid, userData?.data?.token))
                }, 500)

                setSubmitBtn(true)
                setTimeout(() => {
                    handleNextClick()
                }, 1000);

                SocketUpdateApi()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const SkipQuestion = () => {
        setIsSubmitted(true);
        if (currentQuestion?.uuid) {
            let payload;
            if (timePerQuestion === 0) {
                payload = {
                    "type_uuid": location?.state?.data?.type_uuid,
                    "method_uuid": location?.state?.data?.method_uuid,
                    "game_uuid": location?.state?.data?.uuid,
                    "question_uuid": currentQuestion?.uuid,
                    "participant_uuid": userData?.data?.data?.uuid,
                    "user_answer": "skip",
                    // "skipped_questions": skipdQuesIndex?.length + 1
                }
            }
            else {
                payload = {
                    "type_uuid": location?.state?.data?.type_uuid,
                    "method_uuid": location?.state?.data?.method_uuid,
                    "game_uuid": location?.state?.data?.uuid,
                    "question_uuid": currentQuestion?.uuid,
                    "participant_uuid": userData?.data?.data?.uuid,
                    "user_answer": "skip",
                    "skipped_questions": skipdQuesIndex?.length + 1
                }
            }
            axios.put(`${process.env.REACT_APP_WEB_APP}/participant/scorecard/update`, payload, {
                headers: {
                    "x-auth-token": userData?.data?.token
                }
            })
                .then((res) => {
                    console.log(res, "sdfsdfsdff")
                    sethandleAnswerDetails(res.data)
                    setTimeout(() => {
                        dispatch(ScoreCardListURL(location?.state?.data?.uuid, location?.state?.data?.type_uuid, location?.state?.data?.method_uuid, userData?.data?.token))
                    }, 500)
                    setSubmitBtn(true)
                    handleNextClick()
                    SocketUpdateApi()
                })
                .catch((err) => {
                    console.log(err)
                })

            if (gameType === "Exam") {
                if (totalscoreCount?.length > 0) {
                    if (totalscoreCount?.every(items => items?.percentage === 100 && items?.is_finished === true)) {
                        history.push({
                            pathname: "/ScoreCard",
                            state: location?.state
                        })
                    }
                }
            }
            else if (gameType !== "Exam") {
                if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length) {
                    if (totalscoreCount?.length > 0) {
                        if (totalscoreCount.every(items => items?.percentage === 100)) {
                            history.push({
                                pathname: "/ScoreCard",
                                state: location?.state
                            })
                        }
                    }
                }
            }
        }

    }

    useEffect(() => {
        if (gameType === "Quiz") {
            if (totalscoreCount?.length > 0) {
                totalscoreCount?.map((items) => {
                    if (items?.participant === playeruuid) {
                        if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length || items?.percentage === 100) {

                            setOpen(true)
                            setLoading(true)
                        }
                    }
                })
            }
        }
        else if (gameType === "Exam") {
            if (totalscoreCount?.length > 0) {
                totalscoreCount?.map((items) => {
                    console.log(items, "vdgfdshskjhkfsjh")
                    if (items?.participant === playeruuid) {
                        if ((handleanswerdetails?.data?.questions_attempted === questions?.data?.length || items?.percentage === 100) && items?.skipped_questions === 0) {
                            setOpen(true)
                            setLoading(true)
                        }
                    }
                })
            }
        }
        else if (gameType === "Buzzer" || gameType === "Rapid fire") {
            if (totalscoreCount?.length > 0) {
                totalscoreCount?.map((items) => {
                    if (items?.participant === playeruuid) {
                        if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length || items?.percentage === 100) {
                            setOpen(true)
                            setLoading(true)
                        }
                    }
                })
            }
        }

    }, [open, loading, totalscoreCount, playeruuid])

    useEffect(() => {
        if (gameType === "Buzzer") {
            if (totalscoreCount?.length > 0) {
                if (totalscoreCount?.every(items => items?.is_submitted === true)) {
                    const payload = {
                        "type_uuid": location?.state?.data.type_uuid,
                        "method_uuid": location?.state?.data.method_uuid,
                        "game_uuid": location?.state?.data?.uuid,
                        "question_uuid": currentQuestionUuidRef?.current,
                        "participant_uuid": userData?.data?.data?.uuid,
                        "user_answer": "skip",
                    }
                    axios.put(`${process.env.REACT_APP_WEB_APP}/participant/scorecard/update`, payload, {
                        headers: {
                            "x-auth-token": userData.data.token
                        }
                    })
                        .then((res) => {
                            console.log(res, "bharath123")
                            handleNextClick();
                        })
                        .catch((err) => {
                            console.log(err, "jhdfsfjdsk")
                        })
                }
            }
        }
    }, [totalscoreCount])

    const FinsihFunction = () => {
        setExamOpen(true)
    }

    let trueCount = 0;
    let falseCount = 0;

    for (const response of userResponses) {
        if (response.isCurrectAns) {
            trueCount++;
        } else {
            falseCount++;
        }
    }

    const handleOptionChange = (optionId, answer, isCurrectAns) => {
        console.log(optionId, answer, isCurrectAns, "optionId");
        setSelectedOptionId(optionId);
        setSubmitBtn(false)
        setTypeOfDisabled(true);
        const response = {
            questionUuid: currentQuestion.uuid,
            optionId,
            answer,
            isCurrectAns,
        };
        setAnswer(response)
        // ==========================================================================
        // HandleSubmit(response)
        // If the response for the current question already exists, update it
        if (gameType === "Rapid fire" || gameType === "Buzzer") {
            setTypeOfDisabled(true);
            HandleSubmit(response)
        }
        else {
            setTypeOfDisabled(false);
        }



        const updatedResponses = [...userResponses];
        const existingResponseIndex = updatedResponses.findIndex((item) => item.questionUuid === response.questionUuid);

        if (existingResponseIndex !== -1) {
            updatedResponses[existingResponseIndex] = response;
        } else {
            updatedResponses.push(response);
        }

        setUserResponses(updatedResponses);

    };

    const handleNextClick = () => {
        // setTimePerQuestion(10)
        setIsSubmitted(false);
        if (questions?.data3[0]?.time_per_question) {
            setTimePerQuestion(questions?.data3[0]?.time_per_question)
        }

        // Find the next question index that has not been answered
        let nextQuestionIndex = currentQuestionIndex + 1;
        while (nextQuestionIndex < questions?.data.length) {
            const nextQuestionUuid = questions?.data[nextQuestionIndex].uuid;
            const nextQuestionResponse = userResponses.find((response) => response.questionUuid === nextQuestionUuid);
            if (!nextQuestionResponse) {
                break;
            }
            nextQuestionIndex++;
        }

        const even = (element) => element === nextQuestionIndex;
        const updatedArray = skipdQuesIndex?.some(even)
        // skipdQuesIndex.includes(currentQuestionIndex)

        if (updatedArray) {
            setSkipBtndisabled(true);
        }
        else {
            setSkipBtndisabled(false)
        }

        // If all questions have been answered, don't increment the current index
        if (nextQuestionIndex >= questions?.data.length) {
            return;
        }

        // Update currentQuestionIndex with the next question index
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedOptionId(null);
        const nextQuestionUuid = questions?.data[nextQuestionIndex].uuid;
        const nextQuestionResponse = userResponses.find((response) => response.questionUuid === nextQuestionUuid);
        setSelectedOptionId(nextQuestionResponse ? nextQuestionResponse.optionId : null);
        setTypeOfDisabled(false);
        setSubmitOptionsDisabled(false);
    };



    const handlePreviousClick = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            // Retrieve the previously selected option for the previous question
            const previousQuestionUuid = questions?.data[currentQuestionIndex - 1].uuid;
            const previousResponse = userResponses.find((response) => response.questionUuid === previousQuestionUuid);
            setSelectedOptionId(previousResponse ? previousResponse.optionId : null);
        }
    };

    useEffect(() => {
        if (questions?.data3[0]?.time_per_question) {
            setTimePerQuestion(questions?.data3[0]?.time_per_question)
            setTime(questions?.data3[0]?.total_duration)
            // setTime(10)

        }
    }, [questions])



    useEffect(() => {

        const interval = setInterval(() => {
            if (timePerQuestion > 0) {
                setTimePerQuestion((prevSeconds) => prevSeconds - 1);
            } else {
                clearInterval(interval);
                if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length) {
                    if (gameType === "Exam") {
                        if (totalscoreCount?.length > 0) {
                            if (totalscoreCount.every(items => items?.percentage === 100 && items?.is_finished === true)) {
                                history.push({
                                    pathname: "/ScoreCard",
                                    state: location?.state
                                })
                            }
                        }
                    }
                    else {
                        if (totalscoreCount?.length > 0) {
                            if (totalscoreCount.every(items => items?.percentage === 100)) {
                                history.push({
                                    pathname: "/ScoreCard",
                                    state: location?.state
                                })
                            }
                        }
                    }
                }
                else {
                    SkipQuestion()
                    if (skipdQuesIndex.includes(currentQuestionIndex)) {
                        removeSkippedQuestion(currentQuestionIndex);
                    }
                }

            }
        }, 1000);

        return () => {
            clearInterval(interval);
            // SkipQuestion()
        };
    }, [timePerQuestion]);

    const removeSkippedQuestion = (questionIndex) => {
        // Logic to remove the skipped question from skipdQuesIndex
        const updatedArray = skipdQuesIndex.filter(item => item !== questionIndex);
        setSkipdQuesIndex(updatedArray);
    };

    useEffect(() => {
        if (gameType === "Exam") {
            if (totalscoreCount?.length > 0) {
                if (totalscoreCount?.every(items => items?.percentage === 100 && items?.is_finished === true)) {
                    history.push({
                        pathname: "/ScoreCard",
                        state: location?.state
                    })
                }
            }
        }
        else if (gameType !== "Exam") {
            if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length) {
                if (totalscoreCount?.length > 0) {
                    if (totalscoreCount?.every(items => items?.percentage === 100)) {
                        history.push({
                            pathname: "/ScoreCard",
                            state: location?.state
                        })
                    }
                }
            }
        }
    }, [currentQuestionIndex])



    const CancelGame = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "to exit the game ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Exit!',
                    'Your are successfully exit',
                    'success'
                )
                // setTimeout(() => {

                history.push(({
                    pathname: "/",

                }));

                // }, 1000)
            }
        })
    }


    // Initial time in timePerQuestion

    useEffect(() => {
        if (time) {
            let timerInterval = setInterval(() => {
                if (time > 0) {
                    setTime((prevTime) => prevTime - 1);
                }

                else {
                    clearInterval(timerInterval);

                }


            }, 1000);

            return () => {
                clearInterval(timerInterval);
            };
        }


    }, [time]);

    if (time === 0) {
        if (gameType === "Exam" && totalscoreCount?.length > 0) {
            if (totalscoreCount?.every(items => items?.percentage === 100 && items?.is_finished === true)) {
                history.push({
                    pathname: "/ScoreCard",
                    state: location?.state
                })
            }
        }
        else if (gameType !== "Exam") {
            if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length) {
                if (totalscoreCount?.length > 0) {
                    if (totalscoreCount?.every(items => items?.percentage === 100)) {
                        history.push({
                            pathname: "/ScoreCard",
                            state: location?.state
                        })
                    }
                }
            }
        }
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const timePerQuestion = time % 60;
        return `${minutes} : ${timePerQuestion} `;
    };


    const ScoreCardRedirect = () => {
        if (gameType === "Exam" && totalscoreCount?.length > 0) {
            if (totalscoreCount?.every(items => items?.percentage === 100 && items?.is_finished === true)) {
                history.push({
                    pathname: "/ScoreCard",
                    state: location?.state
                })
            }
        }
        else if (gameType !== "Exam") {
            if (handleanswerdetails?.data?.questions_attempted === questions?.data?.length) {
                if (totalscoreCount?.length > 0) {
                    if (totalscoreCount?.every(items => items?.percentage === 100)) {
                        history.push({
                            pathname: "/ScoreCard",
                            state: location?.state
                        })
                    }
                }
            }
        }
    }

    const TrySkipedQuestion = (event) => {
        console.log(event, "TrySkipedQsdfsfsdfsdfuestion");
        setCurrentQuestionIndex(event)
        setSkipBtndisabled(true)

        if (questions?.data3[0]?.time_per_question) {
            setTimePerQuestion(questions?.data3[0]?.time_per_question)
        }

        // const updatedArray = skipdQuesIndex.filter(item => item !== event);
        // // Update the state with the filtered array
        // setSkipdQuesIndex(updatedArray);

    }
    const HandleClikSkipedForExam = () => {

        // if (!skipdQuesIndex.includes(currentQuestionIndex)) {
        //     setSkipdQuesIndex([...skipdQuesIndex, currentQuestionIndex]);
        // }
        const even = (element) => element === currentQuestionIndex;
        const updatedArray = skipdQuesIndex.some(even)

        if (!updatedArray) {
            setSkipdQuesIndex([...skipdQuesIndex, currentQuestionIndex]);
        }
        if (questions?.data3[0]?.time_per_question) {
            setTimePerQuestion(questions?.data3[0]?.time_per_question)
        }
        setTimeout(() => {
            SkipQuestion()
        }, 500)
    }



    return (
        <>
            <Grid style={{ backgroundImage: "linear-gradient(#2dce89, #2dcecc)" }}>
                <Grid item container className="QuestionContainer" >
                    {/* <h1>Countdown Timer: {timePerQuestion} timePerQuestion</h1> */}
                    <Grid md={4} xs={12} lg={4}>
                        {GameInstructionData && GameInstructionData?.data && GameInstructionData?.data?.map((item) => {

                            return <div key="">
                                <div
                                    style={{
                                        background: "white",
                                        padding: "10px 10px 40px 10px",
                                        borderRadius: "10px",
                                        margin: "10px",
                                    }}
                                >

                                    <Grid item container >

                                        <Grid md={6} xs={6}>
                                            <p style={{ fontWeight: "500", color: "#707070" }}>
                                                Quiz | {item?.competitive_types[0]?.name}
                                            </p>
                                            <p style={{ fontWeight: "500", color: "#707070" }}>
                                                Subject : {item?.topic[0]?.name}
                                            </p>
                                            <p style={{ fontWeight: "500", color: "#000" }}>
                                                Game Id :{" "}
                                                <span style={{ color: "#2a8e46", fontWeight: "bold" }}>
                                                    {item?.uuid}
                                                </span>{" "}
                                            </p>
                                        </Grid>
                                        <Grid md={6} xs={6}>
                                            <p>
                                                {/* <UseTimer expiryTimestamp={expiryTimestamp} /> */}

                                                <div style={{ textAlign: "center", display: "flex", justifyContent: "flex-start" }}>
                                                    <div style={{ fontSize: "25px" }}>

                                                        <span
                                                            style={{
                                                                backgroundColor: "black",
                                                                padding: "3px 4px",
                                                                borderRadius: "10px",
                                                                color: "#fff",
                                                                fontWeight: "bold",
                                                                margin: "5px"
                                                            }}
                                                        >
                                                            {/* {minutes} */}
                                                            {formatTime(time)}

                                                        </span>


                                                    </div>

                                                </div>


                                                {/* <h1>Timer: {formatTime(time)}</h1> */}
                                            </p>
                                            <p style={{ fontWeight: "500", color: "#707070" }}>
                                                Total Questions : {item?.competitive_methods[0]?.questions_count}
                                            </p>
                                            <p
                                                style={{ display: "grid", justifyContent: "space-between", cursor: "pointer" }}
                                                onClick={CancelGame}
                                            >
                                                <span style={{ color: "#5e72e4", fontWeight: "bolder", cursor: "pointer" }}>
                                                    <CloseIcon />
                                                </span>
                                                <span style={{ color: "red" }}>Exit game</span>
                                            </p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        })}
                    </Grid>

                    <Grid md={3} xs={12} lg={3}>

                        <div
                            style={{
                                background: "white",
                                padding: "20px",
                                borderRadius: "10px",
                                margin: "10px",
                            }}
                        >
                            <Grid align="center">

                                <div
                                    style={{
                                        display: "block",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "-webkit-box",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        // paddingLeft:"30px"
                                    }}
                                >
                                    <div>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "#000",
                                                fontSize: "22px",
                                            }}
                                        >
                                            {/* {item.player[0].name} */}
                                        </span>


                                        <p style={{ fontWeight: "500", color: "#000" }}>
                                            {/* Total Score : {scoreCard ? scoreCard?.data?.total_score : 0} */}
                                            Total Score : {TotalScoreBoard ? TotalScoreBoard?.total_score : 0}
                                        </p>

                                        <div style={{ paddingBottom: "1%" }}>
                                            <span style={{ fontWeight: "500", color: "#000", fontSize: "30px" }}>
                                                {TotalScoreBoard ? TotalScoreBoard?.coins : 0}
                                            </span>


                                            <img src={rupees} alt="" width="7%" height="auto" />
                                        </div>
                                    </div>

                                </div>



                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>

                                        <span
                                            style={{
                                                padding: "5px 20px",
                                                backgroundColor: "#2dce89",
                                                display: "block",
                                                justifyContent: "center",
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {TotalScoreBoard ? TotalScoreBoard?.correct_count : 0}
                                        </span>


                                        <span style={{ fontWeight: "500", color: "#707070" }}>
                                            Correct
                                        </span>
                                    </div>
                                    <div>

                                        <span
                                            style={{
                                                padding: "5px 20px",
                                                backgroundColor: "#fb6340",
                                                display: "block",
                                                justifyContent: "center",
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {TotalScoreBoard ? TotalScoreBoard?.skipped_count : 0}
                                        </span>

                                        <span style={{ fontWeight: "500", color: "#707070" }}>
                                            {" "}
                                            Skipped
                                        </span>
                                    </div>
                                    <div>

                                        <span
                                            style={{
                                                padding: "5px 20px",
                                                backgroundColor: "#f5365c",
                                                display: "block",
                                                justifyContent: "center",
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >

                                            {TotalScoreBoard ? TotalScoreBoard?.incorrect_count : 0}
                                        </span>


                                        <span style={{ fontWeight: "500", color: "#707070" }}>
                                            {" "}
                                            Incorrect
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </div>

                    </Grid>
                    <Grid md={5} xs={12} lg={5}>
                        <div
                            style={{
                                background: "white",
                                padding: "10px 10px 40px 10px",
                                borderRadius: "10px",
                                margin: "10px",

                            }}
                        >
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th className="header">Player</th>
                                        <th className="header">Score</th>
                                        <th className="header">Completion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid &&
                                        totalscoreCount?.map((item) => {
                                            return <tr>
                                                <td>{item?.participant_name}</td>
                                                <td>{item?.totalScore}</td>
                                                <td>{item?.percentage}%&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <progress id="file" value={item?.percentage} max="100"></progress>
                                                </td>
                                            </tr>
                                        })

                                    }
                                    {/* <tr>
                                        <td>{location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[0]?.participant_name}</td>
                                        <td>{location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[0]?.totalScore}</td>
                                        <td>{location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[0]?.percentage}%&nbsp;&nbsp;&nbsp;&nbsp;
                                            <progress id="file" value={location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[0]?.percentage} max="100"></progress>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[1]?.participant_name}</td>
                                        <td>{location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[1]?.totalScore}</td>
                                        <td>{location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[1]?.percentage}%&nbsp;&nbsp;&nbsp;&nbsp;
                                            <progress id="file" value={location?.state?.data?.uuid === totalscoreCount[0]?.game_uuid && totalscoreCount[1]?.percentage} max="100"></progress>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table><br />
                            {/* <h3 className="align-center "><b>Playing Against System</b></h3><br /> */}

                        </div>
                    </Grid>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="lg" // Add this line
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Game Notice:"}
                        </DialogTitle>
                        <DialogTitle id="alert-dialog-title">
                            <Grid>
                                <span>Please Wait!,</span>
                                <span>Until Another Player finished the Game.....</span>
                            </Grid>
                        </DialogTitle>
                        <Grid style={{ marginLeft: "35%", marginBottom: "10px" }}>
                            {/* {loading &&
                                <ClockLoader
                                    color="#2dce8c"
                                    loading={loading}
                                    size={120}
                                />
                            } */}
                            {loading &&
                                <CirclesWithBar
                                    height="120"
                                    width="120"
                                    color="#4fa94d"
                                    outerCircleColor="#4fa94d"
                                    innerCircleColor="#4fa94d"
                                    barColor="#4fa94d"
                                    ariaLabel="circles-with-bar-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            }
                        </Grid>
                    </Dialog>

                    <Dialog
                        open={Examopen}
                        onClose={ExamhandleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="lg" // Add this line
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Exam Notice:"}
                        </DialogTitle>
                        <DialogTitle id="alert-dialog-title">
                            <Grid>
                                <span>Are You Sure!,</span><br />
                                <span>Do You Want To finished the Game.....</span>
                            </Grid>
                        </DialogTitle>
                        <Grid style={{ marginLeft: "35%", marginBottom: "10px" }}>
                            <Button type="button" variant="contained" color="success" onClick={SkippedQuestionFinishApi}>Yes</Button> &nbsp;
                            <Button type="button" variant="contained" color="error" onClick={ExamhandleClose}>No</Button>
                        </Grid>
                    </Dialog>



                    <Grid md={gameType === "Exam" ? 8 : 12} >
                        <div
                            style={{
                                background: "white",
                                padding: "10px",
                                borderRadius: "10px",
                                margin: "10px",
                            }}
                        >
                            <Grid
                                item
                                container
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >


                                <Grid md={4}>
                                    <p style={{ fontWeight: "500" }}>Question No :  {currentQuestionIndex + 1}/{questions?.data?.length}

                                    </p>
                                </Grid>
                                <Grid md={4} style={{ textAlign: "center" }}>
                                    <p><span style={{ fontWeight: "500" }}>Next Question In :</span> <span style={{ fontWeight: "bold", color: "#000" }}>{totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.percentage === 100) ? ScoreCardRedirect() : timePerQuestion} Seconds</span></p>
                                </Grid>
                                <Grid md={4} style={{ display: "flex", justifyContent: "end" }}>
                                    <p>

                                        {/* <Button
                      variant="contained"
                      color="success"
                      onClick={handlePreviousClick}
                      disabled={currentQuestionIndex === 0}>
                      Previous
                    </Button> */}
                                        &nbsp;&nbsp;&nbsp;
                                        {/* <Button
                      onClick={handleNextClick}
                      disabled={currentQuestionIndex === questions?.data.length - 1}
                      variant="contained" color="success"
                    >
                      Next
                    </Button> */}



                                        &nbsp;&nbsp;&nbsp;
                                        {/* pervious skip button */}

                                        {/* {gameType === "Rapid fire" || gameType === "Buzzer" && (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.participant === userData.data.data.uuid)) && (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.percentage === 100)) ?
                                            <Button variant="contained" color="error"
                                                disabled
                                            >
                                                Skip
                                            </Button>
                                            : gameType === "Rapid fire" || gameType === "Buzzer" && (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.participant === userData.data.data.uuid)) && (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.percentage === 100)) ?
                                                <Button variant="contained" color="error"
                                                    disabled
                                                >
                                                    Skip
                                                </Button>
                                                : (isLastQuestionSubmit === questions?.data?.length) ?
                                                    <Button variant="contained" color="error"
                                                        disabled
                                                    >
                                                        Skip
                                                    </Button>
                                                    : (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.participant === userData.data.data.uuid)) && (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.percentage === 100)) ?
                                                        <Button variant="contained" color="error"
                                                            disabled
                                                        >
                                                            Skip
                                                        </Button>
                                                        :
                                                        <Button variant="contained" color="error"
                                                            disabled={skipBtndisabled}
                                                            onClick={() => { SkipQuestion(); HandleClikSkipedForExam() }}
                                                        >
                                                            Skip
                                                        </Button>
                                        } */}
                                        {gameType === "Rapid fire" && handleanswerdetails?.data?.questions_attempted === questions?.data?.length ?
                                            <Button variant="contained" color="error"
                                                disabled
                                            >
                                                Skip
                                            </Button>
                                            :
                                            gameType === "Buzzer" && handleanswerdetails?.data?.questions_attempted === questions?.data?.length ?
                                                <Button variant="contained" color="error"
                                                    disabled
                                                >
                                                    Skip
                                                </Button>
                                                :
                                                gameType === "Exam" ?
                                                    <Button variant="contained" color="error"
                                                        disabled={skipBtndisabled}
                                                        onClick={() => { HandleClikSkipedForExam() }}
                                                    >
                                                        Skip
                                                    </Button>
                                                    :
                                                    handleanswerdetails?.data?.questions_attempted === questions?.data?.length ?
                                                        <Button variant="contained" color="error"
                                                            disabled
                                                        >
                                                            Skip
                                                        </Button>
                                                        :
                                                        <Button variant="contained" color="error"
                                                            disabled={skipBtndisabled}
                                                            onClick={() => { SkipQuestion() }}
                                                        >
                                                            Skip
                                                        </Button>
                                        }
                                        &nbsp;&nbsp;&nbsp;
                                        {/* <Link to="/ScoreCard"> */}



                                        {/* ======================================================================================== */}


                                        {/* {gameType === "Rapid fire" || gameType === "Buzzer" &&
                                            null
                                        } */}

                                        {/* pervious submit  button */}

                                        {/* {(totalscoreCount?.length > 0 && totalscoreCount?.every((item) => item.participant === userData.data.data.uuid)) && (totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.percentage === 100)) ?
                                            <Button variant="contained" color="success"
                                                disabled
                                            >
                                                Submit
                                            </Button>
                                            : totalscoreCount?.length > 0 && totalscoreCount?.every((items) => items?.participant === userData.data.data.uuid) && (totalscoreCount.length > 0 && totalscoreCount?.every((items) => items?.percentage === 100)) ?
                                                <Button variant="contained" color="success"
                                                    disabled
                                                >
                                                    Submit
                                                </Button>
                                                : gameType === "Rapid fire" || gameType === "Buzzer" ?
                                                    null
                                                    :
                                                    <Button variant="contained" color="success"
                                                        onClick={HandleSubmitBtn}
                                                        disabled={submitbtn}
                                                    >
                                                        Submit
                                                    </Button>
                                        } */}
                                        {/* submit button code */}
                                        {gameType === "Rapid fire" || gameType === "Buzzer" ?
                                            null
                                            : gameType === "Exam" && handleanswerdetails?.data?.questions_attempted === questions?.data?.length && totalscoreCount?.every((items) => items?.skipped_questions === 0) ?
                                                <Button variant="contained" color="success"
                                                    disabled
                                                >
                                                    Submit
                                                </Button>
                                                : gameType !== "Exam" && handleanswerdetails?.data?.questions_attempted === questions?.data?.length ?
                                                    <Button variant="contained" color="success"
                                                        disabled
                                                    >
                                                        Submit
                                                    </Button>
                                                    : <Button variant="contained" color="success"
                                                        onClick={HandleSubmitBtn}
                                                        disabled={submitbtn}
                                                    >
                                                        Submit
                                                    </Button>
                                        }

                                        {/* </Link> */}

                                        {" "}
                                        {gameType === "Exam" &&
                                            <Button variant="contained" color="success"
                                                onClick={FinsihFunction}
                                                disabled={handleanswerdetails?.data?.questions_attempted !== questions?.data?.length}
                                            >
                                                Finish
                                            </Button>
                                        }
                                    </p>
                                </Grid>
                            </Grid>
                            <hr />
                            {/* <h3>Question and answer section start */}

                            <div className="App">
                                {currentQuestionIndex < questions?.data?.length ? (
                                    <div>
                                        <h2>Question {currentQuestionIndex + 1}: {currentQuestion?.question}</h2>
                                        <Grid item container>
                                            {gameType === "Rapid fire" || gameType === "Buzzer" ?

                                                <>
                                                    {/* {currentQuestion.options.map((option) => (
                                                        <Grid md={6} xs={12} >


                                                            <div
                                                                style={{
                                                                    background: selectedOptionId === option?._id
                                                                        ? option.is_correct_answer
                                                                            ? "green"
                                                                            : "red"
                                                                        : "#8898aa",
                                                                    padding: "18px",
                                                                    margin: "10px",
                                                                    display: "flex",
                                                                    color: "white",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <label>
                                                                    {option.answer}
                                                                </label>
                                                                <input
                                                                    style={{ width: "25px", height: "25px" }}
                                                                    type="radio"
                                                                    name="options"
                                                                    value={option?._id}
                                                                    checked={selectedOptionId === option?._id}
                                                                    onChange={() => handleOptionChange(option?._id, option.answer, option.is_correct_answer)}
                                                                />



                                                            </div>
                                                        </Grid>
                                                    ))} */}

                                                    {currentQuestion?.options?.map((option) => (
                                                        <Grid md={6} xs={12} key={option._id} >
                                                            <div onClick={() => !typeofdisabled ? handleOptionChange(option?._id, option?.answer, option?.is_correct_answer) : null}
                                                                style={{
                                                                    padding: "5px",
                                                                    margin: "5px",
                                                                    cursor: "pointer"
                                                                }}

                                                            >
                                                                <div disabled={submitoptionsDisabled}
                                                                    style={{
                                                                        background: selectedOptionId === option?._id
                                                                            ? option?.is_correct_answer
                                                                                ? "green"
                                                                                : "red"
                                                                            : "#8898aa",
                                                                        padding: "18px",
                                                                        display: "flex",
                                                                        color: "white",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        {option.answer}
                                                                    </label>
                                                                    <input
                                                                        style={{ width: "25px", height: "25px" }}
                                                                        type="radio"
                                                                        name="options"
                                                                        value={option?._id}
                                                                        checked={selectedOptionId === option?._id}
                                                                        onChange={() => handleOptionChange(option?._id, option?.answer, option?.is_correct_answer)}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        disabled={submitoptionsDisabled}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    ))}

                                                </>

                                                :
                                                <>
                                                    {currentQuestion?.options.map((option) => (
                                                        <Grid md={6} xs={12} key={option?._id}>
                                                            <div onClick={() => !submitoptionsDisabled ? handleOptionChange(option?._id, option?.answer, option?.is_correct_answer) : null} style={{ cursor: "pointer" }}>
                                                                <div
                                                                    style={{
                                                                        background:
                                                                            // isSubmitted && option.is_correct_answer
                                                                            isSubmitted && selectedOptionId === option?._id && option?.is_correct_answer
                                                                                ? 'green'
                                                                                : isSubmitted && selectedOptionId === option?._id
                                                                                    ? 'red'
                                                                                    : selectedOptionId === option?._id
                                                                                        ? 'blue'
                                                                                        : '#8898aa',
                                                                        padding: '18px',
                                                                        margin: '10px',
                                                                        display: 'flex',
                                                                        color: 'white',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label>{option?.answer}</label>
                                                                    <input
                                                                        style={{ width: '25px', height: '25px' }}
                                                                        type="radio"
                                                                        name="options"
                                                                        value={option._id}
                                                                        checked={selectedOptionId === option?._id}
                                                                        // onChange={() => handleOptionChange(option._id)}
                                                                        onChange={() => handleOptionChange(option?._id, option?.answer, option?.is_correct_answer)}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        disabled={submitoptionsDisabled}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    ))}
                                                </>
                                            }

                                            {/* <button onClick={handleSubmit}>Submit</button> */}

                                        </Grid>

                                    </div>
                                ) : (
                                    <h2>Quiz Finished!</h2>
                                )}
                            </div>

                        </div>
                    </Grid>

                    {gameType === "Exam" ?
                        <Grid md={4} xs={4} lg={4} >
                            <div
                                style={{
                                    background: "black",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    margin: "10px",
                                }}
                            >
                                <p style={{
                                    color: "white",
                                    padding: "10px",
                                }}>Skiped Questions Palette test</p>


                                <Grid item container>

                                    {skipdQuesIndex?.map((item, index) => (
                                        <Grid md={1} key={index}>
                                            <div
                                                style={{
                                                    background: "red",
                                                    color: "white",
                                                    padding: "5px",
                                                    // width: "20px",
                                                    margin: "5px",
                                                    cursor: "pointer",
                                                    borderRadius: "5px",
                                                    alignItems: "center",// Add cursor style to indicate interactivity
                                                    justifyContent: "center"

                                                }}
                                                onClick={() => TrySkipedQuestion(item)} // Use 'onClick' instead of 'onChange' for click events
                                            >
                                                {item + 1}
                                            </div>
                                        </Grid>
                                    ))}

                                </Grid>
                            </div>

                        </Grid>

                        :
                        null
                    }



                </Grid >


            </Grid >






        </>
    );
}

export default UserQuestions;
