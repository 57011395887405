import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    StreamsData: [],
  notification:{}
};

const StreamsSlice = createSlice({
  name: 'Streams',
  initialState,
  reducers: {
    setStreamsData(state, action) {
      state.StreamsData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setStreamsData, setToast } = StreamsSlice.actions;


export const StreamsListURL = (educationLevelUuid,limit,search,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/academic/stream?level_uuid=${educationLevelUuid}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setStreamsData(response.data));
};



const StreamsReducer = StreamsSlice.reducer;

export default StreamsReducer;
