import React,{useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from 'react-redux';
import { secureStorage } from "../../Utils";
import { PlayHistoryURL } from "../../Redux/Game/Stats/PlayHistoryRedux";

const UserSchedule = () =>{
    const [selectedOption, setSelectedOption] = useState('Option 1');

    const handleDropdownChange = (e) => {
      setSelectedOption(e.target.value);
    };



    return(
        <>
        <div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"1%"}}>
                <div>
                {/* <div>
      <label htmlFor="dropdown" className="headertext">Show </label>
      <select id="dropdown" value={selectedOption} onChange={handleDropdownChange} style={{padding:"3px 20px"}} className="headerinput">
        <option value="Option 1">1</option>
        <option value="Option 2">5</option>
        <option value="Option 3">10</option>
        <option value="Option 4">15</option>
        <option value="Option 5">20</option>
      </select>
      <label htmlFor="dropdown" className="headertext"> entries </label>
    </div> */}
                </div>
                <div className="headertext" style={{display:"flex",justifyContent:"end"}}>
                    Search:&nbsp; <input style={{padding:"3px 5px"}} className="headerinput"/>
                </div>
            </div>
 
     


<div>
      <table className="custom-table">
      <thead>
          <tr>
            <th className="header">S.NO</th>
            <th className="header">START DATE</th>
            <th className="header">EXAM TYPE</th>
            <th className="header">MODEL</th>
            <th className="header">LINK</th>
            <th className="header">ACTION</th>
          </tr>
        </thead>
        <tbody>
            <tr >
              <td className="align-center">1</td>
              <td className="align-center">ghfh</td>
              <td className="align-center">dfgdg</td>
              <td className="align-center">98</td>
              <td className="align-center">7</td>
              <td className="align-center">87</td>
            </tr>
     
        </tbody>
      </table>
    </div>

      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"1%"}}>
                <div className="headertext">
                Showing 0 to 0 of 0 entries
                </div>
                <div>
                   <span style={{border:"1px solid #ddd" ,padding:"8px 30px",cursor:"pointer"}} className="footerbutton">Previous</span><span style={{border:"1px solid #ddd" ,padding:"8px 30px",cursor:"pointer"}} className="footerbutton">Next</span>
                </div>
            </div>
        </div>
        </>
    )
};

export default UserSchedule;