



import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import GameInstruction from './View/Game/GameInstruction/GameInstruction';
import GameInstructionUserVsUser from './View/Game/GameInstruction/GameInstructionUserVsUser';
import Login from "./auth/Login"
import ForgotPassword from './auth/ForgotPassword';
import Otp from './auth/Otp';
import { secureStorage } from "./Utils";
import Home from './View/HomePage/LandinPage';
import Questions from './View/Game/Questions/Question';
import UserQuestions from './View/Game/Questions/UsersQuestions';
import ScoreCard from './View/Game/ScoreCard/ScoreCard';
import Stats from './View/Stats/Stats';

import Friends from './View/friends/friends';
import QuestionTimer from './View/Game/Questions/QuestionTimer';
import TopicsDetail from './View/MyPost/TopicsDetail';
import Topics from './View/MyPost/Topics';
import NewTopics from './View/MyPost/NewTopics';
import Schedule from './View/Schedule/Schedule';
import WaitingRoom from './View/Room/WaitingRoom';
import io from 'socket.io-client';



const App = () => {

  const host = "https://api.quizapp.scienstechnologies.com";

  const [authenticated, setAuthenticated] = useState(false);
  const [showFooter, setShowFooter] = useState(true);


  useEffect(() => {
    let userData = secureStorage.getItem("currentUser")
    console.log(userData, "userData")
    if (userData) {
      setAuthenticated(true)
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname === "/game-instruction") {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [window.location.pathname]);



  const handleLogin = () => {
    
    // In a real application, you'd implement the login logic here, e.g., set authentication status
    setAuthenticated(true); // For this example, we're setting the state directly
  };

  console.log(authenticated, "authenticated")

  // const [onlineUserCount, setOnlineUserCount] = useState(0);

  // let userData = secureStorage.getItem("currentUser")

  // const playeruuid = userData?.data?.data?.uuid
  // console.log(playeruuid, "playeruuid")

  // useEffect(() => {
  //   if (userData?.data?.data?.uuid || playeruuid) {
  //     const queryParams = {
  //       userId: playeruuid ? playeruuid : '',
  //     };
  //     console.log(queryParams, "queryParams");

  //     const socket = io(host, {
  //       path: '/pathToConnection',
  //       transports: ['websocket'],
  //       upgrade: false,
  //       query: queryParams,
  //       reconnection: true,
  //       rejectUnauthorized: false
  //     });

  //     socket.on('connect', () => {
  //       console.log('Connected to the server');
  //     });
  //     socket.on('onlineusercount', (count) => {
  //       console.log('Received online user count:', count);
  //       setOnlineUserCount(count);

  //     });

  //     return () => {
  //       if (socket) {
  //         socket.disconnect();
  //       }
  //     };
  //   }

  // }, [playeruuid, userData]);





  return (
    <Router>
      <div>
        {/* <Header /> */}
        <Switch>
          {/* <Route exact path="/" render={(props) => <Home {...props} onlineUserCount={onlineUserCount} />} /> */}
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/about" component={About} /> */}

          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/Otp" component={Otp} />
          <Route exact path="/game-instruction" component={GameInstruction} />
          <Route exact path="/game-instruction-uservsalluser" component={GameInstructionUserVsUser} />
          <Route exact path="/questions" component={Questions} />
          <Route exact path='/Userquestions' component={UserQuestions} />
          <Route exact path="/Scorecard" component={ScoreCard} />
          <Route exact path="/Stats" component={Stats} />
          <Route exact path="/Friends" component={Friends} />
          <Route exact path="/QuestionTimer" component={QuestionTimer} />
          <Route exact path="/Topics" component={Topics} />
          <Route exact path="/TopicsDetail" component={TopicsDetail} />
          <Route exact path="/NewTopics" component={NewTopics} />
          <Route exact path="/Schedule" component={Schedule} />
          <Route exact path="/Waiting-room" component={WaitingRoom} />
          {/* <Route
            exact
            path="/login"
            render={(props) => (
              <Login {...props} login={handleLogin} />
            )}
          /> */}
          {authenticated ? (
            <>

              <Route exact path="/home" component={Home} />
              {/* <Route exact path="/about" component={About} /> */}
              <Route exact path="/game-instruction" component={GameInstruction} />
            </>
          ) : (
            <Redirect to="/" />
          )}
        </Switch>
        {/* {showFooter && */}
        {/* <Footer /> */}
        {/* } */}
      </div>
    </Router>
  );
};

export default App;
