import { Button, Grid } from "@mui/material";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from '../../Components/Header';
import stats from '../../Assets/stats.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimerIcon from '@mui/icons-material/Timer';
import TextField from "@mui/material/TextField";
import Pagination from '@mui/material/Pagination';
import './mypost.css'
import Stack from '@mui/material/Stack';

const Topics = () =>{
    return(
        <>
   <Grid style={{background: "#eeeeee",width:"100%",height:"100%",padding:" 120px 300px 31px"}} className="mypostcontainer">
    <Header/>
           <Grid item container  >
           {/* <div style={{display:"flex",justifyContent:"space-between"}}> */}
           <Grid xs={12} md={3} lg={3} style={{display:"flex",justifyContent:"start",color:"#000",fontSize:"20px",padding: "0px 20px",}}>
              
                    <TextField
              id="outlined-basic"
              label="Search Topics"
              variant="outlined"
              type="text"
              fullWidth 
              // maxWidth="xxl"
            
            />
                </Grid>
                <Grid xs={12} md={9} lg={9} style={{display:"flex",justifyContent:"end",alignItems:"center"}} className="buttonmypost">
               <Link to="/NewTopics"> <Button style={{backgroundColor:"orangered",color:"white"}} >New Topic</Button> </Link>&nbsp;&nbsp;
                    <Button style={{backgroundColor:"orangered",color:"white"}} >My Post</Button>
                </Grid>
           {/* </div> */}
           </Grid>
           <Grid xs={12} md={12} lg={12} style={{paddingTop:"5px"}}>
           <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}}>
                <div  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                  
                    <img src={stats} alt="" width="40px" height="auto" />&nbsp;&nbsp;
                
                         <Link to="/TopicsDetail" style={{fontSize:"23px",textDecoration:"none",color:"#004289",cursor:"pointer"}} >
                            <div className="postDetails">test post by lakshmi on 05/07/2021....</div> </Link>
              <p>lakshmi</p>
                    </div>
                    <div>2
                        <div style={{padding:"5px",backgroundColor:"yellowgreen"}}></div>
                        <RemoveRedEyeIcon/>  25 <br/>
                        <TimerIcon/>23-10-23
                    </div>
                   
                 </div>
            </div>
           </Grid>

           <Grid xs={12} md={12} lg={12} style={{paddingTop:"5px"}}>
           <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}}>
                <div  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                  
                    <img src={stats} alt="" width="40px" height="auto" />&nbsp;&nbsp;
                   
                        <span style={{fontSize:"25px",color:"#004289",cursor:"pointer"}} >
                            <div  className="postDetails">
                            can any body share 10th maths tips
                                </div></span>
              <p>mahesh</p>
                    </div>
                    <div>9
                        <div style={{padding:"5px",backgroundColor:"yellowgreen"}}></div>
                        <RemoveRedEyeIcon/>  32 <br/>
                        <TimerIcon/>11-12-23
                    </div>
                   
                 </div>
            </div>
           </Grid>

           <Grid xs={12} md={12} lg={12} style={{paddingTop:"5px"}}>
           <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}}>
                <div  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                  
                    <img src={stats} alt="" width="40px" height="auto" />&nbsp;&nbsp;
                   
                        <span style={{fontSize:"25px",color:"#004289",cursor:"pointer"}}> 
                        <div className="postDetails">
                        This is my first post on kwizworld
                        </div>
                        </span>
              <p>saikiran</p>
                    </div>
                    <div>6
                        <div style={{padding:"5px",backgroundColor:"yellowgreen"}}></div>
                        <RemoveRedEyeIcon/>  43 <br/>
                        <TimerIcon/>22-10-23
                    </div>
                   
                 </div>
            </div>
           </Grid>

           <Grid xs={12} md={12} lg={12} style={{paddingTop:"5px"}}>
           <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}}>
                <div  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                  
                    <img src={stats} alt="" width="40px" height="auto" />&nbsp;&nbsp;
                   
                        <span style={{fontSize:"25px",color:"#004289",cursor:"pointer"}}>
                            <div className="postDetails">
                            Tournament questions
                            </div>
                           </span>
              <p>Siva</p>
                    </div>
                    <div>8
                        <div style={{padding:"5px",backgroundColor:"yellowgreen"}}></div>
                        <RemoveRedEyeIcon/>  98 <br/>
                        <TimerIcon/>13-10-23
                    </div>
                   
                 </div>
            </div>
           </Grid>

           <Grid xs={12} md={12} lg={12} style={{display:"flex",justifyContent:"end"}}>
        
           <Pagination count={25} variant="outlined" shape="rounded" />
   

           </Grid>
          
        </Grid>
        </>
    )
};
export default Topics;