import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  EducationData: [],
  notification:{}
};

const EducationSlice = createSlice({
  name: 'Education',
  initialState,
  reducers: {
    setEducationData(state, action) {
      state.EducationData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setEducationData, setToast } = EducationSlice.actions;


export const EducationListURL = (token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/education/dropdown/list`,
  {headers:{
    "x-auth-token" : token

}}).then((res) => {
  dispatch(setEducationData(res.data));

})
.catch((err) => {
  dispatch(setEducationData([]));

})
}


const EducationReducer = EducationSlice.reducer;

export default EducationReducer;
