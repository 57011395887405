import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    LocationData: [],
  notification:{}
};

const LocationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {
    setLocationData(state, action) {
      state.LocationData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setLocationData, setToast } = LocationSlice.actions;


export const LocationListURL = (pageNUm,limit,search,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/location/dropdown/list`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setLocationData(response.data));
};

// export const LocationAddURL = (payload,token) => async (dispatch) => {
//   const response = await axios.post(`${process.env.REACT_APP_URL}/topic/add`,payload,{headers:{
//     "x-auth-token" : token
//   }})
//   .then((res) => {
//     console.log(res, "sdfsdfsdff")
//     dispatch(setToast({ status: true, message: res.data.message }))
//   })
//   .catch((err) => {
//     dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))

//   })
// };


  









const LocationReducer = LocationSlice.reducer;

export default LocationReducer;
