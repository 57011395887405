import React, { useState, useEffect } from 'react'
import { secureStorage } from "../../Utils";
import Grid from '@mui/material/Grid';
import "./Home.css"
import intro from "../../Assets/intro-img.svg"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Contact from '../ContactPage/Contact';
import HomeStatic from './HomeStatic';
import examIcon from "../../Assets/exam_card.jpg"
import Login from "../../auth/Login"
import Header from '../../Components/Header'
import player from "../../Assets/useronline.png"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
} from "react-router-dom";
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryListURL } from '../../Redux/ExamCategoryRedux/ExamCategoryRedux';
import { GetGKTopicListURL } from '../../Redux/GKExamRedux/GKExamRedux'
import { GetGKSubTopicListURL } from '../../Redux/GKExamRedux/GKExamSubTopicRedux';
import { ExamTypeListURL } from '../../Redux/Competitve Exams/ExamtypeRedux';
import { CompatativeExamListURL } from '../../Redux/Competitve Exams/CompatativeExamRedux';
import { CompatativeSubPpaperListURL } from '../../Redux/Competitve Exams/ComtativeSubPpaerRedux';
import { EducationListURL } from '../../Redux/EducationRedux/EducationRedux';
import { LocationListURL } from '../../Redux/Location/LocationRedux';
import { EntanceExamTypeGetURL } from '../../Redux/EntranceExam/EntranceEamTypeRedux';
import { EntanceExamGetURL } from '../../Redux/EntranceExam/EntranceExam';
import { ETEGetSubPaperListURL } from '../../Redux/EntranceExam/SubPaperRedux';
import { BoardListURL } from '../../Redux/Acadamic Exams/Board/BoardRedux';
import { GroupListURL } from '../../Redux/Acadamic Exams/Group/GroupRedux';
import { StreamsListURL } from '../../Redux/Acadamic Exams/Streams/StreamsRedux';
import { SubSubjectListURL } from '../../Redux/Acadamic Exams/Sub Subject/SubSubjectRedux';
import { SubjectListURL } from '../../Redux/Acadamic Exams/Subject/SubjectRedux';
import { GameListGetURL, GameAddURL, GameUpdateURL, UserVsAllUserCreateGame } from '../../Redux/Game/GameListRedux';
import Footer from '../../Components/Footer';
import { GameTypeGetURL } from '../../Redux/Game/selectGameRedux/GameTypeRedux';
import { QuizMethodListGetURL } from '../../Redux/Game/selectGameRedux/QuizMethodRedux';
import { PlayHistoryHeaderURL } from '../../Redux/Game/Stats/PlayHistoryHeader';
import io from 'socket.io-client';


const host = "https://api.quizapp.scienstechnologies.com";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {

    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}




function Home() {
    const history = useHistory();
    const dispatch = useDispatch();
    let userData = secureStorage.getItem("currentUser")

    const playeruuid = userData?.data?.data?.uuid


    const [suc, setSuc] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('')
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [gamePoup, setGamePopup] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const [selectedValue, setSelectedValue] = useState('');
    const [quizMethod, setQuizMethod] = useState('')
    console.log(quizMethod, "new_quizMethod");
    const [userType, setUserType] = useState('')

    const eventHandler = (event) => {
        console.log(event, "event11212");
        setQuizMethod(event.uuid)
        setUserType(event.name)
    }

    const handleSelectChange = (event) => {
        console.log()
        setSelectedValue(event.target.value);

    };





    const [ExamType, setExamType] = useState({ value: '', label: '', });
    console.log(ExamType, "ExamType")
    const [educationLevel, setEducationLevel] = useState({ value: '', label: '', });
    const [BoardType, setBoardType] = useState({ value: '', label: '', })
    const [stateName, seStateName] = useState({ value: '', label: '', })



    const [CompatitiveExamType, seCompatitiveExamType] = useState({ value: '', label: '', })
    const [CompatitiveExam, seCompatitiveExam] = useState({ value: '', label: '', })
    // const [compatitiveSubPaper, seCompatitiveSubPaper] = useState({ value: '', label: '', })
    const [compatitiveSubPaper, seCompatitiveSubPaper] = useState([]);
    console.log(CompatitiveExamType, CompatitiveExam, compatitiveSubPaper, "compatitiveSubPaper");

    const [ETExamName, setETExamName] = useState({ value: '', label: '', })
    const [ETExam, setETExam] = useState({ value: '', label: '', })
    // const [ETExamSubPaper, setETExamSubPaper] = useState({ value: '', label: '', })
    const [ETExamSubPaper, setETExamSubPaper] = useState([])
    console.log(ETExamName, ETExam, ETExamSubPaper, "entrance new");

    const [academicBoard, setAcademicBoard] = useState({ value: '', label: '', })
    const [academicGroup, setAcademicGroup] = useState({ value: '', label: '', })
    const [academicStream, setAcademicStream] = useState({ value: '', label: '', })
    const [academicSubject, setAcademicSubject] = useState([]);
    const [academicYear, setacademicYear] = useState({ value: '', label: '' })
    const [academicSemester, setacademicSemester] = useState({ value: '', label: '' })


    const [onlineUserCount, setOnlineUserCount] = useState(0);
    const [joinedUserCount, setJoinedUserCount] = useState(0);

    console.log(joinedUserCount, "joinedUserCount");
    const queryParamString = academicSubject.map(value => `subject_uuid[]=${value.value}`).join('&');

    const [academicSubSubject, setAcademicSubSubject] = useState([]);


    console.log(academicSubSubject, "academicSubSubject");

    const values1 = academicSubject.map(item => item.value);
    const values = academicSubSubject.map(item => item.value);

    console.log(values1, "values");
    const [selectedGKTopic, setSelectedGKtopic] = useState([]);
    const [selectedSubTopic, setSelectedSubTopic] = useState([]);

    const handleChangeGKTopic = (_, newValue) => {

        const uniqueValues = newValue.filter((value, index, self) =>
            index === self.findIndex((v) => v.label === value.label)
        );
        setSelectedGKtopic(uniqueValues);
        setDisableBtn(false)
    };

    // const handleChangeGKTopic = (event, newValue) => {

    //     const uniqueValues = newValue.filter(
    //         (option) => !selectedGKTopic.some((selected) => selected.value === option.value)
    //     );

    //     setSelectedGKtopic([...selectedGKTopic, ...uniqueValues]);
    // };

    const handleChangeGKsubTopic = (_, newValue) => {
        const uniqueValues = newValue.filter((value, index, self) =>
            index === self.findIndex((v) => v.label === value.label)
        );
        setSelectedSubTopic(uniqueValues);
        setDisableBtn(false)
    };



    // const handleChangeGKsubTopic = (event, newValue) => {
    //     const uniqueValues = newValue.filter(
    //         (option) => !selectedSubTopic.some((selected) => selected.value === option.value)
    //     );

    //     setSelectedSubTopic([...selectedSubTopic, ...uniqueValues]);
    //     setDisableBtn(false)
    // };


    const handleButtonClick = () => {
        const topicUUIDs = selectedGKTopic.map((option) => option.value);
        const url = `topic_uuid[]=${topicUUIDs.join('&topic_uuid[]=')}`;
        dispatch(GetGKSubTopicListURL(url))
        // Here, you can perform the API call with the constructed URL
        console.log(url, "asdasdsadas");
    };
    useEffect(() => {
        if (selectedGKTopic?.length !== 0) {
            handleButtonClick()
        }
    }, [selectedGKTopic])



    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleLoginFormClose = () => {
        setShowLoginForm(false);
    };



    // login popup start
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        if (userData) {
            setOpen(true);
        }
        else {
            handleLoginClick()
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    // login popup end


    const { EducationData } = useSelector((state) => state.EducationList)

    const { LocationData } = useSelector((state) => state.LocationList)

    const { ExamTypeData } = useSelector((state) => state.ExamTypeList)
    const { CompatativeExamData } = useSelector((state) => state.CompatativeExamList)
    const { CompatativeSubPpaperDtata, } = useSelector((state) => state.CompatativeSubPpaperList)


    const { categoryData } = useSelector((state) => state.categoryList)
    const { GKTopicData } = useSelector((state) => state.GKTopicList)
    const { GKSubTopicData } = useSelector((state) => state.GKSubTopicList)

    const { EntranceExamTypeData } = useSelector((state) => state.EntranceExamTypeList)
    const { ExamData } = useSelector((state) => state.EntranceExamList)
    const { SubPaperData } = useSelector((state) => state.SubPaperList)

    const { BoardData } = useSelector((state) => state.BoardList)
    const { StreamsData } = useSelector((state) => state.StreamsList)
    const { GroupData } = useSelector((state) => state.GroupList)
    const { SubjectData } = useSelector((state) => state.SubjectList)
    const { SubSubjectData } = useSelector((state) => state.SubSubjectList)
    console.log(SubSubjectData, SubjectData, "SubSubjectData");

    const { GameListData, notification } = useSelector((state) => state.GameList)
    const { GameTypeData } = useSelector((state) => state.GameTypeList)
    const { QuizMethodData } = useSelector((state) => state.QuizMethodList)
    console.log(QuizMethodData, "QuizMethodData");



    const EducationLevelList = EducationData && EducationData.data && EducationData.data.map((item) => { return { label: item.name, value: item.uuid } })
    const StateNameList = LocationData && LocationData.data && LocationData.data.map((item) => { return { label: item.state, value: item.uuid } })

    const CompetitiveExamTypeList = ExamTypeData && ExamTypeData.data && ExamTypeData.data.map((item) => { return { label: item.exam_type, value: item.uuid } })
    const CompetitiveExamList = CompatativeExamData && CompatativeExamData.data && CompatativeExamData.data.map((item) => { return { label: item.exam_name, value: item.uuid } })
    const CompetitiveSubPpaperList = CompatativeSubPpaperDtata && CompatativeSubPpaperDtata.data && CompatativeSubPpaperDtata.data.map((item) => { return { label: item.sub_paper, value: item.uuid } })

    const ETExamTypeList = EntranceExamTypeData && EntranceExamTypeData.data && EntranceExamTypeData.data.map((item) => { return { label: item.entrance_name, value: item.uuid } })
    const ETExamList = ExamData && ExamData.data && ExamData.data.map((item) => { return { label: item.subject, value: item.uuid } })
    const ETEamSubPpaerList = SubPaperData && SubPaperData.data && SubPaperData.data.map((item) => { return { label: item.sub_paper, value: item.uuid } })

    const AcadmicBoardList = BoardData && BoardData.data && BoardData.data.map((item) => { return { label: item.board_name, value: item.uuid } })
    const AcadmicStreamsList = StreamsData && StreamsData.data && StreamsData.data.map((item) => { return { label: item.stream, value: item.uuid } })
    const AcadmicGroupList = GroupData && GroupData.data && GroupData.data.map((item) => { return { label: item.group, value: item.uuid } })
    const AcadmicSubjectList = SubjectData && SubjectData?.data?.map((item) => { return { label: item.subject, value: item.uuid } })
    // const AcadmicSubSubjectList = SubSubjectData ? SubSubjectData?.map((item) => { return { label: item.sub_subject_name, value: item.uuid } }) : [];
    const AcadmicSubSubjectList = Array.isArray(SubSubjectData)
        ? SubSubjectData.map((item) => ({ label: item.sub_subject_name, value: item.uuid }))
        : [];


    console.log(AcadmicSubSubjectList, "ETEamSubPpaerList");
    const CategoryList = categoryData && categoryData.map((item) => { return { value: item.uuid, label: item.exam_category } })

    const GKTopicList = GKTopicData && GKTopicData.map((item) => { return { label: item.name, value: item.uuid } })
    const GKSubTopicList = GKSubTopicData && GKSubTopicData.map((item) => { return { label: item.name, value: item.uuid } })


    console.log(AcadmicSubSubjectList, CompetitiveSubPpaperList, "AcadmicSubSubjectList");

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));



    useEffect(() => {
        dispatch(CategoryListURL())
        dispatch(EducationListURL())
    }, [])

    // compatitive api calls start
    useEffect(() => {
        if (educationLevel.value !== '')
            dispatch(ExamTypeListURL(educationLevel.value, BoardType.value, stateName.label))
    }, [educationLevel, educationLevel, stateName])


    useEffect(() => {
        if (CompatitiveExamType.value !== '') {
            dispatch(CompatativeExamListURL(CompatitiveExamType.value, educationLevel.value))
        }

    }, [CompatitiveExamType])

    useEffect(() => {
        if (CompatitiveExam.value !== '') {
            dispatch(CompatativeSubPpaperListURL(CompatitiveExam.value))
        }

    }, [CompatitiveExam])
    // compatitive apis end


    // Entrance exam apis start
    useEffect(() => {
        if (educationLevel.value !== '') {
            dispatch(EntanceExamTypeGetURL(educationLevel.value, BoardType.value, stateName.label))
        }
    }, [educationLevel, educationLevel, stateName])

    useEffect(() => {
        if (ETExamName.value !== '') {
            dispatch(EntanceExamGetURL(ETExamName.value, educationLevel.value, BoardType.value, stateName.label))
        }
    }, [ETExamName, educationLevel, BoardType, stateName])


    useEffect(() => {
        if (ETExam.value !== '') {
            dispatch(ETEGetSubPaperListURL(ETExam.value))
        }
    }, [ETExam,])
    // Entrance exam apis end


    // Academic exam  exam apis start
    useEffect(() => {

        if (educationLevel.value !== '') {
            dispatch(StreamsListURL(educationLevel.value,))
        }
    }, [educationLevel])

    useEffect(() => {
        if (BoardType.value !== '') {
            dispatch(BoardListURL(BoardType.value, stateName.label))
        }

    }, [BoardType, stateName])

    useEffect(() => {
        if (academicStream.value !== '' && educationLevel.value !== '')
            dispatch(GroupListURL(educationLevel.value, academicStream.value,))
    }, [academicStream, educationLevel])

    useEffect(() => {
        if (academicStream.value !== '' && educationLevel.value !== '')
            dispatch(SubjectListURL(educationLevel.value, academicStream.value, academicBoard.value, academicGroup.value, academicYear.value, academicSemester.value))
    }, [educationLevel, academicStream, academicBoard, academicGroup, academicYear, academicSemester])


    useEffect(() => {
        if (academicStream.value !== '' && academicGroup.value !== '' && academicSubject?.length !== 0)
            dispatch(SubSubjectListURL(academicStream.value, academicGroup.value, queryParamString))
    }, [academicStream, academicGroup, academicSubject])



    useEffect(() => {
        dispatch(CategoryListURL())

        dispatch(EducationListURL())

        dispatch(ExamTypeListURL())

        dispatch(CompatativeExamListURL())
        dispatch(CompatativeSubPpaperListURL())

        dispatch(GetGKTopicListURL())
        dispatch(LocationListURL())

        dispatch(EntanceExamTypeGetURL())
        dispatch(EntanceExamGetURL())
        dispatch(ETEGetSubPaperListURL())

        // BoardListURL

        dispatch(BoardListURL())
        dispatch(GroupListURL())
        dispatch(StreamsListURL())
        // dispatch(SubSubjectListURL())
        dispatch(SubjectListURL())
        // dispatch(GameListGetURL())

    }, [])

    // this is same game list we have to delete it letter
    useEffect(() => {
        dispatch(PlayHistoryHeaderURL());
    }, [])


    const [value, setValue] = React.useState(0);

    const handleChange1 = (event, newValue) => {
        console.log(newValue, "evedfggfdnt");
        setValue(newValue);
    };




    const handleChangeExamType = (event) => {
        console.log(event, "sdfsfsdfsfdsf")
        const selectedValue = event.target.value;
        const selectedLabel = CategoryList.find((option) => option.value === selectedValue)?.label || '';
        setExamType({ value: selectedValue, label: selectedLabel });
    };

    const handleChangeEducationLevel = (event) => {
        console.log(event, "sdfsfsdfsfdsf")
        const selectedValue = event.target.value;
        const selectedLabel = EducationLevelList.find((option) => option.value === selectedValue)?.label || '';
        setEducationLevel({ value: selectedValue, label: selectedLabel });
    };


    const BoardOptions = [
        { value: 'central', label: 'central' },
        { value: 'state', label: 'state' },

    ];


    const handleChangeBoardType = (event) => {
        console.log(event, "sdfsfsdfsfdsf")
        const selectedValue = event.target.value;
        setBoardType({ value: selectedValue, label: selectedValue });
        if (selectedValue === 'central') {
            seStateName({ value: '', label: '' });
        }
    };

    const handleChangeStateName = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = StateNameList.find((option) => option.value === selectedValue)?.label || '';
        seStateName({ value: selectedValue, label: selectedLabel });
    };

    const handleChangeCompatitiveExamType = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = CompetitiveExamTypeList.find((option) => option.value === selectedValue)?.label || '';
        seCompatitiveExamType({ value: selectedValue, label: selectedLabel });
    };

    const handleChangeCompatitiveExam = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = CompetitiveExamList.find((option) => option.value === selectedValue)?.label || '';
        seCompatitiveExam({ value: selectedValue, label: selectedLabel });
    };


    const handleChangeCompatitiveSubPpaer = (_, newValue) => {
        console.log(newValue, "newValue")
        // const selectedValue = event.target.value;
        // const selectedLabel = CompetitiveSubPpaperList.find((option) => option.value === selectedValue)?.label || '';

        const uniqueValues = newValue.filter((value, index, self) =>
            index === self.findIndex((v) => v.label === value.label)
        );
        seCompatitiveSubPaper(uniqueValues);
        setDisableBtn(false)
    };

    const handleChangeETExamName = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = ETExamTypeList.find((option) => option.value === selectedValue)?.label || '';
        setETExamName({ value: selectedValue, label: selectedLabel });
    };

    const handleChangeETExam = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = ETExamList.find((option) => option.value === selectedValue)?.label || '';
        setETExam({ value: selectedValue, label: selectedLabel });
    };


    const handleChangeETExamSubPaper = (_, newValue) => {
        console.log(newValue, "handleChangeETExamSubPaper")
        // const selectedValue = event.target.value;
        // const selectedLabel = ETEamSubPpaerList.find((option) => option.value === selectedValue)?.label || '';

        const uniqueValues = newValue.filter((value, index, self) =>
            index === self.findIndex((v) => v.label === value.label)
        );

        setETExamSubPaper(uniqueValues);
        setDisableBtn(false)
    };


    const handleChangeAcademicBoard = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = AcadmicBoardList.find((option) => option.value === selectedValue)?.label || '';
        setAcademicBoard({ value: selectedValue, label: selectedLabel });
    };

    const handleChangeAcademicGroup = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = AcadmicGroupList.find((option) => option.value === selectedValue)?.label || '';
        setAcademicGroup({ value: selectedValue, label: selectedLabel });
    };

    const handleChangeAcademicStraeam = (event) => {
        console.log(event, "sdfsfsdfsfdsf111111")
        const selectedValue = event.target.value;
        const selectedLabel = AcadmicStreamsList.find((option) => option.value === selectedValue)?.label || '';
        setAcademicStream({ value: selectedValue, label: selectedLabel });
    };






    const handleChangeAcademicSubject = (_, newValue) => {
        const uniqueValues = newValue?.filter((value, index, self) =>
            index === self.findIndex((v) => v.label === value.label)
        );
        setAcademicSubject(uniqueValues)
    };

    const handleChangeAcademicSubSubject = (_, newValue) => {
        const uniqueValues = newValue.filter((value, index, self) =>
            index === self.findIndex((v) => v.label === value.label)
        );

        setAcademicSubSubject(uniqueValues);
        setDisableBtn(false);
    };



    const [selectedGameType, setselectedGameType] = useState(null);
    console.log(selectedGameType, "selectedGameType");

    const CreateUservsSystemGame = (event) => {

        // event.preventDefault()
        if (userType === "uservssystem") {
            if (ExamType.label === "Entrance") {
                const payload = {
                    "exam_type_uuid": ExamType.value,
                    "level_uuid": educationLevel.value,
                    "board_type": BoardType.label,
                    "state": stateName.label,
                    "entrance_exam_type_uuid": ETExamName.value,
                    "entrance_exam_uuid": ETExam.value,
                    "entrance_sub_paper_uuid": ETExamSubPaper.map(item => item.value),
                    "type_uuid": selectedGameType.uuid,
                    "method_uuid": quizMethod
                }

                dispatch(GameAddURL(payload, userData.data.token))
                setSuc(true)
            }
            else if (ExamType.label === "Competitive") {
                const payload = {
                    "exam_type_uuid": ExamType.value,
                    "level_uuid": educationLevel.value,
                    "board_type": BoardType.label,
                    "state": stateName.label,
                    "competitive_exam_type_uuid": CompatitiveExamType.value,
                    "competitive_exam_uuid": CompatitiveExam.value,
                    "competitive_sub_paper_uuid": compatitiveSubPaper.map(item => item.value),
                    "type_uuid": selectedGameType.uuid,
                    "method_uuid": quizMethod
                }
                dispatch(GameAddURL(payload, userData.data.token))
                setSuc(true)

            }
            else if (ExamType.label === "General Knowledge") {

                console.log("General KnowledgeGeneral Knowledge");
                const GkTopicId = selectedGKTopic.map(item => {
                    return item.value
                })
                const GkSubTopicTopicId = selectedSubTopic.map(item => {
                    return item.value
                })

                const payload = {
                    "exam_type_uuid": ExamType.value,
                    "type_uuid": selectedGameType.uuid,
                    "method_uuid": quizMethod,
                    "topic_uuid": GkTopicId,
                    "sub_topic_uuid": GkSubTopicTopicId,

                }
                dispatch(GameAddURL(payload, userData.data.token))
                setSuc(true)

            }
            else if (ExamType.label === "Academic") {
                const payload = {
                    "exam_type_uuid": ExamType.value,
                    "level_uuid": educationLevel.value,
                    "board_type": BoardType.label,
                    "state": stateName.label,
                    "academic_board_uuid": academicBoard.value,
                    "academic_stream_uuid": academicStream.value,
                    "academic_group_uuid": academicGroup.value,
                    "academic_year": academicYear.value,
                    "academic_semister": academicSemester.value,
                    "academic_subject_uuid": academicSubject.map(item => item.value),
                    "academic_sub_subject_uuid": academicSubSubject.map(item => item.value),
                    "type_uuid": selectedGameType.uuid,
                    "method_uuid": quizMethod
                }

                dispatch(GameAddURL(payload, userData.data.token))
                setSuc(true)

            }

        }
        // else {

        //     if (joinedUserCount?.playercount === 0 || joinedUserCount === 0) {

        //         if (ExamType.label === "Entrance") {
        //             const payload = {
        //                 "exam_type_uuid": ExamType.value,
        //                 "level_uuid": educationLevel.value,
        //                 "board_type": BoardType.label,
        //                 "state": stateName.label,
        //                 "entrance_exam_type_uuid": ETExamName.value,
        //                 "entrance_exam_uuid": ETExam.value,
        //                 "entrance_sub_paper_uuid": ETExamSubPaper.map(item => item.value),
        //                 "type_uuid": selectedGameType.uuid,
        //                 "method_uuid": quizMethod
        //             }

        //             dispatch(GameAddURL(payload, userData.data.token))
        //             setSuc(true)
        //         }
        //         else if (ExamType.label === "Competitive") {
        //             const payload = {
        //                 "exam_type_uuid": ExamType.value,
        //                 "level_uuid": educationLevel.value,
        //                 "board_type": BoardType.label,
        //                 "state": stateName.label,
        //                 "competitive_exam_type_uuid": CompatitiveExamType.value,
        //                 "competitive_exam_uuid": CompatitiveExam.value,
        //                 "competitive_sub_paper_uuid": compatitiveSubPaper.map(item => item.value),
        //                 "type_uuid": selectedGameType.uuid,
        //                 "method_uuid": quizMethod
        //             }
        //             dispatch(GameAddURL(payload, userData.data.token))
        //             setSuc(true)

        //         }
        //         else if (ExamType.label === "General Knowledge") {

        //             console.log("General KnowledgeGeneral Knowledge");
        //             const GkTopicId = selectedGKTopic.map(item => {
        //                 return item.value
        //             })
        //             const GkSubTopicTopicId = selectedSubTopic.map(item => {
        //                 return item.value
        //             })

        //             const payload = {
        //                 "exam_type_uuid": ExamType.value,
        //                 "type_uuid": selectedGameType.uuid,
        //                 "method_uuid": quizMethod,
        //                 "topic_uuid": GkTopicId,
        //                 "sub_topic_uuid": GkSubTopicTopicId,

        //             }
        //             dispatch(GameAddURL(payload, userData.data.token))
        //             setSuc(true)

        //         }
        //         else if (ExamType.label === "Academic") {
        //             const payload = {
        //                 "exam_type_uuid": ExamType.value,
        //                 "level_uuid": educationLevel.value,
        //                 "board_type": BoardType.label,
        //                 "state": stateName.label,
        //                 "academic_board_uuid": academicBoard.value,
        //                 "academic_stream_uuid": academicStream.value,
        //                 "academic_group_uuid": academicGroup.value,
        //                 "academic_year": academicYear.value,
        //                 "academic_semister": academicSemester.value,
        //                 "academic_subject_uuid": academicSubject.map(item => item.value),
        //                 "academic_sub_subject_uuid": academicSubSubject.map(item => item.value),
        //                 "type_uuid": selectedGameType.uuid,
        //                 "method_uuid": quizMethod
        //             }

        //             dispatch(GameAddURL(payload, userData.data.token))
        //             setSuc(true)

        //         }

        //     } else {
        //         const payload = {
        //             "game_uuid": joinedUserCount?.gameUuid,
        //             "player_uuid": playeruuid
        //         }
        //         dispatch(GameUpdateURL(payload, userData.data.token))
        //         setSuc(true)

        //     }
        // }
    }


    // user vs All users function 

    // const CreateUservsUserGame = () => {

    //     if (ExamType.label === "Entrance") {
    //         const payload = {
    //             "exam_type_uuid": ExamType.value,
    //             "level_uuid": educationLevel.value,
    //             "board_type": BoardType.label,
    //             "state": stateName.label,
    //             "entrance_exam_type_uuid": ETExamName.value,
    //             "entrance_exam_uuid": ETExam.value,
    //             "entrance_sub_paper_uuid": ETExamSubPaper.map(item => item.value),
    //             "type_uuid": selectedGameType.uuid,
    //             "method_uuid": quizMethod
    //         }

    //         dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
    //         setSuc(true)
    //     }
    //     else if (ExamType.label === "Competitive") {
    //         const payload = {
    //             "exam_type_uuid": ExamType.value,
    //             "level_uuid": educationLevel.value,
    //             "board_type": BoardType.label,
    //             "state": stateName.label,
    //             "competitive_exam_type_uuid": CompatitiveExamType.value,
    //             "competitive_exam_uuid": CompatitiveExam.value,
    //             "competitive_sub_paper_uuid": compatitiveSubPaper.map(item => item.value),
    //             "type_uuid": selectedGameType.uuid,
    //             "method_uuid": quizMethod
    //         }
    //         dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
    //         setSuc(true)

    //     }
    //     else if (ExamType.label === "General Knowledge") {

    //         console.log("General KnowledgeGeneral Knowledge");
    //         const GkTopicId = selectedGKTopic.map(item => {
    //             return item.value
    //         })
    //         const GkSubTopicTopicId = selectedSubTopic.map(item => {
    //             return item.value
    //         })

    //         const payload = {
    //             "exam_type_uuid": ExamType.value,
    //             "type_uuid": selectedGameType.uuid,
    //             "method_uuid": quizMethod,
    //             "topic_uuid": GkTopicId,
    //             "sub_topic_uuid": GkSubTopicTopicId,

    //         }
    //         dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
    //         setSuc(true)

    //     }
    //     else if (ExamType.label === "Academic") {
    //         const payload = {
    //             "exam_type_uuid": ExamType.value,
    //             "level_uuid": educationLevel.value,
    //             "board_type": BoardType.label,
    //             "state": stateName.label,
    //             "academic_board_uuid": academicBoard.value,
    //             "academic_stream_uuid": academicStream.value,
    //             "academic_group_uuid": academicGroup.value,
    //             "academic_year": academicYear.value,
    //             "academic_semister": academicSemester.value,
    //             "academic_subject_uuid": academicSubject.map(item => item.value),
    //             "academic_sub_subject_uuid": academicSubSubject.map(item => item.value),
    //             "type_uuid": selectedGameType.uuid,
    //             "method_uuid": quizMethod
    //         }

    //         dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
    //         setSuc(true)

    //     }

    // }

    const RedirectToUserInstruction = () => {
        if (userType === "uservsuser" || userType === "uservs2user" || userType === "uservs4user") {

            const GkTopicId = selectedGKTopic.map(item => {
                return item.value
            })
            const GkSubTopicTopicId = selectedSubTopic.map(item => {
                return item.value
            })

            history.push(({
                pathname: "/game-instruction-uservsalluser",
                state: {
                    //entrance
                    userType,
                    exam_type: ExamType.label,
                    exam_type_uuid: ExamType.value,
                    level_uuid: educationLevel.value,
                    board_type: BoardType.label,
                    state: stateName.label,
                    entrance_exam_type_uuid: ETExamName.value,
                    entrance_exam_uuid: ETExam.value,
                    entrance_sub_paper_uuid: ETExamSubPaper.map(item => item.value),
                    type_uuid: selectedGameType.uuid,
                    method_uuid: quizMethod,
                    //competitive
                    competitive_exam_type_uuid: CompatitiveExamType.value,
                    competitive_exam_uuid: CompatitiveExam.value,
                    competitive_sub_paper_uuid: compatitiveSubPaper.map(item => item.value),
                    //GK
                    topic_uuid: GkTopicId,
                    sub_topic_uuid: GkSubTopicTopicId,
                    //academic
                    academic_board_uuid: academicBoard.value,
                    academic_stream_uuid: academicStream.value,
                    academic_group_uuid: academicGroup.value,
                    academic_year: academicYear.value,
                    academic_semister: academicSemester.value,
                    academic_subject_uuid: academicSubject.map(item => item.value),
                    academic_sub_subject_uuid: academicSubSubject.map(item => item.value),
                }
            }));
        }
    }





    useEffect(() => {
        if (suc === true) {
            if (notification.status === true) {
                // toast.success(notification.message, {
                //   position: "top-right",
                // })
                setSuc(false)
                setTimeout(() => {
                    history.push(({
                        pathname: "/game-instruction",

                    }));
                }, 1000)
            }
            else if (notification.status === false) {
                // toast.error(notification.message)
                setSuc(false)
            }
        }

    }, [notification])





    // const [selectedGameType, setselectedGameType] = useState(null);
    console.log(selectedGameType, "selectedGameType");

    const handleCardClick = (card) => {
        setselectedGameType(card);
    };


    // game type list api 
    useEffect(() => {
        dispatch(GameTypeGetURL())

    }, [])

    useEffect(() => {
        //  if(selectedGameType){
        dispatch(QuizMethodListGetURL(selectedGameType && selectedGameType.uuid))
        //  }

    }, [selectedGameType])




    const [logs, setLogs] = useState([]);

    console.log(onlineUserCount, joinedUserCount, "onlineUserCount");

    useEffect(() => {
        if (playeruuid) {

            const socket = io(host, {
                // path: '/pathToConnection',
                transports: ['websocket'],
                upgrade: false,
                reconnection: true,
                rejectUnauthorized: false
            });

            socket.on('connect', () => {
                console.log('Connected to the server');
            });
            socket.on('onlineUsers', (count) => {
                console.log('Received online user count:', count);
                setOnlineUserCount(count);

            });

            return () => {
                if (socket) {
                    socket.disconnect();
                }
            };
        }

    }, [playeruuid]);

    // useEffect(() => {
    //     console.log(selectedGKTopic, "selectedGKTopic")
    //     if (ExamType.label === "General Knowledge") {
    //         const queryParams = {
    //             userId: playeruuid ? playeruuid : '',
    //             typeId: selectedGameType?.uuid ? selectedGameType?.uuid : '',
    //             methodId: quizMethod,
    //             // game_type: "EXAM",
    //             topicId: selectedGKTopic?.length > 0 ? selectedGKTopic?.map((item) => {
    //                 const id = []
    //                 id.push(item.value)
    //                 return id
    //             }) : [],
    //             subtopicId: selectedSubTopic?.length > 0 ? selectedSubTopic?.map((item) => {
    //                 const id = []
    //                 id.push(item.value)
    //                 return id
    //             }) : [],
    //             exam_type_uuid: ExamType.value ? ExamType.value : ''
    //         };
    //         console.log(queryParams, "queryParams");

    //         // const queryParams1 = { userId: "PR-3E94FA7B",
    //         //  gameId : "GID-777A4B9E",
    //         //   methodId : "CM-3CCF6A99",
    //         //    typeId : "CT-89C963FF",
    //         // exam_typeId : "ET-A76284AA",
    //         //  level_uuid : "",
    //         //  competitive_exam_type_uuid : "",
    //         //   competitive_exam_uuid : "",
    //         //   competitive_sub_paperId : "",
    //         //   academic_board_uuid: "",
    //         // academic_stream_uuid : "",
    //         //  academic_group_uuid : "",
    //         //   academic_year : "",
    //         //   academic_semister : "",
    //         //   academic_subject_uuid : "",
    //         // academic_sub_subject_uuid : "",
    //         // entrance_exam_type_uuid : "",
    //         // entrance_exam_uuid : "",
    //         // entrance_sub_paper_uuid : "",
    //         // topicId : "TP-A1DB6606",
    //         //  subtopicId : "STP-9A4CD949"
    //         // };

    //         const socket = io(host, {
    //             path: '/pathToConnection',
    //             transports: ['websocket'],
    //             upgrade: false,
    //             query: queryParams,
    //             reconnection: true,
    //             rejectUnauthorized: false
    //         });

    //         socket.on('connect', () => {
    //             console.log('Connected to the server');
    //         });
    //         socket.on('onlineusercount', (count) => {
    //             console.log('Received online user count:', count);
    //             setOnlineUserCount(count);

    //         });
    //         socket.on('receiveplayers', (count) => {
    //             console.log('Received Player:', count);
    //         });
    //         // if (playeruuid !== GameListData?.data?.created_by_uuid) {
    //         socket.on('receiveplayercount', (count) => {
    //             console.log('Received player user count:', count);
    //             setJoinedUserCount(count);
    //         });
    //         // }

    //         return () => {
    //             if (socket) {
    //                 socket.disconnect();
    //             }
    //         };
    //     }

    // }, [playeruuid, selectedGameType, quizMethod, selectedGKTopic, selectedSubTopic, ExamType]);

    // useEffect(() => {
    //     if (ExamType.label === "Entrance") {
    //         const queryParams = {
    //             userId: playeruuid ? playeruuid : '',
    //             exam_type_uuid: ExamType?.value ? ExamType?.value : '',
    //             level_uuid: educationLevel?.value ? educationLevel?.value : "",
    //             board_type: BoardType?.label,
    //             state: stateName?.label,
    //             entrance_exam_type_uuid: ETExamName?.value ? ETExamName?.value : "",
    //             entrance_exam_uuid: ETExam?.value ? ETExam?.value : "",
    //             entrance_sub_paper_uuid: ETExamSubPaper?.length > 0 ? ETExamSubPaper?.map((item) => {
    //                 const id = []
    //                 id.push(item.value)
    //                 return id
    //             }) : [],
    //             methodId: quizMethod,
    //             typeId: selectedGameType?.uuid ? selectedGameType?.uuid : '',
    //         };
    //         console.log(queryParams, "queryParams");

    //         const socket = io(host, {
    //             path: '/pathToConnection',
    //             transports: ['websocket'],
    //             upgrade: false,
    //             query: queryParams,
    //             reconnection: true,
    //             rejectUnauthorized: false
    //         });

    //         socket.on('connect', () => {
    //             console.log('Connected to the server');
    //         });
    //         socket.on('onlineusercount', (count) => {
    //             console.log('Received online user count:', count);
    //             setOnlineUserCount(count);

    //         });
    //         socket.on('receiveplayers', (count) => {
    //             console.log('Received Player:', count);
    //         });
    //         socket.on('receiveplayercount', (count) => {
    //             console.log('Received player user count:', count);
    //             setJoinedUserCount(count);
    //         });


    //         return () => {
    //             if (socket) {
    //                 socket.disconnect();
    //             }
    //         };
    //     }
    // }, [playeruuid, selectedGameType, quizMethod, ExamType, ETExamSubPaper, ETExam, educationLevel, BoardType, stateName, ETExamName])

    // useEffect(() => {

    //     if (ExamType.label === "Competitive") {
    //         const queryParams = {

    //             userId: playeruuid ? playeruuid : '',
    //             exam_type_uuid: ExamType?.value ? ExamType?.value : '',
    //             level_uuid: educationLevel?.value ? educationLevel?.value : "",
    //             board_type: BoardType?.label,
    //             state: stateName?.label,
    //             competitive_exam_type_uuid: CompatitiveExamType?.value ? CompatitiveExamType?.value : "",
    //             competitive_exam_uuid: CompatitiveExam?.value ? CompatitiveExam?.value : "",
    //             competitive_sub_paperId: compatitiveSubPaper?.length > 0 ? compatitiveSubPaper?.map((item) => {
    //                 const id = []
    //                 id.push(item.value)
    //                 return id
    //             }) : [],
    //             methodId: quizMethod,
    //             typeId: selectedGameType?.uuid ? selectedGameType?.uuid : '',
    //         };
    //         console.log(queryParams, "queryParams");

    //         const socket = io(host, {
    //             path: '/pathToConnection',
    //             transports: ['websocket'],
    //             upgrade: false,
    //             query: queryParams,
    //             reconnection: true,
    //             rejectUnauthorized: false
    //         });

    //         socket.on('connect', () => {
    //             console.log('Connected to the server');
    //         });
    //         socket.on('onlineusercount', (count) => {
    //             console.log('Received online user count:', count);
    //             setOnlineUserCount(count);

    //         });
    //         socket.on('receiveplayers', (count) => {
    //             console.log('Received Player:', count);
    //         });
    //         socket.on('receiveplayercount', (count) => {
    //             console.log('Received player user count:', count);
    //             setJoinedUserCount(count);
    //         });


    //         return () => {
    //             if (socket) {
    //                 socket.disconnect();
    //             }
    //         };
    //     }

    // }, [playeruuid, ExamType, educationLevel, BoardType, stateName, CompatitiveExamType, CompatitiveExam, compatitiveSubPaper, quizMethod, selectedGameType])

    // useEffect(() => {
    //     if (ExamType.label === "Academic") {

    //         const queryParams = {

    //             exam_type_uuid: ExamType?.value ? ExamType?.value : "",
    //             level_uuid: educationLevel?.value ? educationLevel?.value : "",
    //             board_type: BoardType?.label,
    //             state: stateName?.label,
    //             academic_board_uuid: academicBoard?.value ? academicBoard?.value : "",
    //             academic_stream_uuid: academicStream?.value ? academicStream?.value : "",
    //             academic_group_uuid: academicGroup?.value ? academicGroup?.value : "",
    //             academic_year: academicYear?.value ? academicYear?.value : "",
    //             academic_semister: academicSemester?.value ? academicSemester?.value : "",
    //             academic_subject_uuid: academicSubject?.length > 0 ? academicSubject?.map((item) => {
    //                 const id = []
    //                 id.push(item.value)
    //                 return id
    //             }) : [],
    //             academic_sub_subject_uuid: academicSubSubject?.length > 0 ? academicSubSubject?.map((item) => {
    //                 const id = []
    //                 id.push(item.value)
    //                 return id
    //             }) : [],
    //             typeId: selectedGameType ? selectedGameType?.uuid : "",
    //             methodId: quizMethod,
    //         };
    //         console.log(queryParams, "queryParams");

    //         const socket = io(host, {
    //             path: '/pathToConnection',
    //             transports: ['websocket'],
    //             upgrade: false,
    //             query: queryParams,
    //             reconnection: true,
    //             rejectUnauthorized: false
    //         });

    //         socket.on('connect', () => {
    //             console.log('Connected to the server');
    //         });
    //         socket.on('onlineusercount', (count) => {
    //             console.log('Received online user count:', count);
    //             setOnlineUserCount(count);

    //         });
    //         socket.on('receiveplayers', (count) => {
    //             console.log('Received Player:', count);
    //         });
    //         socket.on('receiveplayercount', (count) => {
    //             console.log('Received player user count:', count);
    //             setJoinedUserCount(count);
    //         });


    //         return () => {
    //             if (socket) {
    //                 socket.disconnect();
    //             }
    //         };
    //     }
    // }, [ExamType, educationLevel, BoardType, stateName, academicBoard, academicStream, academicGroup, academicYear, academicSemester, academicSubject, selectedGameType, quizMethod])

    const year = [
        { value: "1st Year", label: "1st Year" },
        { value: "2nd Year", label: "2nd Year" },
        { value: "3rd Year", label: "3rd Year" },
        { value: "4th Year", label: "4th Year" },
    ]
    const sem = [
        { value: "1st sem", label: "1st sem" },
        { value: "2nd sem", label: "2nd sem" },
        { value: "3rd sem", label: "3rd sem" },
        { value: "4th sem", label: "4th sem" },
        { value: "5th sem", label: "5th sem" },
        { value: "6th sem", label: "6th sem" },
        { value: "7th sem", label: "7th sem" },
        { value: "8th sem", label: "8th sem" },

    ]


    const handleChangeAcadmecYear = (event) => {
        setacademicYear({ value: event.target.value, label: event.target.value });
    };
    const handleChangeAcadmecSemester = (event) => {
        setacademicSemester({ value: event.target.value, label: event.target.value });
    };



    // if (joinedUserCount==1){
    //     const gameUpdate = () => {
    //         const payload = {
    //             "game_uuid": "GID-6A3C3DCC",
    //             "player_uuid": "PR-96649BE2"
    //         }

    //         dispatch(GameUpdateURL(payload, userData.data.token))
    //     }
    // }



    return (
        <>
            <div>
                <Grid item container className='homeContainer'>
                    <Header />
                    <Grid md={6} xs={12} sx={{ minWidth: 120, background: "white", padding: "20px", borderRadius: "10px" }} className='landingdropdown' order={{ md: 1, xs: 2 }}>
                        <Box sx={{ minWidth: 120, padding: "20px", }} >
                            <Grid align="center">
                                <h1>Let's Play!</h1>
                            </Grid>


                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">Select Your Exam Type</InputLabel>
                                <Select
                                    value={ExamType.value}
                                    label="Select Your Exam Type"
                                    onChange={handleChangeExamType}
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    {CategoryList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Box>

                        {ExamType.label === "General Knowledge" || ExamType.label === '' ?
                            null
                            :
                            <>
                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">Select Education Level</InputLabel>
                                        <Select
                                            value={educationLevel.value}
                                            label="Select Education Level"
                                            onChange={handleChangeEducationLevel}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {EducationLevelList.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                </Box>

                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">Select Board Type</InputLabel>
                                        <Select
                                            value={BoardType.value}
                                            label="Select Board Type"
                                            onChange={handleChangeBoardType}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {BoardOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {BoardType.value === "state" ?
                                    <Box sx={{ minWidth: 120, padding: "20px", }} >
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Select State name</InputLabel>
                                            <Select
                                                value={stateName.value}
                                                label="Select State name"
                                                onChange={handleChangeStateName}
                                            >
                                                <MenuItem value="">
                                                </MenuItem>
                                                {StateNameList.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Box>
                                    :
                                    null}
                            </>


                        }

                        {
                            ExamType.label === "Entrance" ?
                                <>

                                    <Box sx={{ minWidth: 120, padding: "20px", }} >
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Select Exam Name </InputLabel>
                                            <Select
                                                value={ETExamName.value}
                                                label="Select Exam Type"
                                                onChange={handleChangeETExamName}
                                            >
                                                <MenuItem value="">
                                                </MenuItem>
                                                {ETExamTypeList.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Box>

                                    <Box sx={{ minWidth: 120, padding: "20px", }} >
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Select Exam</InputLabel>
                                            <Select
                                                value={ETExam.value}
                                                label="Select Exam"
                                                onChange={handleChangeETExam}
                                            >
                                                <MenuItem value="">
                                                </MenuItem>
                                                {ETExamList.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Box>

                                    <Box sx={{ minWidth: 120, padding: "20px", }} >


                                        <Autocomplete
                                            multiple
                                            options={ETEamSubPpaerList}
                                            getOptionLabel={(option) => option.label}
                                            value={ETExamSubPaper}
                                            onChange={handleChangeETExamSubPaper}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Sub Paper"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {/* <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Select Exam</InputLabel>
                                            <Select
                                                value={ETExamSubPaper.value}
                                                label="Select Exam"
                                                onChange={handleChangeETExamSubPaper}
                                            >
                                                <MenuItem value="">
                                                </MenuItem>
                                                {ETEamSubPpaerList.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl> */}
                                    </Box>





                                </>


                                : ExamType.label === "Competitive" ?
                                    <>


                                        <Box sx={{ minWidth: 120, padding: "20px", }} >
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Select Exam Type </InputLabel>
                                                <Select
                                                    value={CompatitiveExamType.value}
                                                    label="Select Exam Type"
                                                    onChange={handleChangeCompatitiveExamType}
                                                >
                                                    <MenuItem value="">
                                                    </MenuItem>
                                                    {CompetitiveExamTypeList.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </Box>
                                        <Box sx={{ minWidth: 120, padding: "20px", }} >
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Select Exam  </InputLabel>
                                                <Select
                                                    value={CompatitiveExam.value}
                                                    label="Select Exam "
                                                    onChange={handleChangeCompatitiveExam}
                                                >
                                                    <MenuItem value="">
                                                    </MenuItem>
                                                    {CompetitiveExamList.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </Box>
                                        <Box sx={{ minWidth: 120, padding: "20px", }} >

                                            <Autocomplete
                                                multiple
                                                options={CompetitiveSubPpaperList}
                                                getOptionLabel={(option) => option.label}
                                                value={compatitiveSubPaper}
                                                onChange={handleChangeCompatitiveSubPpaer}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Sub Paper"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {/* <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Select Sub Paper  </InputLabel>
                                                <Select
                                                    value={compatitiveSubPaper.value}
                                                    label="Select Sub Paper "
                                                    onChange={handleChangeCompatitiveSubPpaer}
                                                >
                                                    <MenuItem value="">
                                                    </MenuItem>
                                                    {CompetitiveSubPpaperList.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl> */}
                                        </Box>

                                    </>
                                    : ExamType.label === "General Knowledge" ?
                                        <>
                                            <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                <Autocomplete
                                                    multiple
                                                    options={GKTopicList}
                                                    getOptionLabel={(option) => option.label}
                                                    value={selectedGKTopic}
                                                    onChange={handleChangeGKTopic}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Topic"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                            </Box>
                                            <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                <Autocomplete
                                                    multiple
                                                    options={GKSubTopicList}
                                                    getOptionLabel={(option) => option.label}
                                                    value={selectedSubTopic}
                                                    onChange={handleChangeGKsubTopic}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Sub-Topic"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                            </Box>



                                        </>
                                        : ExamType.label === "Academic" ?

                                            <>

                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Select Board / University </InputLabel>
                                                        <Select
                                                            value={academicBoard.value}
                                                            label="Select Board / University"
                                                            onChange={handleChangeAcademicBoard}
                                                        >
                                                            <MenuItem value="">
                                                            </MenuItem>
                                                            {AcadmicBoardList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </Box>

                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Select Stream </InputLabel>
                                                        <Select
                                                            value={academicStream.value}
                                                            label="Select Stream"
                                                            onChange={handleChangeAcademicStraeam}
                                                        >
                                                            <MenuItem value="">
                                                            </MenuItem>
                                                            {AcadmicStreamsList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </Box>


                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Select Group </InputLabel>
                                                        <Select
                                                            value={academicGroup.value}
                                                            label="Select Group"
                                                            onChange={handleChangeAcademicGroup}
                                                        >
                                                            <MenuItem value="">
                                                            </MenuItem>
                                                            {AcadmicGroupList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </Box>
                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
                                                        <Select
                                                            value={academicYear.value}
                                                            label="Select Year"
                                                            onChange={handleChangeAcadmecYear}
                                                        >
                                                            <MenuItem value="">
                                                            </MenuItem>
                                                            {year.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </Box>
                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Select Semester </InputLabel>
                                                        <Select
                                                            value={academicSemester.value}
                                                            label="Select Semester"
                                                            onChange={handleChangeAcadmecSemester}
                                                        >
                                                            <MenuItem value="">
                                                            </MenuItem>
                                                            {sem.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </Box>


                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <Autocomplete
                                                        multiple
                                                        options={AcadmicSubjectList}
                                                        getOptionLabel={(option) => option.label}
                                                        value={academicSubject}
                                                        onChange={handleChangeAcademicSubject}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Subject"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />

                                                </Box>

                                                <Box sx={{ minWidth: 120, padding: "20px", }} >
                                                    <Autocomplete
                                                        multiple
                                                        options={AcadmicSubSubjectList}
                                                        getOptionLabel={(option) => option.label}
                                                        value={academicSubSubject}
                                                        onChange={handleChangeAcademicSubSubject}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Sub-Subject"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />

                                                </Box>
                                            </>
                                            :
                                            null

                        }

                        <Grid align="center">
                            {/* <Button onClick={disableBtn ? null : handleClickOpen} variant="contained" style={{ cursor: disableBtn ? "not-allowed" : "pointer" }} >Start</Button> */}
                            <Button onClick={handleClickOpen} variant="contained" disabled={disableBtn}>Start</Button>
                        </Grid>


                    </Grid>
                    <Grid md={6} xs={12} align="center" className='order-1' order={{ md: 2, xs: 1 }}>

                        <img src={intro} style={{ width: "90%" }} />
                    </Grid>

                </Grid>
                <HomeStatic />
                <Contact />
                <Footer />
            </div>



            {/* =========================== */}
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="xl" // Add this line
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Choose Your Game"}
                    </DialogTitle>
                    <DialogTitle style={{ marginLeft: "90%" }}>
                        <Button variant="contained" onClick={handleClose}>Close</Button>
                    </DialogTitle>
                    <Grid item container style={{ width: "100%" }}>
                        {GameTypeData && GameTypeData.data && GameTypeData.data.map((card) => {
                            return <>
                                <Grid md={3} align="center"  >
                                    <Card onClick={() => handleCardClick(card)}
                                        style={{
                                            margin: "5px",
                                            background: (selectedGameType && selectedGameType._id === card._id ? "green" : null),
                                            color: (selectedGameType && selectedGameType._id === card._id ? "White" : null)
                                        }} >
                                        <CardActionArea>
                                            <div>
                                                <p>{card.name}</p>
                                                <img src={examIcon} style={{ width: "100%" }} />
                                            </div>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </>

                        })}



                    </Grid>

                    <hr style={{ width: "100%" }} />


                    {/* <hr/> */}
                    <Grid item container style={{ width: "100%" }}>
                        <Grid md={12} align="center">
                            <div>
                                <p>  <img src={player} style={{ width: "8%" }} />  Players Online- {onlineUserCount}</p>

                            </div>
                        </Grid>
                        <Grid md={12} align="center">
                            {QuizMethodData && QuizMethodData.data && QuizMethodData.data ?


                                <>

                                    <Box
                                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                                    >
                                        <Tabs
                                            orientation="vertical"
                                            variant="scrollable"
                                            value={value}
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs example"
                                            sx={{ borderRight: 1, borderColor: 'divider', color: "red" }}
                                            style={{ width: "20%" }}
                                        >
                                            {QuizMethodData && QuizMethodData.data && QuizMethodData.data.map((item, index) => {
                                                { console.log(item, "sdfsdf") }
                                                return <Tab
                                                    label={`${item.name}`}
                                                    {...a11yProps(index)}
                                                    onClick={() => eventHandler(item)} // Pass item data to the eventHandler

                                                />


                                            })}



                                        </Tabs>
                                        {quizMethod ?
                                            <>
                                                <TabPanel value={value} index={0} fullWidth maxWidth="lg" style={{ width: "100%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <table style={{ width: "100%" }}>
                                                            <tr>
                                                                {/* <th style={{color:"red"}}>First Name</th> */}
                                                                <th style={{ color: "white", background: "black" }}>#</th>
                                                                <th style={{ color: "white", background: "black" }}>Joined Players / Total Players</th>
                                                                <th style={{ color: "white", background: "black" }}>Action</th>

                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td></td>
                                                                <td>
                                                                    {/* <Link to="/game-instruction"> */}
                                                                    <Button onClick={() => { CreateUservsSystemGame("2") }} variant="contained">join</Button>
                                                                    {/* </Link> */}
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={value} index={1} style={{ width: "100%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <table style={{ width: "100%" }}>
                                                            <tr>

                                                                <th style={{ color: "white", background: "black" }}>#</th>
                                                                <th style={{ color: "white", background: "black" }}>Joined Players / Total Players</th>
                                                                <th style={{ color: "white", background: "black" }}>Action</th>

                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>{joinedUserCount?.playercount ? joinedUserCount.playercount : 0}/2</td>
                                                                <td>

                                                                    <Button onClick={() => { RedirectToUserInstruction("3") }} variant="contained">join</Button>

                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                    {/* user vs user */}
                                                </TabPanel>
                                                <TabPanel value={value} index={2} style={{ width: "100%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <table style={{ width: "100%" }}>
                                                            <tr>
                                                                {/* <th style={{color:"red"}}>First Name</th> */}
                                                                <th style={{ color: "white", background: "black" }}>#</th>
                                                                <th style={{ color: "white", background: "black" }}>Joined Players / Total Players</th>
                                                                <th style={{ color: "white", background: "black" }}>Action</th>

                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>0/3</td>
                                                                <td>
                                                                    {/* <Link to="/game-instruction">  */}
                                                                    <Button onClick={() => { RedirectToUserInstruction("4") }} variant="contained">join</Button>
                                                                    {/* </Link> */}
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                    {/* user vs 2 user */}
                                                </TabPanel>
                                                <TabPanel value={value} index={3} style={{ width: "100%" }}>

                                                    <div style={{ width: "100%" }}>
                                                        <table style={{ width: "100%" }}>
                                                            <tr>
                                                                {/* <th style={{color:"red"}}>First Name</th> */}
                                                                <th style={{ color: "white", background: "black" }}>#</th>
                                                                <th style={{ color: "white", background: "black" }}>Joined Players / Total Players</th>
                                                                <th style={{ color: "white", background: "black" }}>Action</th>

                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>0/5</td>
                                                                <td>
                                                                    {/* <Link to="/game-instruction"> */}
                                                                    <Button onClick={() => { RedirectToUserInstruction("5") }} variant="contained">join</Button>
                                                                    {/* </Link> */}
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                    {/* user vs 4 user */}
                                                </TabPanel>
                                            </>
                                            :
                                            null
                                        }


                                    </Box>
                                </>
                                :
                                null
                            }

                        </Grid>
                    </Grid>

                </Dialog>

                {showLoginForm && <Login isOpen={showLoginForm} onClose={handleLoginFormClose} />}
            </div>
        </>
    )
}

export default Home