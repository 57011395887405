import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import "./Home.css";
import intro from "../../Assets/intro-img.svg"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import About from "../../Assets/about-extra-1.svg"
import About2 from "../../Assets/about-extra-2.svg"
import About3 from "../../Assets/about-img.svg"
import Contact from '../ContactPage/Contact';
import { TopPlayerApi } from '../../Redux/Game/GameListRedux';

import SchoolIcon from '@mui/icons-material/School';
import { useDispatch, useSelector } from 'react-redux';

function HomeStatic() {

    const dispatch = useDispatch();

    const { TopplayerList } = useSelector((state) => state.GameList)

    useEffect(() => {
        dispatch(TopPlayerApi())
    }, [])

    return (
        <>
            <Grid Item Container style={{ padding: "100px" }} className='homeStatic'>
                <Grid md={12} align="center">
                    <h1>Subscription Plans</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <p> incididunt ut labore et dolore magna aliqua.
                    </p>
                </Grid>

            </Grid>



            <Grid container style={{ padding: "10px 150px 10px 150px" }} className='homeStaticCard'>
                <Grid xs={12} md={4} lg={4} style={{ padding: "20px" }} className='Cards'>
                    <Card >

                        <img src={intro} style={{ width: "100%" }} />
                        <CardContent>

                            <Grid align="left">
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                            </Grid>

                            <Typography variant="body2" color="text.secondary">
                                Subscription Amount 2000
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained">SUBSCRIBE</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid xs={12} md={4} lg={4} style={{ padding: "20px" }} className='Cards'>
                    <Card >

                        <img src={intro} style={{ width: "100%" }} />
                        <CardContent>

                            <Grid align="left">
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                            </Grid>

                            <Typography variant="body2" color="text.secondary">
                                Subscription Amount 2000
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained">SUBSCRIBE</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid xs={12} md={4} lg={4} style={{ padding: "20px" }} className='Cards'>
                    <Card >

                        <img src={intro} style={{ width: "100%" }} />

                        <CardContent>

                            <Grid align="left">
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Test
                                </Typography>
                            </Grid>

                            <Typography variant="body2" color="text.secondary">
                                Subscription Amount 2000
                            </Typography>
                        </CardContent>
                        <CardActions>

                            <Button variant="contained">SUBSCRIBE</Button>

                        </CardActions>
                    </Card>
                </Grid>

            </Grid>




            <Grid Item Container style={{ padding: "100px" }} className='homeStatic'>
                <Grid md={12} xs={12} align="center">
                    <h1>Playing Categories</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <p> incididunt ut labore et dolore magna aliqua.
                    </p>
                </Grid>

            </Grid>
            <Grid item container style={{ padding: "10px 150px 10px 150px" }} className='homeStatic'>
                <Grid md={6} align="left" order={{ xs: 1 }}>

                    <Grid item container >
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>School/College Exams</b></p>
                            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>Entrance Exams</b></p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>Competitive Exams</b></p>
                            <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>General Exams</b></p>
                            <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>

                        </Grid>

                    </Grid>

                </Grid>
                <Grid md={6} align="center" order={{ xs: 2 }}>

                    <img src={About3} style={{ width: "100%" }} />
                </Grid>

            </Grid>


            <Grid item container style={{ padding: "100px 150px 100px 150px" }} className='homeStatic'>

                <Grid md={6} align="center" order={{ xs: 4 }}>

                    <img src={About} style={{ width: "100%" }} />
                </Grid>
                <Grid md={6} align="left" order={{ xs: 3 }}>
                    <p className='subHeading'>
                        Competition Types
                    </p>

                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>QUIZ</b></p>
                            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>Exams</b></p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum

                            </p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>Rapid Fire</b></p>
                            <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b> Buzzer</b></p>
                            <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>

                        </Grid>

                    </Grid>

                </Grid>

            </Grid>


            <Grid item container style={{ padding: "10px 150px 10px 150px" }} className='homeStatic'>
                <Grid md={6} align="left">
                    <p className='subHeading'>Competition Methods</p>
                    <Grid item container >
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>User vs System</b></p>
                            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>User vs User </b></p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>User vs 2 Users</b></p>
                            <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>

                        </Grid>

                    </Grid>
                    <Grid item container >

                        <Grid md={2}>
                            <div className='circle'>
                                <SchoolIcon style={{ margin: "10px" }} fontSize='large' />
                            </div>
                        </Grid>
                        <Grid md={10}>
                            <p><b>User vs 4 Users</b></p>
                            <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>

                        </Grid>

                    </Grid>

                </Grid>
                <Grid md={6} align="center">

                    <img src={About2} style={{ width: "100%" }} />
                </Grid>

            </Grid>

            <Grid item container style={{ background: "#eeeeee", padding: "70px" }}>
                <Grid md={12} align="center" >
                    <h1 >Upcoming Events!</h1>
                    <p style={{ color: "red" }}>Please Join Before 5 Minutes</p>


                </Grid>

            </Grid>




            <Grid item container style={{ background: "#ecf5ff", padding: "50px", textAlign: "center" }}  >
                <Grid align="center" md={12} xs={12}>
                    <h1 align="center" style={{ textAlign: "center" }}>Top Players</h1>
                </Grid>
                {TopplayerList?.data?.length > 0 && TopplayerList?.data?.map((items) => {
                    return <Grid md={3} xs={12}>
                        <p>{items?.name}</p>
                        <p><b>{items?.coins}</b></p>
                    </Grid>
                })}
                {/* <Grid md={3} xs={12}>
                    <p>girishma</p>
                    <p><b>7114</b></p>
                </Grid>
                <Grid md={3} xs={12}>
                    <p>eja Kumar</p>
                    <p><b>2739</b></p>
                </Grid>
                <Grid md={3} xs={12}>
                    <p>Sainath Dekonda</p>
                    <p><b>2680</b></p>
                </Grid> */}

            </Grid>
        </>
    )
}

export default HomeStatic