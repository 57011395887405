import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    UpdateProfileData: {},
    notification:{}
};

const UpdateProfileSlice = createSlice({
  name: 'UpdateProfile',
  initialState,
  reducers: {
    setUpdateProfileData(state, action) {
      state.UpdateProfileData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setUpdateProfileData, setToast } = UpdateProfileSlice.actions;

export const UpdateProfileURL = (token,uuid,payload) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/participant/update/${uuid}`,payload,{headers:{
    "x-auth-token" : token
  }})
  .then((res) => {
    console.log(res, "asasASas")
  dispatch(setUpdateProfileData(res.data));
  dispatch(setToast({ status: true, message: res.data.message }))
})
.catch((err) => {
  console.log(err, "err")
  dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
})
 
};



export const CoinsDuductionURL = (payload,token) => async (dispatch) => {
  const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/participant/coins/dedected`,payload,{headers:{
    "x-auth-token" : token
  }})
  .then((res) => {
    console.log(res, "asasAsdfsdfsdfsffdsdfSas")
    dispatch(setToast({ status: true, message: res.data.message }))
})
.catch((err) => {
  console.log(err, "errasdasdasdasdasd")
  dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
})
 
};

const UpdateProfileReducer = UpdateProfileSlice.reducer;

export default UpdateProfileReducer;
