import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  ExamData: [],
  notification:{}
};

const ExamSlice = createSlice({
  name: 'Exam',
  initialState,
  reducers: {
    setExamData(state, action) {
      state.ExamData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setExamData, setToast } = ExamSlice.actions;


export const EntanceExamGetURL = (ETExamUuid,educationLevelUuid,BoardType,stateName, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/entrance/exam?entrance_exam_uuid=${ETExamUuid}&level_uuid=${educationLevelUuid}&board_type=${BoardType}&state=${stateName}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setExamData(response.data));
};





const EntranceExamReducer = ExamSlice.reducer;

export default EntranceExamReducer;
