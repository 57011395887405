import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
  GKTopicData: [],
  notification: {}
};

const GKTopicSlice = createSlice({
  name: 'G K Topic',
  initialState,
  reducers: {
    setGKTopicData(state, action) {
      state.GKTopicData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    },
  },
});

export const { setGKTopicData, setToast } = GKTopicSlice.actions;


export const GetGKTopicListURL = (token,catId) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/topics?exam_type_uuid=ET-A76284AA`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "dfghj")
  dispatch(setGKTopicData(response.data.data));
};


// export const CategoryAddURL = (payload,token) => async (dispatch) => {
//     const response = await axios.post(`${process.env.REACT_APP_URL}/category/create`,payload,{headers:{
//       "x-auth-token" : token
//     }}) .then((res) => {
//       console.log(res, "sdfsdfsdff")
//       dispatch(setToast({ status: true, message: res.data.message }))
//     })
//     .catch((err) => {
//       dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))

//     })
//   };

// export const CategoryUpdateURL = (uuid,payload, token) => async (dispatch) => {
//     const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/category/update/${uuid}`,payload,{headers:{
//       "x-auth-token" : token
//     }}) .then((res) => {
//       console.log(res, "sdfsdfsdff")
//       dispatch(setToast({ status: true, message: res.data.message }))
//     })
//     .catch((err) => {
//       dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))

//     })
   
//   };

  // export const  CategoryStatusUpdateURL = (payload, token) => async (dispatch) => {
  //   const response = await axios.put(`${process.env.REACT_APP_WEB_APP}/category/change/status`, payload, {
  //     headers: {
  //       "x-auth-token": token
  //     }
  //   }).then((res) => {
  //     console.log(res, "sdfsddffsdff")
  //     dispatch(setToast({ status: true, message: res.data.message }))
  //   })
  //     .catch((err) => {
  //       console.log(err && err.response,"hjgjghgjhghj")
  //       dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
  //     })
 
  // };


const GKTopicListReducer = GKTopicSlice.reducer;

export default GKTopicListReducer;
