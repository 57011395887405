import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from 'react-redux';
import "./gameInstruction.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Llogo from "../../../Assets/no1exam_logo.png";
import Button from "@mui/material/Button";
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import Header from '../../../Components/Header';
import { UserVsUserGameInstructionListURL } from "../../../Redux/GameInstruction/GameInstructionUserVsUserRedux";
import { UserVsAllUserCreateGame, UserVsAllUserCreateGameData } from "../../../Redux/Game/GameListRedux";
import { secureStorage } from "../../../Utils";
import { CoinsDuductionURL } from "../../../Redux/Game/Stats/UpdateProfileRedux";
import 'react-toastify/dist/ReactToastify.css';
import { GameReplyApi } from "../../../Redux/Game/GameListRedux";
import Swal from 'sweetalert2';
import axios from "axios";



function GameUsersInstruction(props) {
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();
  console.log(location, "location");

  let userData = secureStorage.getItem("currentUser")
  console.log(userData?.data?.data?.uuid, "userData")

  const { GameInstructionDataUserVsUser } = useSelector((state) => state.GameInstructionListUserVsUser)
  const { notification } = useSelector((state) => state.UpdateProfileList)
  // const { notification } = useSelector((state) => state.GameList)
  console.log(GameInstructionDataUserVsUser, "GameInstructionDataUserVsUser");


  useEffect(() => {
    dispatch(UserVsUserGameInstructionListURL(location?.state?.method_uuid))
  }, [])


  // const scorecardUpdate =()=>{
  //   dispatch(ScoreCardListURL(GameListData.data.uuid, GameListData.data.type_uuid, GameListData.data.method_uuid, userData?.data?.token))
  //   }

  // exam type list api

  const [suc, setSuc] = useState(false);


  // const ReplayGame = () => {
  //   const payload = {
  //     "game_uuid": GameListData?.data?.uuid,
  //     "participant_uuid": userData?.data?.data?.uuid
  //   }
  //   dispatch(ReplayGamePostURL(payload, userData.data.token))
  // }

  const CoinsDeductionApi = async () => {
    const payload = {
      "user_uuid": userData?.data?.data?.uuid,
      "type_uuid": location?.state?.type_uuid,

    }
    const res = await axios.put(`${process.env.REACT_APP_WEB_APP}/participant/coins/dedected`, payload)
      .then((res) => {
        console.log(res, "dfgvdfgvfddgfd")
        if (res) {
          Swal.fire({
            icon: 'success',
            title: (res?.data?.message),
          })
        }
        return res?.data;
      })
      .catch((err) => {
        if (err) {
          Swal.fire({
            icon: 'error',
            title: (err?.response?.data?.message),
          })
        }
        console.log(err)
      })
  }

  const CreateUservsUserGame = async (payload1, token1) => {
    const funData = async (payload, token) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_WEB_APP}/watingroom/create`, payload, {
          headers: {
            "x-auth-token": token
          }
        });
        dispatch(UserVsAllUserCreateGameData(res.data))
        return res.data;
      } catch (err) {
        console.error("Error creating game:", err);
        dispatch(UserVsAllUserCreateGameData({}))
        return {};
      }
    };
    const results = await funData(payload1, token1);
    console.log(results, "result");
    return results;
  }


  // const CoinDeduction = () => {

  //   const payload = {
  //     "user_uuid": userData?.data?.data?.uuid,
  //     "type_uuid": location?.state?.type_uuid,

  //   }
  //   dispatch(CoinsDuductionURL(payload, userData?.data?.token))
  //   setSuc(true)
  // }

  const CoinDeduction = () => {

    if (location?.stat === "Replay") {
      const payload = {
        "game_uuid": location?.state?.uuid,
        "participant_uuid": userData?.data?.data?.uuid
      }
      dispatch(GameReplyApi(payload, userData?.data?.token))
      setTimeout(() => {
        history.push(({
          pathname: "/Waiting-room",
          state: location?.state,
          stat: "Replay"
        }));
      }, 2000)
    }
    else {
      if (location?.state?.exam_type === "Entrance") {
        const payload = {
          "exam_type_uuid": location?.state?.exam_type_uuid,
          "level_uuid": location?.state?.level_uuid,
          "board_type": location?.state?.board_type,
          "state": location?.state?.state,
          "entrance_exam_type_uuid": location?.state?.entrance_exam_type_uuid,
          "entrance_exam_uuid": location?.state?.entrance_exam_uuid,
          "entrance_sub_paper_uuid": location?.state?.entrance_sub_paper_uuid,
          "type_uuid": location?.state?.type_uuid,
          "method_uuid": location?.state?.method_uuid
        }
        CoinsDeductionApi().then((res) => {
          CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
            if (res) {
              history.push(({
                // pathname: "/questions",
                pathname: "/Waiting-room",
                state: location?.state
              }));
            }
          })
            .catch((err) => {
              console.log(err, "error")
              if (err?.response?.data?.hit_again) {
                CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
                  if (res) {
                    history.push(({
                      // pathname: "/questions",
                      pathname: "/Waiting-room",
                      state: location?.state
                    }));
                  }
                })
                  .catch((err) => {
                    console.log(err, "err")
                  })
              }
            })
        })
        // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
        // setSuc(true)
      }
      else if (location?.state?.exam_type === "Competitive") {
        const payload = {
          "exam_type_uuid": location?.state?.exam_type_uuid,
          "level_uuid": location?.state?.level_uuid,
          "board_type": location?.state?.board_type,
          "state": location?.state?.state,
          "competitive_exam_type_uuid": location?.state?.competitive_exam_type_uuid,
          "competitive_exam_uuid": location?.state?.competitive_exam_uuid,
          "competitive_sub_paper_uuid": location?.state?.competitive_sub_paper_uuid,
          "type_uuid": location?.state?.type_uuid,
          "method_uuid": location?.state?.method_uuid
        }
        // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
        // setSuc(true)
        CoinsDeductionApi().then((res) => {
          CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
            if (res) {
              history.push(({
                // pathname: "/questions",
                pathname: "/Waiting-room",
                state: location?.state
              }));
            }
          })
            .catch((err) => {
              console.log(err, "error")
              if (err?.response?.data?.hit_again) {
                CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
                  if (res) {
                    history.push(({
                      // pathname: "/questions",
                      pathname: "/Waiting-room",
                      state: location?.state
                    }));
                  }
                })
                  .catch((err) => {
                    console.log(err, "err")
                  })
              }
            })
        })
      }
      else if (location?.state?.exam_type === "General Knowledge") {

        const payload = {
          "exam_type_uuid": location?.state?.exam_type_uuid,
          "type_uuid": location?.state?.type_uuid,
          "method_uuid": location?.state?.method_uuid,
          "topic_uuid": location?.state?.topic_uuid,
          "sub_topic_uuid": location?.state?.sub_topic_uuid,

        }
        // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
        // setSuc(true)
        CoinsDeductionApi().then((res) => {
          CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
            if (res) {
              history.push(({
                // pathname: "/questions",
                pathname: "/Waiting-room",
                state: location?.state
              }));
            }
          })
            .catch((err) => {
              console.log(err?.response?.data?.hit_again, "error")
              if (err?.response?.data?.hit_again) {
                CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
                  if (res) {
                    history.push(({
                      // pathname: "/questions",
                      pathname: "/Waiting-room",
                      state: location?.state
                    }));
                  }
                })
                  .catch((err) => {
                    console.log(err, "err")
                  })
              }
            })
        })
      }
      else if (location?.state?.exam_type === "Academic") {
        const payload = {
          "exam_type_uuid": location?.state?.exam_type_uuid,
          "level_uuid": location?.state?.level_uuid,
          "board_type": location?.state?.board_type,
          "state": location?.state?.state,
          "academic_board_uuid": location?.state?.academic_board_uuid,
          "academic_stream_uuid": location?.state?.academic_stream_uuid,
          "academic_group_uuid": location?.state?.academic_group_uuid,
          "academic_year": location?.state?.academic_year,
          "academic_semister": location?.state?.academic_semister,
          "academic_subject_uuid": location?.state?.academic_subject_uuid,
          "academic_sub_subject_uuid": location?.state?.academic_sub_subject_uuid,
          "type_uuid": location?.state?.type_uuid,
          "method_uuid": location?.state?.method_uuid
        }

        // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
        // setSuc(true)
        CoinsDeductionApi().then((res) => {
          CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
            if (res) {
              history.push(({
                // pathname: "/questions",
                pathname: "/Waiting-room",
                state: location?.state
              }));
            }
          })
            .catch((err) => {
              console.log(err, "error")
              if (err?.response?.data?.hit_again) {
                CreateUservsUserGame(payload, userData?.data?.token).then((res) => {
                  if (res) {
                    history.push(({
                      // pathname: "/questions",
                      pathname: "/Waiting-room",
                      state: location?.state
                    }));
                  }
                })
                  .catch((err) => {
                    console.log(err, "err")
                  })
              }
            })
        })
      }
    }

  }



  useEffect(() => {
    if (suc === true) {
      if (notification.status === true) {
        Swal.fire({
          icon: 'success',
          title: (notification.message),
        })
        // if (location?.state === "replay") {
        // ReplayGame()
        // }

        // const payload = {
        //   "is_game_started": "true"
        // }
        // dispatch(IsGameStartedUrl(GameListData?.data?.uuid,payload,userData.data.token))


        // CreateUservsUserGame()
        // setTimeout(() => {
        //   history.push(({
        //     // pathname: "/questions",
        //     pathname: "/Waiting-room",
        //     state: location?.state
        //   }));
        // }, 1000)
        setSuc(false)

      }
      else if (notification.status === false) {
        Swal.fire({
          icon: 'error',
          title: (notification.message.message),
        })
        setSuc(false)
      }
    }

  }, [notification])

  return (
    <>
      <Grid item container className="gameInstContainer">
        <Header />
        <Grid md={9}>
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              //   height: "40vh",
              //   overflowY: "scroll",
            }}
          >
            <Grid align="center" >
              <h1 className="Gameheader">Game Instructions</h1>
            </Grid>
            <div
              style={{ padding: "20px", height: "60vh", overflowY: "scroll" }}
            >
              <div>
                <h3>Before beginning the exam</h3>
                <p>1. Make sure you have a good internet connection</p>
                <p>
                  2. Highly recommended to user googlechrome browser for game
                  play for better smooth experience.
                </p>
                <p>
                  3. Keep browser window in maximize.Minimizing the game window
                  could lead to time wastage.
                </p>
              </div>

              <div>
                <h3>During the exam</h3>
                <p>1. Make sure you have a good internet connection</p>
                <p>
                  2. Highly recommended to user googlechrome browser for game
                  play for better smooth experience.
                </p>
                <p>
                  3. Keep browser window in maximize.Minimizing the game window
                  will lead to time wastage.
                </p>
                <p>
                  4. Do not refresh game window at any cost,this will lead to
                  end of game and make you lose all coins you gained during the
                  game.
                </p>
              </div>
              <div>
                <h3>Score Calculations</h3>
                {GameInstructionDataUserVsUser && GameInstructionDataUserVsUser.data && GameInstructionDataUserVsUser.data.map((item) => {
                  console.log(item, "sdfdfdsfsdfdsfsdf")
                  return <div key="">
                    {/* <h3>Game Id : {item.uuid}</h3> */}
                    <p>1. Competition Type : {item?.competitive_type[0]?.name}</p>
                    <p>2. Competition Method : {item.name}</p>
                    <p>3. Total Allowed Players : {item.total_players}</p>
                    <p>4. Total Questions : {item.questions_count}</p>

                    <p>5. Total Duration : {item.total_duration / 60}  Minutes</p>
                    <p>6. Time per question : {item.time_per_question} Seconds</p>
                    <p>
                      7. Quick Answer Time : {item.quick_answer_time} {Number(item.quick_answer_time) > 2 ? "Points" : "Point"} (If player answers a question within
                      quick answer time player gets bonus 2 points for correct
                      answer)(Only applicable for BUZZER game)
                    </p>
                    <p>8. Score for correct answer : {item.correct_answer_value} {Number(item.correct_answer_value) > 1 ? "Points" : "Point"}</p>

                    <p>9. Score for in-correct answer : {item.negative_answer_value} {Number(item.negative_answer_value) > 1 ? "Points" : "Point"}</p>
                    <p>10. If question skipped : {String(item.skip)}</p>

                  </div>
                })}
              </div>
              <div>
                <h3>U can Share</h3>
                <FacebookIcon />
                <TwitterIcon />
                <LinkedInIcon />

                <p>
                  If you encounter problems while joining the game or during the
                  game play,you must contact our support team by email or phone.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid md={3}>
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
            }}
          >
            <img src={Llogo} alt="" width="100%" height="100%" />
            <br />
            <br />
            {/* <Link to="/questions"> */}
            <Button variant="contained" fullWidth color="success" onClick={CoinDeduction}>
              Start Game
            </Button>
            {/* </Link> */}
            <br />
            <br />
            <Link to="/">
              <Button variant="contained" fullWidth color="error">
                Back to Home
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid >
    </>
  );
}

export default GameUsersInstruction;
