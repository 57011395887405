import { Grid } from "@mui/material";
import React from "react";

const PendingRequest = () =>{
    return(
        <>
            <Grid item container>
        <Grid md={12} align="left" style={{paddingLeft:"40px"}}>
                                <h2>Pending Request</h2>
                                <hr style={{border:"1px solid #e9ecef"}}/>
                            </Grid>
                            
                            </Grid>
        </>
    )
};

export default PendingRequest;