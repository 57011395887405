import React, { useState, useEffect } from "react";
import { secureStorage } from "../../Utils";
import { useDispatch, useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import Llogo from "../../Assets/no1exam_logo.png";
import Grid from "@mui/material/Grid";
import Source from "../../Assets/source.gif"
import user from "../../Assets/user-selected.png"
import user1 from "../../Assets/user-1.png"
import connecting from "../../Assets/icon-motion-intel-connect-wifi.gif"
import Countdown from 'react-countdown';
import Swal from "sweetalert2";
import io from 'socket.io-client';
import axios from 'axios';
import { GameUpdateURL, GameAddURL, UserVsAllUserCreateGame, UserVsAllUserCreateGameData } from "../../Redux/Game/GameListRedux";
import { IsGameStartedUrl } from "../../Redux/Game/GameListRedux";
import { ScoreCardListURL } from "../../Redux/ScoreCard/ScoreCardRedux";
import { ParticipentScorePostURL } from "../../Redux/Game/QuestionsRedux/QuestionRedux";


const host = "https://api.quizapp.scienstechnologies.com";
const WaitingRoom = () => {

  const history = useHistory();
  const location = useLocation();
  console.log(location, "locationdsfsdf")
  const dispatch = useDispatch();
  let userData = secureStorage.getItem("currentUser")
  const { GameListData, UservsalluserData, GameReplayDetails } = useSelector((state) => state.GameList)
  console.log(UservsalluserData, "UservsalluserData")
  const { GameInstructionData } = useSelector((state) => state.GameInstructionList)


  console.log(GameListData, "GameListDataGameListData");
  console.log(userData, "userData")

  const [socketgamedetails, setSocketGameDetails] = useState(undefined)
  console.log(socketgamedetails, "socketgamedetails");

  const [timeOut, setTimeOut] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [waitingRoomChange, setWaitingRoomChange] = useState('');
  console.log(waitingRoomChange, "waitingRoomChange")

  const [UserInGame, setUserInGame] = useState([])
  const [gameDetails, setGameDetails] = useState('');
  console.log(gameDetails, "gameDetailsgameDetails");

  const [playersnameingame, setPlayersNameinGame] = useState('');
  console.log(playersnameingame, "playersnameingame");
  // Set the target time to 30 seconds from now
  const targetDate = Date.now() + 30000; // 30,000 milliseconds

  const LeaveGameApi = async () => {
    const payload = {
      // "game_uuid": GameListData?.data?.uuid,
      "waiting_room_uuid": UservsalluserData?.data?.uuid,
      "player_uuid": userData?.data?.data?.uuid
    }
    const res = await axios.put(`${process.env.REACT_APP_WEB_APP}/game/waiting/room`, payload)
      .then((res) => {
        console.log(res, "dvfdgdgrfgrew")
        if (res) {
          Swal.fire({
            icon: 'success',
            title: (res?.data?.message ? "You Left the Game Sucessfully!" : ""),
          })
        }
        return res
      })
      .catch((err) => {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: (err?.response?.data?.message),
        })
      })
  }

  const CreateGameUrl = async (payload1, token1) => {
    const funData = async (payload, token) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_WEB_APP}/watingroom/create`, payload, {
          headers: {
            "x-auth-token": token
          }
        });
        dispatch(UserVsAllUserCreateGameData(res.data))
        return res.data;
      } catch (err) {
        console.error("Error creating game:", err);
        dispatch(UserVsAllUserCreateGameData({}))
        return {}; // or any default value you prefer
      }
    };

    const results = await funData(payload1, token1);
    console.log(results, "result");
    return results;
  }

  const createGameSocket = (queryParams) => {

    const socket = io(host, {
      // path: '/pathToConnection',
      transports: ['websocket'],
      upgrade: false,
      query: queryParams,
      reconnection: true,
      rejectUnauthorized: false
    });

    socket.on('connect', () => {
      console.log('Connected to the server');
    });
    SocketGameDetails();
    socket.on("gamedetails", (count) => {
      console.log(count, "gamedetails");
      setGameDetails(count)
    });
    socket.on("usersInGame", (usersInGame) => {
      console.log(usersInGame, "usersInGame");
      setUserInGame(usersInGame)
    });

    // return () => {
    //   if (socket) {
    //     socket.disconnect();
    //   }
    // };
  }

  const [suc, setSuc] = useState(false);

  const onComplete = () => {
    // Set the timeOut state to true when the countdown completes
    setTimeOut(true);
    if (userType === "uservsuser") {
      Swal.fire({
        title: 'Time Out.! Do You Want To Play With Sytem ',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `Leave Game`,
        // cancelButtonText: 'Wait for Another 30sec'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (location?.state?.exam_type === "Entrance") {
            const payload = {
              "exam_type_uuid": location?.state?.exam_type_uuid,
              "level_uuid": location?.state?.level_uuid,
              "board_type": location?.state?.board_type,
              "state": location?.state?.state,
              "entrance_exam_type_uuid": location?.state?.entrance_exam_type_uuid,
              "entrance_exam_uuid": location?.state?.entrance_exam_uuid,
              "entrance_sub_paper_uuid": location?.state?.entrance_sub_paper_uuid,
              "type_uuid": location?.state?.type_uuid,
              "method_uuid": location?.state?.method_uuid
            }
            dispatch(GameAddURL(payload, userData.data.token))
            LeaveGameApi()
            setSuc(true)
          }
          else if (location?.state?.exam_type === "Competitive") {
            const payload = {
              "exam_type_uuid": location?.state?.exam_type_uuid,
              "level_uuid": location?.state?.level_uuid,
              "board_type": location?.state?.board_type,
              "state": location?.state?.state,
              "competitive_exam_type_uuid": location?.state?.competitive_exam_type_uuid,
              "competitive_exam_uuid": location?.state?.competitive_exam_uuid,
              "competitive_sub_paper_uuid": location?.state?.competitive_sub_paper_uuid,
              "type_uuid": location?.state?.type_uuid,
              "method_uuid": location?.state?.method_uuid
            }
            dispatch(GameAddURL(payload, userData.data.token))
            LeaveGameApi()
            setSuc(true)
          }
          else if (location?.state?.exam_type === "General Knowledge") {
            const payload = {
              "exam_type_uuid": location?.state?.exam_type_uuid,
              "type_uuid": location?.state?.type_uuid,
              "method_uuid": location?.state?.method_uuid,
              "topic_uuid": location?.state?.topic_uuid,
              "sub_topic_uuid": location?.state?.sub_topic_uuid,
            }
            dispatch(GameAddURL(payload, userData.data.token))
            LeaveGameApi()
            setSuc(true)
          }
          else if (location?.state?.exam_type === "Academic") {
            const payload = {
              "exam_type_uuid": location?.state?.exam_type_uuid,
              "level_uuid": location?.state?.level_uuid,
              "board_type": location?.state?.board_type,
              "state": location?.state?.state,
              "academic_board_uuid": location?.state?.academic_board_uuid,
              "academic_stream_uuid": location?.state?.academic_stream_uuid,
              "academic_group_uuid": location?.state?.academic_group_uuid,
              "academic_year": location?.state?.academic_year,
              "academic_semister": location?.state?.academic_semister,
              "academic_subject_uuid": location?.state?.academic_subject_uuid,
              "academic_sub_subject_uuid": location?.state?.academic_sub_subject_uuid,
              "type_uuid": location?.state?.type_uuid,
              "method_uuid": location?.state?.method_uuid
            }
            dispatch(GameAddURL(payload, userData?.data?.token))
            LeaveGameApi()
            setSuc(true)
          }
          // Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          LeaveGameApi()
          history.push(({
            pathname: "/",
          }));
          // Swal.fire('Changes are not saved', '', 'info')
        }
        else if (result?.isDismissed) {
          console.log('Cancel button clicked');
          restartCountdown();
        }
      })
    }
    //bbvhjdbfdvf
    else if (userType === "uservs2user") {
      Swal.fire({
        title: 'Time Out.! Do You Want To Play With user vs user ',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `Leave Game`,
        // cancelButtonText: 'Wait for Another 30sec'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.get(`${process.env.REACT_APP_WEB_APP}/watingroom/change/method/${location?.state?.type_uuid}/uservsuser`, {
            headers: {
              "x-auth-token": userData?.data?.token
            }
          })
            .then((res) => {
              console.log(res, "waitingroomres")
              setWaitingRoomChange(res?.data?.data)
              setUserType("uservsuser")
              setTimeOut(() => {
                if (location?.state?.exam_type === "Entrance") {
                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "level_uuid": location?.state?.level_uuid,
                    "board_type": location?.state?.board_type,
                    "state": location?.state?.state,
                    "entrance_exam_type_uuid": location?.state?.entrance_exam_type_uuid,
                    "entrance_exam_uuid": location?.state?.entrance_exam_uuid,
                    "entrance_sub_paper_uuid": location?.state?.entrance_sub_paper_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid
                  }

                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })
                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };
                }
                else if (location?.state?.exam_type === "Competitive") {
                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "level_uuid": location?.state?.level_uuid,
                    "board_type": location?.state?.board_type,
                    "state": location?.state?.state,
                    "competitive_exam_type_uuid": location?.state?.competitive_exam_type_uuid,
                    "competitive_exam_uuid": location?.state?.competitive_exam_uuid,
                    "competitive_sub_paper_uuid": location?.state?.competitive_sub_paper_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid
                  }

                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })
                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };

                }
                else if (location?.state?.exam_type === "General Knowledge") {

                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid,
                    "topic_uuid": location?.state?.topic_uuid,
                    "sub_topic_uuid": location?.state?.sub_topic_uuid,

                  }

                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })
                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };

                }
                else if (location?.state?.exam_type === "Academic") {
                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "level_uuid": location?.state?.level_uuid,
                    "board_type": location?.state?.board_type,
                    "state": location?.state?.state,
                    "academic_board_uuid": location?.state?.academic_board_uuid,
                    "academic_stream_uuid": location?.state?.academic_stream_uuid,
                    "academic_group_uuid": location?.state?.academic_group_uuid,
                    "academic_year": location?.state?.academic_year,
                    "academic_semister": location?.state?.academic_semister,
                    "academic_subject_uuid": location?.state?.academic_subject_uuid,
                    "academic_sub_subject_uuid": location?.state?.academic_sub_subject_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid
                  }

                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })

                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };
                }
              }, 5000)
            })
            .catch((err) => {
              console.log(err)
            })
          // Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          LeaveGameApi()
          history.push(({
            pathname: "/",
          }));
          // Swal.fire('Changes are not saved', '', 'info')
        }
        else if (result.isDismissed) {
          console.log('Cancel button clicked');
          restartCountdown();
        }
      })
    }
    else if (userType === "uservs4user") {
      Swal.fire({
        title: 'Time Out.! Do You Want To Play With user vs 2user ',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `Leave Game`,
        // cancelButtonText: 'Wait for Another 30sec'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.get(`${process.env.REACT_APP_WEB_APP}/watingroom/change/method/${location?.state?.type_uuid}/uservs2user`, {
            headers: {
              "x-auth-token": userData?.data?.token
            }
          })
            .then((res) => {
              console.log(res, "waitingroomres")
              setWaitingRoomChange(res?.data?.data)
              setUserType("uservs2user")
              setTimeOut(() => {
                if (location?.state?.exam_type === "Entrance") {
                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "level_uuid": location?.state?.level_uuid,
                    "board_type": location?.state?.board_type,
                    "state": location?.state?.state,
                    "entrance_exam_type_uuid": location?.state?.entrance_exam_type_uuid,
                    "entrance_exam_uuid": location?.state?.entrance_exam_uuid,
                    "entrance_sub_paper_uuid": location?.state?.entrance_sub_paper_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid
                  }

                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })

                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };
                }
                else if (location?.state?.exam_type === "Competitive") {
                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "level_uuid": location?.state?.level_uuid,
                    "board_type": location?.state?.board_type,
                    "state": location?.state?.state,
                    "competitive_exam_type_uuid": location?.state?.competitive_exam_type_uuid,
                    "competitive_exam_uuid": location?.state?.competitive_exam_uuid,
                    "competitive_sub_paper_uuid": location?.state?.competitive_sub_paper_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid
                  }

                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })
                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };
                }
                else if (location?.state?.exam_type === "General Knowledge") {

                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid,
                    "topic_uuid": location?.state?.topic_uuid,
                    "sub_topic_uuid": location?.state?.sub_topic_uuid,

                  }
                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })

                }
                else if (location?.state?.exam_type === "Academic") {
                  const payload = {
                    "exam_type_uuid": location?.state?.exam_type_uuid,
                    "level_uuid": location?.state?.level_uuid,
                    "board_type": location?.state?.board_type,
                    "state": location?.state?.state,
                    "academic_board_uuid": location?.state?.academic_board_uuid,
                    "academic_stream_uuid": location?.state?.academic_stream_uuid,
                    "academic_group_uuid": location?.state?.academic_group_uuid,
                    "academic_year": location?.state?.academic_year,
                    "academic_semister": location?.state?.academic_semister,
                    "academic_subject_uuid": location?.state?.academic_subject_uuid,
                    "academic_sub_subject_uuid": location?.state?.academic_sub_subject_uuid,
                    "type_uuid": location?.state?.type_uuid,
                    "method_uuid": res?.data?.data?.method_uuid
                  }


                  LeaveGameApi().then((res) => {
                    CreateGameUrl(payload, userData?.data?.token).then((res) => {
                      setUserInGame([])
                      var queryParams = {};
                      if (String(res?.data?.uuid).startsWith("WRM") === true && res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                          wating_room_uuid: res?.data?.uuid
                        };
                        // createGameSocket(queryParams)
                      }
                      else if (res?.data?.uuid) {
                        queryParams = {
                          // userId: playeruuid ? playeruuid : '',
                          player_uuid: playeruuid ? playeruuid : '',
                        };
                        // createGameSocket(queryParams)

                      }

                    })
                  })
                    .catch((err) => {
                      console.error("Error Removing Waiting room user");
                    })
                  // dispatch(UserVsAllUserCreateGame(payload, userData.data.token))
                  // LeaveGameApi()

                  // const queryParams = {
                  //   // userId: playeruuid ? playeruuid : '',
                  //   player_uuid: playeruuid ? playeruuid : '',
                  // };

                  // const socket = io(host, {
                  //   path: '/pathToConnection',
                  //   transports: ['websocket'],
                  //   upgrade: false,
                  //   query: queryParams,
                  //   reconnection: true,
                  //   rejectUnauthorized: false
                  // });

                  // socket.on('connect', () => {
                  //   console.log('Connected to the server');
                  // });
                  // socket.on("gamedetails", (count) => {
                  //   console.log(count, "gamedetails");
                  //   setGameDetails(count)
                  // });

                  // return () => {
                  //   if (socket) {
                  //     socket.disconnect();
                  //   }
                  // };
                }
              }, 5000)
            })
            .catch((err) => {
              console.log(err)
            })
          // Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          LeaveGameApi()
          history.push(({
            pathname: "/",
          }));
          // Swal.fire('Changes are not saved', '', 'info')
        }
        else if (result.isDismissed) {
          console.log('Cancel button clicked');
          restartCountdown();
        }
      })
    }
  };

  useEffect(() => {
    if (suc === true)
      setTimeOut(() => {
        history.push(({
          pathname: "/game-instruction",
          state: "uservssystem"
        }));
      }, 1000)
  }, [suc])
  const restartCountdown = () => {
    // Reset the timeOut state and set a new key to trigger countdown restart
    setTimeOut(true);
    setResetKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    // Reset the countdown if the resetKey changes
    if (resetKey > 0) {
      setTimeOut(false);
    }
  }, [resetKey]);



  // useEffect(() => {
  //   // Reset the countdown if the resetKey changes
  //   if (resetKey > 0) {
  //     setTimeOut(false);
  //   }
  // }, [resetKey]);



  const [onlineUserCount, setOnlineUserCount] = useState(0);
  const [joinedUserCount, setJoinedUserCount] = useState(0);

  // console.log(onlineUserCount,joinedUserCount,"onlineUserCoun121212121t");

  const playeruuid = userData?.data?.data?.uuid

  const SocketGameDetails = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/socket_game_details?wating_room_uuid=${UservsalluserData?.is_game_start === true ? UservsalluserData?.data?.waiting_room_uuid : UservsalluserData?.data?.uuid}`)
      .then((res) => {
        console.log(res, "hjdbgfjdhjkdhkjhdskjf");
        setSocketGameDetails(res?.data?.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    let timerInterval = setInterval(() => {
      SocketGameDetails()
      SocketWaitingRoomPlayers(UservsalluserData?.is_game_start === true ? UservsalluserData?.data?.waiting_room_uuid : UservsalluserData?.data?.uuid)
    }, 10000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  const SocketWaitingRoomPlayers = (waitingUUid) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/waiting_players?wating_room_uuid=${waitingUUid}`)
      .then((res) => {
        console.log(res, "hfdjddkjhkjdshfjkdshjk");
        setPlayersNameinGame(res?.data?.game_users)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    SocketGameDetails()
    SocketWaitingRoomPlayers(UservsalluserData?.is_game_start === true ? UservsalluserData?.data?.waiting_room_uuid : UservsalluserData?.data?.uuid)
  }, [])

  // useEffect(() => {
  //   console.log(String(UservsalluserData?.data?.uuid).startsWith("WRM"), "playeruuidcccc")
  //   if (playeruuid) {


  // const fetchData = () => {
  // const queryParams = {
  //   // userId: playeruuid ? playeruuid : '',
  //   player_uuid: playeruuid ? playeruuid : '',
  //   wating_room_uuid: UservsalluserData?.data?.uuid
  //   // game_uuid: location?.state !== undefined ? location?.state : "" //once done backend

  // };

  // if (String(UservsalluserData?.data?.uuid).startsWith("WRM") === true && UservsalluserData?.data?.uuid) {
  //   SocketWaitingRoomPlayers(UservsalluserData?.data?.uuid);
  // }

  // Socket previous code start

  // var queryParams = {};
  // if (String(UservsalluserData?.data?.uuid).startsWith("WRM") === true && UservsalluserData?.data?.uuid) {
  //   queryParams = {
  //     userId: playeruuid ? playeruuid : '',
  //     // player_uuid: playeruuid ? playeruuid : '',
  //     // wating_room_uuid: UservsalluserData?.data?.uuid
  //   };
  //   // createGameSocket(queryParams)
  // }
  // else if (UservsalluserData?.data?.uuid) {
  //   queryParams = {
  //     userId: playeruuid ? playeruuid : '',
  //     // player_uuid: playeruuid ? playeruuid : '',
  //   };
  //   // createGameSocket(queryParams)

  // }

  // Socket previous code ends

  // const socket = io(host, {
  //   path: '/pathToConnection',
  //   transports: ['websocket'],
  //   upgrade: false,
  //   query: queryParams,
  //   reconnection: true,
  //   rejectUnauthorized: false
  // });

  // socket.on('connect', () => {
  //   console.log('Connected to the server');
  // });
  // socket.on('onlineusercount', (count) => {
  //   console.log('Received online user count:', count);
  //   setOnlineUserCount(count);

  // });
  // socket.on('receiveplayercount', (count) => {
  //   console.log('Received player user count:', count);
  //   setJoinedUserCount(count);
  // });
  // socket.on("online", (userId) => {
  //   console.log(userId, "Is Online!"); // update online status
  //   // document.getElementById("logs").innerHTML += "<div>" + userId + " Is Online! </div>";
  // });

  // // USER IS OFFLINE
  // socket.on("offline", (userId) => {
  //   console.log(userId, "Is Offline!"); // update offline status
  //   // document.getElementById("logs").innerHTML += "<div>" + userId + " Is Offline! </div>";
  // });
  // socket.on("usersInGame", (usersInGame) => {
  //   console.log(usersInGame, "usersInGame");
  //   setUserInGame(usersInGame)
  //   // update offline status
  //   // document.getElementById("logs").innerHTML += "<div>" + userId + " Is Offline! </div>";
  // });
  // socket.on("gamedetails", (count) => {
  //   console.log(count, "gamedetails");
  //   setGameDetails(count)
  // });

  // return () => {
  //   if (socket) {
  //     socket.disconnect();
  //   }
  // };

  // }

  // const intervalId = setInterval(() => {
  //   fetchData();
  // }, 1000);

  // return () => clearInterval(intervalId);


  //   }
  // }, [playeruuid, gameDetails]);



  useEffect(() => {
    if (socketgamedetails !== undefined) {
      setTimeout(() => {
        history.push(({
          pathname: "/Userquestions",
          state: socketgamedetails
        }));
      }, 2000)
      // const payload = {
      //   "is_game_started": "true"
      // }

      // setTimeout(() => {
      // if (playeruuid !== GameListData?.data?.created_by_uuid) {
      // dispatch(IsGameStartedUrl(location?.state !== undefined ? location?.state : "", payload, userData.data.token))
      // dispatch(IsGameStartedUrl(gameDetails?.data?.uuid, payload, userData.data.token))

      // }
      // }, 1000)

      // setTimeOut(() => {
      //   const payload = {
      //     "type_uuid": GameListData.data.type_uuid,
      //     "method_uuid": GameListData.data.method_uuid,
      //     "game_uuid": GameListData.data.uuid,
      //     "participant_uuid": userData.data.data.uuid
      //   }
      //   dispatch(ParticipentScorePostURL(payload, userData.data.token))
      // }, 500)
    }

  }, [socketgamedetails])


  // const ParticipentScore =()=>{
  //   const payload={
  //     "type_uuid" : GameListData.data.type_uuid,
  //     "method_uuid" : GameListData.data.method_uuid,
  //     "game_uuid" : GameListData.data.uuid,
  //     "participant_uuid" : userData.data.data.uuid
  // }
  // dispatch(ParticipentScorePostURL(payload,userData.data.token))
  // }

  //   useEffect(()=>{
  //     if(GameListData)
  //     {
  //       ParticipentScore()
  //         // dispatch(GameInstructionListURL(GameListData.data.uuid))
  //         dispatch(ScoreCardListURL(GameListData.data.uuid, GameListData.data.type_uuid, GameListData.data.method_uuid, userData?.data?.token))
  //         console.log(GameInstructionData,"GameInstructionData")
  //     }
  // },[ GameListData])

  const [userType, setUserType] = useState('')

  useEffect(() => {
    if (location?.state?.userType) {
      setUserType(location?.state?.userType)
    }
  }, [location])


  return (
    <>
      <Grid item container className="waiting_room_container">
        {/* <Header /> */}
        {userType === "uservsuser" &&
          <Grid md={9}>
            <Grid item container>
              <Grid md={4}>
                <img src={user} alt="user" />
                <br />
                <p>Name : {playersnameingame?.length > 0 && playersnameingame?.map((items) => {
                  if (items?.uuid === userData?.data?.data?.uuid) {
                    return items?.name
                  }
                })}</p>

              </Grid>
              <Grid md={4}>
                <img src={connecting} alt="user" style={{ width: "100px", marginTop: "100px" }} />

              </Grid>
              <Grid md={4}>
                <img src={user1} alt="user 1" />
                <br />
                {/* <p>Name:{UserInGame.length !== 0 ? UserInGame[0].name : " "}</p> */}
                <p>Name:{UserInGame?.length > 0 && UserInGame?.map((item) => {
                  if (item?.uuid !== userData?.data?.data?.uuid) {
                    return item?.name
                  }
                })}</p>
                <br />
                {/* <p> 1 sec</p> */}
                {/* <div> */}
                {/* <h2>Countdown Timer</h2> */}
                <Countdown key={resetKey} date={targetDate} onComplete={() => onComplete()} />

              </Grid>
            </Grid>
          </Grid>
        }
        {userType === "uservs2user" &&
          <Grid md={9}>
            <Grid item container>
              <Grid md={2}>
                <img src={user} alt="user" style={{ width: "72%" }} />
                <br />
                <p>Name : {playersnameingame?.length > 0 && playersnameingame?.map((items) => {
                  if (items?.uuid === userData?.data?.data?.uuid) {
                    return items?.name
                  }
                })}</p>
              </Grid>
              < Grid md={2}>
                <img src={connecting} alt="user" style={{ width: "100px", marginTop: "100px" }} />
              </Grid>
              <Grid md={2}>
                <img src={user1} alt="user 1" />
                <br />
                <p>Name:
                  {playersnameingame?.length > 0 && (() => {
                    const item = playersnameingame.find((items) => items?.uuid !== userData?.data?.data?.uuid);
                    return item ? item.name : null;
                  })()}
                </p>
              </Grid>
              <Grid md={2}>
                <img src={connecting} alt="user" style={{ width: "100px", marginTop: "100px" }} />
              </Grid>
              <Grid md={2}>
                <img src={user1} alt="user 1" />
                <br />
                <p>Name:</p>
              </Grid>
              <Grid md={2}>
              </Grid>
              <Grid md={2}>
                <br />
                <Countdown key={resetKey} date={targetDate} onComplete={() => onComplete()} />
              </Grid>
            </Grid >
          </Grid>
        }
        {userType === "uservs4user" &&
          <Grid md={9}>
            <Grid item container style={{ display: "flex", justifyContent: "center" }} >
              <Grid md={4} >
                <img src={user} alt="user" />
                <br />
                <p>Name : {playersnameingame?.length > 0 && playersnameingame?.map((items) => {
                  if (items?.uuid === userData?.data?.data?.uuid) {
                    return items?.name
                  }
                })}</p>

              </Grid>

            </Grid>
          </Grid>
        }
        <Grid md={3}>
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
            }}
          >
            <img src={Llogo} alt="" width="100%" height="100%" />
            <br />
            {/* <br /> */}

            <img src={Source} alt="" width="100%" height="100%" />
            {/* <Link to="/questions"> */}
            {/* <Button variant="contained" fullWidth color="success">
                Start Game
              </Button> */}
            {/* </Link> */}
            {/* <br /> */}
            <br />
            <Link to="/">
              <Button variant="contained" fullWidth color="error" onClick={() => LeaveGameApi()}>
                Leave Game
              </Button>
            </Link>
          </div>
        </Grid>

        {userType === "uservs4user" &&
          <Grid item container style={{ marginLeft: "7%" }}>

            <Grid md={2}>
              <img src={user1} alt="user 1" />
              <br />
              <p>Name:</p>
            </Grid>
            <Grid md={2}>
              <img src={user1} alt="user 1" />
              <br />
              <p>Name:</p>
            </Grid>
            <Grid md={2}>
              <img src={user1} alt="user 1" />
              <br />
              <p>Name:</p>
              <br />

              <Countdown key={resetKey} date={targetDate} onComplete={() => onComplete()} />

            </Grid>
            <Grid md={2}>
              <img src={user1} alt="user 1" />
              <br />
              <p>Name:</p>
            </Grid>
          </Grid>
        }
      </Grid >
    </>
  )
}
export default WaitingRoom