import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    GameInstructionData: [],
  notification:{}
};

const GameInstructionSlice = createSlice({
  name: 'GameInstruction',
  initialState,
  reducers: {
    setGameInstructionData(state, action) {
      state.GameInstructionData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setGameInstructionData, setToast } = GameInstructionSlice.actions;


export const GameInstructionListURL = (gameId, token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/game/view/${gameId}`,{headers:{
    "x-auth-token" : token
//   }});
//   console.log(response.data.data, "dfghj")
//   dispatch(setGameInstructionData(response.data));
// };

}}).then((res) => {
  dispatch(setGameInstructionData(res.data));

})
.catch((err) => {
  dispatch(setGameInstructionData([]));

})
}





const GameInstructionReducer = GameInstructionSlice.reducer;

export default GameInstructionReducer;
