import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    SubSubjectData: [],
  notification:{}
};

const SubSubjectSlice = createSlice({
  name: 'SubSubject',
  initialState,
  reducers: {
    setSubSubjectData(state, action) {
      state.SubSubjectData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setSubSubjectData, setToast } = SubSubjectSlice.actions;


export const SubSubjectListURL = (academicStream,academicGroup,queryParamString,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/academic/sub/subject?stream_uuid=${academicStream}&group_uuid=${academicGroup}&${queryParamString}`,{headers:{
    "x-auth-token" : token
  }})
  .then((res) => {
    dispatch(setSubSubjectData(res.data.data));
    console.log(res, "wereewrwer")
  })
  .catch((err) => {
    dispatch(setSubSubjectData([]));
  })
}
  
  
  
  
//   ;
//   console.log(response.data.data, "dfghj")
//   dispatch(setSubSubjectData(response.data));
// };




const SubSubjectReducer = SubSubjectSlice.reducer;

export default SubSubjectReducer;
