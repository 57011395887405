import React, { useState, useEffect } from 'react';
import './Question.css'; // Import your CSS file here

function QuestionTimer() {
  const [countdown] = useState(new Date(Date.parse(new Date()) + 14 * 24 * 60 * 60 * 1000));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const diff = countdown - now;
      const newMinutes = Math.floor(diff / 1000 / 60 % 60);
      const newSeconds = Math.floor(diff / 1000 % 60);

      // Check if the new second has started
      if (newSeconds !== seconds) {
        setSeconds(newSeconds < 10 ? '0' + newSeconds : newSeconds);
        // Add the flipping class to trigger the flip animation
        setTimeout(() => {
          setSeconds('' + newSeconds);
        }, 50);
      }

      if (newMinutes !== minutes) {
        setMinutes(newMinutes < 10 ? '0' + newMinutes : newMinutes);
      }

      if (diff <= 0) {
        clearInterval(timeinterval);
      }
    };

    const timeinterval = setInterval(updateClock, 1000);
    updateClock();

    return () => {
      clearInterval(timeinterval);
    };
  }, [countdown, minutes, seconds]);

  return (
    <main>
      <div className='cards'>
        <div className='card minutes'>
          <div className='flip-card'>
            <div className={`top-half ${minutes < 10 ? 'flipping' : ''}`}>
              {minutes < 10 ? '0' + minutes : minutes}
            </div>
            <div className={`bottom-half ${minutes < 10 ? 'flipping' : ''}`}>
              {minutes < 10 ? '0' + minutes : minutes}
            </div>
          </div>
          <p>Minutes</p>
        </div>

        <div className='card seconds'>
          <div className='flip-card'>
            <div className={`top-half ${seconds < 10 ? 'flipping' : ''}`}>
              {seconds < 10 ? '0' + seconds : seconds}
            </div>
            <div className={`bottom-half ${seconds < 10 ? 'flipping' : ''}`}>
              {seconds < 10 ? '0' + seconds : seconds}
            </div>
          </div>
          <p>Seconds</p>
        </div>
      </div>
    </main>
  );
}

export default QuestionTimer;
