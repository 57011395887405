import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    CompatativeExamData: [],
  notification:{}
};

const CompatativeExamSlice = createSlice({
  name: 'Compatative Exam',
  initialState,
  reducers: {
    setExamCompatativeExamData(state, action) {
      state.CompatativeExamData = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setExamCompatativeExamData, setToast } = CompatativeExamSlice.actions;


export const CompatativeExamListURL = (CompatitiveExamTypeUUid,educationLevelUuid,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/competitive/exam?competitive_exam_type_uuid=${CompatitiveExamTypeUUid}&level_uuid=${educationLevelUuid}&board_type=state&state=Telangana`,{headers:{
    "x-auth-token" : token


}}).then((res) => {
  dispatch(setExamCompatativeExamData(res.data));

})
.catch((err) => {
  dispatch(setExamCompatativeExamData([]));

})
}



export const CompatativeExamAddURL = (payload,token) => async (dispatch) => {
  const response = await axios.post(`${process.env.REACT_APP_URL}/competitive/exam/add`,payload,{headers:{
    "x-auth-token" : token
  }})
  .then((res) => {
    console.log(res, "sdfsdfsdff")
    dispatch(setToast({ status: true, message: res.data.message }))
  })
  .catch((err) => {
    dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))

  })
};


  


  export const ExamTypeUpdateURL = (payload, uuid ,token) => async (dispatch) => {
    const response = await axios.put(`${process.env.REACT_APP_URL}/stream/update/${uuid}`, payload, {
      headers: {
        "x-auth-token": token
      }
    }).then((res) => {
      console.log(res, "sdfsddffsdff")
      dispatch(setToast({ status: true, message: res.data.message }))
    })
      .catch((err) => {
        console.log(err && err.response,"hjgjghgjhghj")
        dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
      })
    // console.log(response, "sdfsfsdfs")
  
  };

  export const  ExamTypeStatusUpdateURL = ( id, payload, token) => async (dispatch) => {
    const response = await axios.put(`${process.env.REACT_APP_URL}/board/update/status/${id}`, payload, {
      headers: {
        "x-auth-token": token
      }
    }).then((res) => {
      console.log(res, "sdfsddffsdff")
      dispatch(setToast({ status: true, message: res.data.message }))
    })
      .catch((err) => {
        console.log(err && err.response,"hjgjghgjhghj")
        dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
      })
 
  };




const CompatativeExamReducer = CompatativeExamSlice.reducer;

export default CompatativeExamReducer;
