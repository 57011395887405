import * as React from 'react';
import { useState } from 'react';
import { secureStorage } from "../Utils";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../Assets/logo.png'
import Login from "../auth/Login"
import SignUp from "../auth/Signup"
import { useHistory,Link } from 'react-router-dom';

const drawerWidth = 240;
const navItems = ['Home', 'Events','Profile','Friends','About No 1 Exam', 'Contact'];

function DrawerAppBar(props) {

  const history=useHistory()
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  let userData = secureStorage.getItem("currentUser")
console.log(userData,"use32145622");

  const Logout = () => {
    secureStorage.removeItem("currentUser");
        history.push('/')
    }

  const [showLoginForm, setShowLoginForm] = useState(false);

  const handleLoginClick = () => {
    setShowLoginForm(true);
  };

  const handleLoginFormClose = () => {
    setShowLoginForm(false);
  };


  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
       <img src={Logo} alt="logo" style={{width:"100px"}}/>
      </Typography>
      <Divider />
      {/* <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center',}}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <List>
      <Link to='/' style={{textDecoration:"none" , color:"black"}}>
          <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: 'center',}}>
              <ListItemText >Home</ListItemText>
            </ListItemButton>
          </ListItem>
          </Link>
          {/* <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: 'center',}}>
              <ListItemText >Events</ListItemText>
            </ListItemButton>
          </ListItem> */}
          <Link to='/Stats' style={{textDecoration:"none" , color:"black"}}>
          <ListItem  disablePadding>
          <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center"}}>
            Profile
            </ListItemButton>
            </ListItem>
            </Link>
            {/* <Link to='/friends' style={{textDecoration:"none" , color:"black"}}>
            <ListItem  disablePadding>
            <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
            Friends
            </ListItemButton>
            </ListItem>
            </Link> */}
          <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: 'center',}}>
              <ListItemText >About No 1 Exam</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: 'center',}}>
              <ListItemText >Contact Us</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
          <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center"}}>
           
           {/* <Login/> */}

  

           {userData?.data?.data?
             <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center"}} onClick={Logout}>
          Logout
           </ListItemButton>
           :
           <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center"}}  onClick={handleLoginClick}>
          Login
           </ListItemButton>
           }
           {showLoginForm && <Login isOpen={showLoginForm} onClose={handleLoginFormClose} />}
          
           </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: 'center',}}>
              <ListItemText >
                <SignUp/>
              </ListItemText>
            </ListItemButton>
          </ListItem>


     
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', }}>
      <CssBaseline />
      <AppBar component="nav" style={{color:'black', background:'white'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            align='left'
          >
          
            <img src={Logo} alt="logo" style={{width:"100px"}}/>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {/* {navItems.map((item) => (
              <Button key={item} sx={{ color: 'black' }}>
                {item}
              </Button>
            ))} */}
            {/* <Button sx={{ color: 'black' }}>Home</Button>
            <Button>Events</Button> */}
            <List>
         
          <ListItem  disablePadding>
          <Link to='/' style={{textDecoration:"none" , color:"black"}}>
            <ListItemButton sx={{ textAlign: 'center',}}>
            Home

            </ListItemButton>
            </Link>
            {/* <ListItemButton sx={{ textAlign: 'center',}}>
            Events
            </ListItemButton> */}

            {userData?.data?.data?
            <Link to='/Stats' style={{textDecoration:"none" , color:"black"}}>
          <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
            <span style={{ textDecoration: 'none' }}>Profile</span>
          </ListItemButton>
        </Link>
            :
            null
            }
        {/* <Link to='/friends' style={{textDecoration:"none" , color:"black"}}>
        <ListItemButton sx={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
            Friends
            </ListItemButton>
            </Link> */}
            <ListItemButton sx={{ textAlign: 'center',}}>
            About No 1 Exam
            </ListItemButton>

            <ListItemButton sx={{ textAlign: 'center',}}>
            Contact Us

            </ListItemButton>
            <ListItemButton sx={{ textAlign: 'center',}}>
           
            {/* <Login/> */}

   

            {userData?.data?.data?
              <ListItemButton sx={{ textAlign: 'center',}} onClick={Logout}>
           Logout
            </ListItemButton>
            :
            <ListItemButton sx={{ textAlign: 'center',}}  onClick={handleLoginClick}>
           Login
            </ListItemButton>
            }
            {showLoginForm && <Login isOpen={showLoginForm} onClose={handleLoginFormClose} />}
           
            </ListItemButton>

            {userData?.data?.data?
            null:
            <ListItemButton sx={{ textAlign: 'center',}}>
        
        <SignUp/>
                    </ListItemButton>
            }
 


          </ListItem>
          </List>
        
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>


    </Box>
    
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;