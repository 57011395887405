import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO, SERVICE_URL } from 'config.js';
import axios from 'axios';


const initialState = {
    CompatativeSubPpaperDtata: [],
  notification:{}
};

const CompatativeSubPpaperSlice = createSlice({
  name: 'Compatative Sub Ppaper',
  initialState,
  reducers: {
    setCompatativeSubPpaperDtata(state, action) {
      state.CompatativeSubPpaperDtata = action.payload;
    },
    setToast(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setCompatativeSubPpaperDtata, setToast } = CompatativeSubPpaperSlice.actions;


export const CompatativeSubPpaperListURL = (competitive_exam_uuid,token) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_WEB_APP}/participant/get/competitive/subpaper?competitive_exam_uuid=${competitive_exam_uuid}`,{headers:{
    "x-auth-token" : token
  }});
  console.log(response.data.data, "CompatativeSubPpaperDtata")
  dispatch(setCompatativeSubPpaperDtata(response.data));
};

// export const ExamTypeAddURL = (payload,token) => async (dispatch) => {
//   const response = await axios.post(`${process.env.REACT_APP_URL}/competitive/exam/type/add`,payload,{headers:{
//     "x-auth-token" : token
//   }})
//   .then((res) => {
//     console.log(res, "sdfsdfsdff")
//     dispatch(setToast({ status: true, message: res.data.message }))
//   })
//   .catch((err) => {
//     dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))

//   })
// };


  


//   export const ExamTypeUpdateURL = (payload, uuid ,token) => async (dispatch) => {
//     const response = await axios.put(`${process.env.REACT_APP_URL}/stream/update/${uuid}`, payload, {
//       headers: {
//         "x-auth-token": token
//       }
//     }).then((res) => {
//       console.log(res, "sdfsddffsdff")
//       dispatch(setToast({ status: true, message: res.data.message }))
//     })
//       .catch((err) => {
//         console.log(err && err.response,"hjgjghgjhghj")
//         dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
//       })
//     // console.log(response, "sdfsfsdfs")
  
//   };

//   export const  ExamTypeStatusUpdateURL = ( id, payload, token) => async (dispatch) => {
//     const response = await axios.put(`${process.env.REACT_APP_URL}/board/update/status/${id}`, payload, {
//       headers: {
//         "x-auth-token": token
//       }
//     }).then((res) => {
//       console.log(res, "sdfsddffsdff")
//       dispatch(setToast({ status: true, message: res.data.message }))
//     })
//       .catch((err) => {
//         console.log(err && err.response,"hjgjghgjhghj")
//         dispatch(setToast({ status: false, message: err && err.response? err && err.response.data:"Something went wrong" }))
  
//       })
 
//   };




const CompatativeSubPpaperReducer = CompatativeSubPpaperSlice.reducer;

export default CompatativeSubPpaperReducer;
