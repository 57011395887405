import { Grid } from "@mui/material";
import React from "react";
import './friends.css'

const Chat = () =>{
    return(
        <>
        <Grid item container style={{padding:"0px",border:"1px solid green"}}>
           <Grid xs={12} md={9}>
          <h1 className="ChatTitle"> Select a friend</h1>
<div className="chat1"></div>
<textarea placeholder="Message..."  className="textarea" ></textarea>
           </Grid>
           <Grid xs={12} md={3}>
           <h1 className="ChatTitle2">Friends List</h1>
           <div className="chat2"></div>
           </Grid>
        </Grid>
        </>
    )
};

export default Chat;