import React ,{useState} from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./auth.css";
import { BrowserRouter as Router, Switch, Route, Link ,useHistory, useLocation} from "react-router-dom";
import Swal from 'sweetalert2';

function ForgotPassword() {
  const history = useHistory();
    const [email, setEmail]=React.useState("")
    const [otp, setOtp]=useState(false)


    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
          "email" : email,
     
        }
        axios.post(`${process.env.REACT_APP_WEB_APP}/user/forgot/password`, payload, {
          // headers: {
    
          //   "x-access-token": `${token}`,
          // }
        })
          .then((res) => {
            if (res.status === 200) {
              console.log(res, "ewrrwerwr")
              setOtp(true)
              Swal.fire({
                icon: 'success',
                title: (res.data.message),
                // text: 'Something went wrong!',
                // footer: '<a href="">Why do I have this issue?</a>'
              })

              setTimeout(() => {
            
                history.push(({
                    pathname: "/Otp",
                    state:email
  
                }));
            }, 1000)
      
         
            }
    
    
          })
          .catch((err) => {
            // showNotification("error", "Failed !", "" + err.response.data.message)
            console.log(err)
            Swal.fire({
              icon: 'error',
              title: (err?.response?.data?.message ? err.response.data.message : "Somthing Went Wrong" ),
              // text: 'Something went wrong!',
              // footer: '<a href="">Why do I have this issue?</a>'
            })
          })
      }
  return (
    <>
     <Grid item container style={{padding:"200px"}}>
        <Grid md={12} align="center">
        <div className="forgatpasswod" style={{padding:"10px"}}>
        <form onSubmit={handleSubmit}>
        <TextField 
        id="outlined-basic"
         label="Email" 
         variant="outlined" 
          type='email' 
          fullWidth
          onChange={(e)=>{setEmail(e.target.value)}}

          />
          <br/>
          <br/>

<Button  variant="contained" color="success" type='submit'>Send Password Reset Link</Button>
          </form>
        </div>
        
       
    </Grid>
    </Grid>
    </>
  )
}

export default ForgotPassword