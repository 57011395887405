import React from 'react'
import Grid from '@mui/material/Grid';
import GoogleMapReact from 'google-map-react';
import TextField from '@mui/material/TextField';
import "./Contact.css"
import PlaceIcon from '@mui/icons-material/Place';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Contact() {

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <>
            <Grid item container style={{ background: "#eeeeee", padding: "50px" }} className='homeStatic'>
                <Grid md={12} xs={12} align="center" >
                    <h1 >Contact Us</h1>


                </Grid>
                <Grid md={6} xs={12}>
                    <div style={{ height: '50vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            <AnyReactComponent
                                lat={59.955413}
                                lng={30.337844}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                    </div>
                </Grid>
                <Grid md={6} xs={12}>
                <Grid item container style={{ padding:"10px 50px 10px 50px"}} className='ContactStatic' >
                            <Grid md={4} xs={12}>
                            <p className='cont-P'> <PlaceIcon/> A108 Adam Street, NY 535022</p>
                            </Grid>
                            <Grid md={4} xs={12}>
                            <p className='cont-P'><MarkunreadIcon/>info@example.com</p>
                            </Grid>
                            <Grid md={4} xs={12}>
                            <p className='cont-P'><LocalPhoneIcon/>+1 5589 55488 55</p>
                            </Grid>
                            </Grid>
                           
                    <form>
                        <Grid item container style={{ padding:"10px 50px 10px 50px"}} className='ContactStatic'>
                            <Grid md={6} xs={12} style={{ padding:"10px"}} >
                                <TextField 
                                id="outlined-basic"
                                 label="Outlined" 
                                 variant="outlined"
                                  fullWidth
                                style={{background:"white",}}
                                 />
                            </Grid>
                            <Grid md={6} xs={12} style={{ padding:"10px"}}>
                                <TextField
                                 id="outlined-basic" 
                                 label="Outlined" 
                                 variant="outlined"  
                                style={{background:"white",}}
                                fullWidth
                                />
                            </Grid>
                            <Grid md={12} xs={12} style={{ padding:"10px"}}>
                                <TextField 
                                id="outlined-basic"
                                 label="Outlined" 
                                 variant="outlined" 
                                  fullWidth
                                  style={{background:"white",}}

                                  />
                            </Grid>
                            <Grid md={12} xs={12} style={{ padding:"10px"}}>
                                <TextField 
                                id="outlined-basic"
                                 label="Outlined" 
                                 variant="outlined"   
                                  multiline rows={4} 
                                  fullWidth
                                  style={{background:"white",}}

                                  />
                            </Grid>

                        </Grid>
                    </form>

                </Grid>

            </Grid>
        </>
    )
}

export default Contact