import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Components/Header'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Person2Icon from '@mui/icons-material/Person2';
import LockIcon from '@mui/icons-material/Lock';
import stats from '../../Assets/stats.png'
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import UseTimer from "./UseTimer";
import './Stats.css'
import QuizHistory from "../PlayHistory/QuizHistory";
import BuzzerHistory from "../PlayHistory/BuzzerHistory";
import RapidFireHistory from "../PlayHistory/RapidFireHistory";
import ExamHistory from "../PlayHistory/ExamHistory";
import home from '../../Assets/home.png'
import post from '../../Assets/bookmark.png';
import Edit from '../../Assets/edit.png';
import Logout from '../../Assets/switch.png';
import { StatsListURL } from "../../Redux/Game/Stats/StatsRedux";
import { secureStorage } from "../../Utils";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import PersonalInfo from "./StatsTabs/PersonalInfo";
import ChangesPassword from "./StatsTabs/ChangesPassword";
import { PlayHistoryHeaderURL } from "../../Redux/Game/Stats/PlayHistoryHeader";


function Stats() {

  const dispatch = useDispatch()
  const [pageNum, setPageNum] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('')
  //  const { currentUser } = useSelector((state) => state.auth)
  //  console.log(currentUser,"currentUser")
  const { StatsListData } = useSelector((state) => state.StatsList)
  console.log(StatsListData, "gdsdfdfssdsfd");


  console.log(secureStorage.getItem('currentUser'), "hgvdhsdfsg")

  const details = secureStorage.getItem('currentUser')

  useEffect(() => {
    dispatch(StatsListURL(details?.data?.token, details?.data?.data?.uuid));
  }, [])




  const { PlayHistoryHeaderData } = useSelector((state) => state.PlayHistoryHeaderList)
  console.log(PlayHistoryHeaderData, "ieywieproeprte");


  useEffect(() => {
    dispatch(PlayHistoryHeaderURL(pageNum, search, details?.data?.token, limit));
    // console.log(PlayHistoryHeaderData,"shasjhgdjds");
  }, [])


  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  const [selectedTab, setSelectedTab] = useState(0);
  const [typeuuid, setTypeUuid] = useState(PlayHistoryHeaderData[0]?.uuid)

  const [selectedTabPupUp, setSelectedTabPupUp] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




  // useEffect(() => {
  //   fetch('https://api.quizapp.scienstechnologies.com/api/v1/participant/profile/PR-B4748152')
  //     .then(response => response.json())
  //     .then(data => {
  //       // Handle the image data here
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);
  // const [images, setImages] = useState([]);

  // setImages(data);



  return (
    <>
      <Grid style={{ backgroundColor: "#191d4d", width: "100%", height: "100%", padding: " 100px 50px 31px 50px" }} className="stats">

        <Header />
        <Grid style={{ paddingBottom: "20px" }}>
          <img src={stats} alt="" width="40px" height="auto" />
          <span style={{ fontSize: "25px", color: "white" }}>&nbsp;STATS</span>
        </Grid>
        <Grid item container>

          <Grid xs={12} md={6} lg={2}>

            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span> QUIZ</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.quiz}</span><br />
                    <span>Game Played</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>

            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span> COINS</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.coins}</span><br />
                    <span>Earned</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={6} lg={2}>
            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span> BUZZERS</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.buzzer}</span><br />
                    <span>Game Played</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span>BADGE</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.badges}</span><br />
                    <span>Earned</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={6} lg={2}>
            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span>RAPID FIRE</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.rapid}</span><br />
                    <span>Game Played</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span>MEDALS</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.medals}</span><br />
                    <span>Earned</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={6} lg={2}>
            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span>EXAM</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.exam}</span><br />
                    <span>Game Played</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
            <div key={' '}>
              <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span>CUPS</span><br />
                    <span style={{ fontWeight: "bold", color: "#000" }}>{StatsListData?.data?.cups}</span><br />
                    <span>Earned</span>
                  </div>
                  <div>
                    <img src={stats} alt="" width="40px" height="auto" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid xs={12} md={12} lg={4}>
            <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "10px" }}>

              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>

                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  <div >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {/* {images.map(image => (
  <img src={StatsListData?.data?.profile_pic} alt="ProfilePic" />
))} */}
                      <img
                        src={StatsListData?.data?.profile_pic}
                        // alt="ProfilePic"
                        alt={`${StatsListData?.data?.name}'s profile`}
                        crossOrigin="anonymous"
                        width="130px"
                        height="auto"
                      />
                    </div>
                    <div style={{ textAlign: "center", padding: "10px", fontWeight: "500", fontSize: "25px" }}>{StatsListData?.data?.name}</div>
                    <div style={{ textAlign: "center" }}> {StatsListData?.data?.email} </div>
                    <div style={{ textAlign: "center" }}> {StatsListData?.data?.mobile}</div>
                  </div>
                </div>
                <div style={{ display: "block", justifyContent: "center", alignItems: "center" }}>
                  <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                    <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div>
                        <img src={home} alt="home" width="25px" height="auto" />
                      </div>
                      <div style={{ marginBottom: "7px" }}>
                        <span style={{ fontSize: "20px" }}>  Home</span>
                      </div>
                    </div>
                  </Link>
                  <br />


                  {/* <Link to="/Topics" style={{textDecoration:"none",color:"#000"}}> */}
                  <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                      <img src={post} alt="post" width="25px" height="auto" />
                    </div>

                    <div style={{ marginBottom: "7px" }}>
                      <span style={{ fontSize: "20px" }}>&nbsp;My Post</span>
                    </div>


                  </div>
                  {/* </Link> */}
                  <br />

                  <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-evenly", alignItems: "center" }} onClick={handleClickOpen}>

                    <div>
                      <img src={Edit} alt="Edit" width="25px" height="auto" />
                    </div>

                    <div style={{ marginBottom: "7px" }}  >
                      <span style={{ fontSize: "20px" }}> &nbsp;&nbsp;&nbsp; Edit</span>
                    </div>

                  </div>

                  <br />

                  <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <div>
                      <img src={Logout} alt="Logout" width="25px" height="auto" />
                    </div>

                    <div style={{ marginBottom: "7px" }}>
                      <span style={{ fontSize: "20px" }}>&nbsp;&nbsp;Logout</span>
                    </div>

                  </div> <br />

                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid item container>
          <Grid xs={12} md={12} lg={12}>
            <div style={{ background: "white", padding: "20px", borderRadius: "10px", margin: "15px", }}>
              <img src={stats} alt="" width="30px" height="auto" /> &nbsp;
              <span style={{ fontSize: "28px", fontWeight: "500" }} className="playhistorytext">Play History</span>

              <Grid xs={12} md={12} lg={12}>
                {/* {PlayHistoryHeaderData.map((item) => {
            console.log(item, "item")
            return <div key=""> */}
                <div key={' '} className="bottomTabs">
                  <div className="tab" style={{ background: 'linear-gradient(87deg, #5e72e4 0, #825ee4 100%)', display: "flex", justifyContent: "center", borderWidth: "2px", borderColor: "#fff", cursor: "pointer" }} onClick={() => { setSelectedTab(0); setTypeUuid(PlayHistoryHeaderData[0].uuid) }}>
                    <div className="textInfo">
                      {PlayHistoryHeaderData[0]?.name}
                      {/* jhgj */}
                      {selectedTab === 0 && (
                        <div className="tab-pseudo-element"></div>
                      )}
                    </div>
                  </div>
                  <div className="tab" style={{ background: 'linear-gradient(87deg, #2dce89 0, #2dcecc 100%)', display: "flex", justifyContent: "center", borderWidth: "2px", borderColor: "#fff", cursor: "pointer" }} onClick={() => { setSelectedTab(1); setTypeUuid(PlayHistoryHeaderData[1].uuid) }}>
                    <div className="textInfo">
                      {PlayHistoryHeaderData[1]?.name}
                      {selectedTab === 1 && (
                        <div className="tab-pseudo-element"></div>
                      )}
                    </div>
                  </div>
                  <div className="tab" style={{ background: 'linear-gradient(87deg, #f5365c 0, #f56036 100%)', display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => { setSelectedTab(2); setTypeUuid(PlayHistoryHeaderData[2].uuid) }}>
                    <div className="textInfo">
                      {PlayHistoryHeaderData[2]?.name}
                      {selectedTab === 2 && (
                        <div className="tab-pseudo-element"></div>
                      )}
                    </div>
                  </div>
                  <div className="tab" style={{ background: "linear-gradient(87deg, #11cdef 0, #1171ef 100%)", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => { setSelectedTab(3); setTypeUuid(PlayHistoryHeaderData[3].uuid) }}>
                    <div className="textInfo">
                      {PlayHistoryHeaderData[3]?.name}

                      {selectedTab === 3 && (
                        <div className="tab-pseudo-element"></div>
                      )}
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* })} */}
              </Grid>
              <Grid xs={12} md={12} lg={12} style={{ marginTop: "2.35%" }}>
                {selectedTab == 0 ? (<QuizHistory uuid={typeuuid} />) : selectedTab == 1 ? (<BuzzerHistory uuid={typeuuid} />) : selectedTab == 2 ? (<RapidFireHistory uuid={typeuuid} />) : selectedTab == 3 ? (<ExamHistory uuid={typeuuid} />) : null}
              </Grid>
            </div>
          </Grid>
        </Grid>


        {/* Edit profile popup  */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >

          <DialogTitle id="alert-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <FontAwesomeIcon icon={faEdit} />&nbsp;Edit Profile
              </div>
              <div style={{ cursor: "pointer" }} onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>

          </DialogTitle>

          <Grid item container style={{ padding: "20px" }}>

            <Grid xs={12} md={12} lg={12}>
              <div className="bottomTabsPopUp">
                <div className="tabPopUp" style={{ background: 'linear-gradient(87deg, #5e72e4 0, #825ee4 100%)', display: "flex", justifyContent: "center", borderWidth: "2px", borderColor: "#fff", cursor: "pointer" }} onClick={() => { setSelectedTabPupUp(0); }}>
                  <div className="textInfo">
                    <Person2Icon /> &nbsp; Personal Info
                    {selectedTabPupUp === 0 && (
                      <div className="tab-pseudo-element"></div>
                    )}
                  </div>
                </div>
                <div className="tabPopUp" style={{ background: 'linear-gradient(87deg, #2dce89 0, #2dcecc 100%)', display: "flex", justifyContent: "center", borderWidth: "2px", borderColor: "#fff", cursor: "pointer" }} onClick={() => { setSelectedTabPupUp(1); }}>
                  <div className="textInfo">
                    <LockIcon />&nbsp;   Change Password
                    {selectedTabPupUp === 1 && (
                      <div className="tab-pseudo-element"></div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid xs={12} md={12} lg={12} style={{ marginTop: "1%" }}>
            {selectedTabPupUp == 0 ? (<PersonalInfo onClick={handleClose} />) : selectedTabPupUp == 1 ? (<ChangesPassword onClick={handleClose}/>) : null}
          </Grid>

        </Dialog>
      </Grid>
    </>
  );
}

export default Stats;
