import React from "react";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const FindFriends = () =>{
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    return(
        <>
        <Grid item container>
        <Grid align="left" style={{paddingLeft:"40px"}}>
                                <h2>Search Friends</h2>
                            </Grid>
            <Grid xs={12} md={12} lg={12} style={{border:"2px solid rgba(0, 0, 0, 0.05)",margin:"0px 30px",borderRadius:"0.375rem",backgroundColor:"#fff"}} className="findfriend">
                <Grid xs={12} md={4} lg={4} style={{padding:"50px"}} className="findfriend1">
               <Stack direction="row" spacing={2}>
               <Box sx={{ minWidth: 320 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Box>
          
  <Button variant="contained" color="success">
        Submit
      </Button>
      </Stack>
          
            


                </Grid>

            </Grid>
        </Grid>
        </>
    )
};

export default FindFriends;