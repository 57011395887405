import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import './friends.css'

const GameSchedule = () => {
  const [age, setAge] = React.useState("");
  const [age1, setAge1] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange1 = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <Grid item container>
        <Grid md={12} align="left" style={{ paddingLeft: "0px" }}>
          <h3
            style={{
              lineHeight: "20px",
              color: "#32325d",
              fontFamily: "Montserrat sans-serif",
              fontWeight: "400",
              fontSize: "23px",
            }}
          >
            Game Schedule
          </h3>
        </Grid>
        <Grid item container style={{margin:"0px 100px 0px 0px"}} className="GameSchedule">
          <Grid xs={12} md={3} align="center" className="title" style={{fontSize:"18px",display:"flex",justifyContent:"center",alignItems:"center"}}>Type Of Game</Grid>
          <Grid xs={12} md={3}>
            <Box sx={{ minWidth: 320 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Age"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Quizz</MenuItem>
                  <MenuItem value={20}>Buzzer</MenuItem>
                  <MenuItem value={30}>Rapid Fire</MenuItem>
                  <MenuItem value={40}>Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid xs={12} md={3} className="title" align="center" style={{fontSize:"18px",display:"flex",justifyContent:"center",alignItems:"center"}}>No of Players</Grid>
          <Grid xs={12} md={3}>
            <Box sx={{ minWidth: 320 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age1}
                  label="Age"
                  onChange={handleChange1}
                >
                  <MenuItem value={50}>Ten</MenuItem>
                  <MenuItem value={60}>Twenty</MenuItem>
                  <MenuItem value={70}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item container style={{margin:"40px 100px 0px 0px"}} className="GameSchedule">
          <Grid xs={12} md={3} className="title" align="center" style={{fontSize:"18px",display:"flex",justifyContent:"center",alignItems:"center"}}>Set Game Date</Grid>
          <Grid xs={12} md={3}>
            <Box sx={{ minWidth: 320 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Age"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Quizz</MenuItem>
                  <MenuItem value={20}>Buzzer</MenuItem>
                  <MenuItem value={30}>Rapid Fire</MenuItem>
                  <MenuItem value={40}>Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid xs={12} md={3} className="title" align="center" style={{fontSize:"18px",display:"flex",justifyContent:"center",alignItems:"center"}}>Select Friends</Grid>
          <Grid xs={12} md={3}>
            <Box sx={{ minWidth: 320 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age1}
                  label="Age"
                  onChange={handleChange1}
                >
                  <MenuItem value={50}>Ten</MenuItem>
                  <MenuItem value={60}>Twenty</MenuItem>
                  <MenuItem value={70}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item container style={{margin:"40px 100px 0px 0px"}} className="GameSchedule">
          <Grid xs={12} md={3} className="title" align="center" style={{fontSize:"18px",display:"flex",justifyContent:"center",alignItems:"center"}}>Exam Category</Grid>
          <Grid xs={12} md={3}>
            <Box sx={{ minWidth: 320 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Age"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Quizz</MenuItem>
                  <MenuItem value={20}>Buzzer</MenuItem>
                  <MenuItem value={30}>Rapid Fire</MenuItem>
                  <MenuItem value={40}>Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid md={12} align="left" style={{ paddingLeft: "50px",marginTop:"2%" }}>
          <Button variant="contained"  className="GameScheduleButton">
        Submit
      </Button>
        </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GameSchedule;
