import axios from 'axios';
import SecureStorage from 'secure-web-storage';

const CryptoJS = require("crypto-js");
const SECRET_KEY = ')H@McQfTjWnZr4u7x!A%C*F-JaNdRgUkUkUXNh*F-JaNCUSTOMER';


export const secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },

  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },

  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }
});

export const decryptEventID = (encryptedData, secretKey) => {
  var decryption = CryptoJS.AES.decrypt(encryptedData, secretKey);
  let decryptKey = decryption.toString(CryptoJS.enc.Utf8);
  return decryptKey;
}


export const getUserToken = () => {
  try {
    let value = secureStorage.getItem("currentUser");
    if (value) {
      if (value.token !== undefined) return value.token;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const loadFetchData = function (type, data = null) {
  let token = getUserToken();
  let header = {};

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    alert(axios.defaults.headers)
  } else {
    header = {
      'Access-Control-Allow-Origin':'*',
      "Content-type": "application/json"
    };
  }

  return data
    ? {
      method: type,
      mode: 'cors',
      body: JSON.stringify(data),
      headers: header
    }
    : {
      method: type,
      mode: 'cors',
      headers: header
    };
};

export const handleUnauthorizedException = response => {
  // look for authorization errors and redirect to sign in
  //
  if (response.status === 401) {

    // remove currentUser data
    secureStorage.removeItem("currentUser");

    // redirect to home
    window.location.replace("/");
  }

  return response;
};

export const handleAPIErrorCodes = response => {
  switch (response.status) {
    case 400:
      return { status: "error", message: 'Invalid parameters in the request' };
    case 401:
      handleUnauthorizedException(response);
      return { status: "error", message: 'Unauthorized. Login expired please login again and check' };
    case 403:
      return { status: "error", message: 'Unauthorized. User is not permitted to do this operation' };
    case 404:
    case 500:
    case 504:
    default:
      return { status: "error", message: 'Unexpected error on the server side. Please try again later' };
  }
}

export const addPagerParams = (params, sort = '_id:DESC') => {
  if (params.searchKey && params.searchKey.length) {
    return (
      '?_q=' + params.searchKey
      + '&_start=' + (params.per_page === -1 ? 0 : ((params.page - 1) * params.per_page))
      + '&_limit=' + params.per_page
      + '&_sort=' + sort);
  }

  return (
    '?_start=' + ((params.page - 1) * params.per_page)
    + '&_limit=' + params.per_page
    + '&_sort=' + sort);
}


// role display names are different from the originally
// created roles

export const UserRolesDisplayNames = {
  'Client': 'Client',
  'Photo Studio': 'Photo Studio',
  'Admin': 'Admin'
};