import React,{useEffect,useState} from "react";
import { Button, Grid } from "@mui/material";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from '../../Components/Header';
import stats from '../../Assets/stats.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimerIcon from '@mui/icons-material/Timer';
import TextField from "@mui/material/TextField";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './Schedule.css'
import UserSchedule from "./UserSchedule";
import FriendsSchedule from "./FriendsSchedule";
import Footer from "../../Components/Footer";

const Schedule = () =>{
    const [selectedTab ,setSelectedTab] = useState(0);

    return(
        <>
   <Grid style={{    background: "linear-gradient(87deg, #172b4d 0, #1a174d 100%)",width:"100%",height:"100vh",padding:" 100px 40px 31px"}} className="mypostcontainer">
    <Header/>
    <Grid item container>
        <Grid xs={12} md={12}  lg={12}>
        <div style={{background: "white",padding: "20px", borderRadius: "10px",margin: "15px",}} className="scheduleCard">

        <Grid xs={12} md={12}  lg={12}>

        <div key={' '} className="bottomTabs">
        <div className="tab" style={{ background: 'linear-gradient(87deg, #5e72e4 0, #825ee4 100%)',display:"flex",justifyContent:"center",borderWidth:"2px",borderColor:"#fff",cursor:"pointer"}} onClick={() =>{setSelectedTab(0)}}>
          <div  className="textInfo">
            User Schedule
          {selectedTab === 0 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
        <div className="tab" style={{ background : "linear-gradient(87deg, #2dce89 0, #2dcecc 100%)",display:"flex",justifyContent:"center",borderWidth:"2px",borderColor:"#fff",cursor:"pointer"}} onClick={() =>{setSelectedTab(1)}}>
        <div  className="textInfo">
            Friends Schedule
        {selectedTab === 1 && (
    <div className="tab-pseudo-element"></div> 
  )}
          </div>
        </div>
       
       
      </div>
  
        </Grid>
        <Grid xs={12} md={12}  lg={12} style={{marginTop:"2.35%"}}>
        {selectedTab == 0 ? (<UserSchedule />) : selectedTab == 1  ? (<FriendsSchedule />): null}
        </Grid>
        </div>
        </Grid>
 </Grid>


        </Grid>
        <Footer />
        </>
    )
};
export default Schedule;